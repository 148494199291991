import * as React from 'react';
import { Badge, Button, IconButton, InputAdornment, TextField, FormControlLabel, Switch } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import './ExpensesTab.scss';
import IconGardening from '../../../../../assets/images/icons/gardening.svg';
import IconCleaning from '../../../../../assets/images/icons/room-cleaning.svg';
import IconMaintenanceExpenses from '../../../../../assets/images/icons/maintenance-expenses.svg';
import IconmMrtgageRent from '../../../../../assets/images/icons/mortgage-rent.svg';
import IconmPlumbingServices from '../../../../../assets/images/icons/plumbing-services.svg';
import IconSwimming from '../../../../../assets/images/icons/swimming.svg';
import IconTransportation from '../../../../../assets/images/icons/transportation-services.svg';
import IconUtility from '../../../../../assets/images/icons/utility.svg';

export default function ExpensesTab() {
    return (
      <div className="expenses_tab_holder">
        <div className="expenses_content_box">
            <div className="expenses_sidebar">
                <div className="search_box">
                    <TextField 
                        variant="outlined"
                        placeholder="Search" 
                        fullWidth
                        type="search" 
                        size="small"
                        className="mui_dflt_outlined_input has_l_icon"
                        InputProps={{
                            startAdornment: 
                            <InputAdornment position="start">
                                <IconButton size="small">
                                    <SearchOutlinedIcon />
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                    <Button variant="text">
                        <Badge color="error" variant="dot">
                            <FilterListOutlinedIcon />
                        </Badge>
                    </Button>
                </div>
                <div className="expenses_list_holder">
                    <Button variant="text" className="expenses_list active">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconGardening} alt="" />
                            </div>
                            <div>
                                <h4>Gardening Expenses</h4>
                                <p>$234 • 30 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="paid">Paid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconCleaning} alt="" />
                            </div>
                            <div>
                                <h4>Room Cleaning</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="unpaid">Unpaid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconMaintenanceExpenses} alt="" />
                            </div>
                            <div>
                                <h4>Maintenance Expenses</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="paid">Paid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconmMrtgageRent} alt="" />
                            </div>
                            <div>
                                <h4>Mortgage/Rent</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="unpaid">Unpaid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconmPlumbingServices} alt="" />
                            </div>
                            <div>
                                <h4>Plumbing Services</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="paid">Paid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconSwimming} alt="" />
                            </div>
                            <div>
                                <h4>Swimming Pool Services</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="unpaid">Unpaid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconTransportation} alt="" />
                            </div>
                            <div>
                                <h4>Transportation  Services</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="paid">Paid</label>
                        </div>
                    </Button>
                    <Button variant="text" className="expenses_list">
                        <div className="flx-box">
                            <div className="avatar">
                                <img src={IconUtility} alt="" />
                            </div>
                            <div>
                                <h4>Utility</h4>
                                <p>$180 • 20 - Jul - 2021</p>
                            </div>
                        </div>
                        <div className="status_box">
                            <AttachFileOutlinedIcon />
                            <br />
                            <label className="paid">Paid</label>
                        </div>
                    </Button>
                </div>
            </div>
            <div className="expenses_content">
                <div className="title_box">
                  <h4>
                    Gardening
                    <label className="paid">Paid</label>
                  </h4>
                  <p>
                    Ruben Clark  •. 12 - Jul - 2021 to  23 - Jul - 2021
                  </p>
                </div>
                <div className="content_box">
                    <div className="row">
                        <div className="col-12 input_box">
                            <label>Rental Name</label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input has_r_icon read-only" 
                                defaultValue="The Cordis, Beijing Capital Airport"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-12 input_box">
                            <label>Select Category  <span className="text-danger">*</span></label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input read-only" 
                                defaultValue="Gardening"
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <img src={IconGardening} alt="" />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-12 input_box">
                            <label>Select Inquriy</label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input read-only" 
                                defaultValue="Ruben Clark  •. 12 - Jul - 2021 to  23 - Jul - 2021"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </div>
                        <div className="col-xl-6 input_box">
                            <label>Expense Date <span className="text-danger">*</span></label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                defaultValue="23 - Jul - 2021" 
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input has_r_icon read-only"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <TodayOutlinedIcon />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-xl-6 input_box">
                            <label>Due Date</label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                defaultValue="30 - Jul - 2021" 
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input has_r_icon read-only"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <TodayOutlinedIcon />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-xl-6 input_box">
                            <FormControlLabel control={<Switch defaultChecked className="mui_dflt_switch" />} label="Recurring Monthly" />
                        </div>
                        <div className="col-xl-6 input_box">
                            <FormControlLabel control={<Switch defaultChecked className="mui_dflt_switch" />} label="Paid" />
                        </div>
                        <div className="col-xl-6 input_box">
                            <label>Amount</label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                defaultValue="234" 
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input has_l_icon has_r_icon read-only"
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <AttachMoneyIcon />
                                    </InputAdornment>,
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <UnfoldMoreIcon />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-xl-6 input_box">
                            <label>Payment Method</label>
                            <TextField 
                                variant="outlined"
                                fullWidth
                                defaultValue="Check" 
                                type="text" 
                                size="small"
                                className="mui_dflt_outlined_input has_r_icon read-only"
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: 
                                    <InputAdornment position="end">
                                        <ExpandMoreIcon />
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-12 input_box">
                            <label>Notes</label>
                            <TextField 
                                variant="outlined"
                                multiline
                                fullWidth
                                defaultValue="Some notes will go here.." 
                                type="text" 
                                size="small"
                                rows={5}
                                className="mui_dflt_outlined_input read-only"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
}