import * as React from 'react';
import { Tab, Tabs, Tooltip } from '@mui/material';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';

import BasicDetails from './BasicDetails/BasicDetails';
import DetailInfo from './DetailInfo/DetailInfo';
import RentalAmenities from './RentalAmenities/RentalAmenities';
import RentalGallery from './RentalGallery/RentalGallery';
import RatesCalendar from './RatesCalendar/RatesCalendar';
import RentalTags from './RentalTags/RentalTags';
import RentalTaxVat from './RentalTaxVat/RentalTaxVat';
import RentalFees from './RentalFees/RentalFees';
import ActivityLogs from './ActivityLogs/ActivityLogs';
import RentalCleaning from './RentalCleaning/RentalCleaning';
import RentalMap from './RentalMap/RentalMap';
import ManageReviews from './ManageReviews/ManageReviews';

import './RentalDetailsTab.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`rental_details-tabpanel-${index}`}
      aria-labelledby={`rental_details-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tab_sub_panel">
          {children}
        </div>
      )}
    </div>
  );
}

function RentalDetailsProps(index: number) {
  return {
    id: `rental_details-tab-${index}`,
    'aria-controls': `rental_details-tabpanel-${index}`,
  };
}

export default function RentalDetailsTab() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
    return (
      <div className="rental_details_tab_holder">
        <Tabs
          orientation="vertical"
          className="fxdside_tab"
          variant="scrollable"
          value={value}
          scrollButtons="auto"
          onChange={handleChange}
        >
          <Tooltip title="Basic Edit" placement="left" arrow>
            <Tab icon={<DescriptionOutlinedIcon />} {...RentalDetailsProps(0)} />
          </Tooltip>
          <Tooltip title="Detail Information" placement="left" arrow>
            <Tab icon={<ListAltIcon />} {...RentalDetailsProps(1)} />
          </Tooltip>
          <Tooltip title="Rental Amenities" placement="left" arrow>
            <Tab icon={<i className='icon icon-amenities' style={{fontSize: 20}} />} {...RentalDetailsProps(2)} />
          </Tooltip>
          <Tooltip title="Rental Gallery" placement="left" arrow>
            <Tab icon={<CollectionsOutlinedIcon />} {...RentalDetailsProps(3)} />
          </Tooltip>
          <Tooltip title="Rates calendar" placement="left" arrow>
            <Tab icon={<TodayOutlinedIcon />} {...RentalDetailsProps(4)} />
          </Tooltip>
          <Tooltip title="Rental Tags" placement="left" arrow>
            <Tab icon={<LocalOfferOutlinedIcon />} {...RentalDetailsProps(5)} />
          </Tooltip>
          <Tooltip title="Rental Tax & vat" placement="left" arrow>
            <Tab icon={<PercentOutlinedIcon />} {...RentalDetailsProps(6)} />
          </Tooltip>
          <Tooltip title="Rental fees" placement="left" arrow>
            <Tab icon={<MonetizationOnOutlinedIcon />} {...RentalDetailsProps(7)} />
          </Tooltip>
          <Tooltip title="Activity Logs" placement="left" arrow>
            <Tab icon={<HistoryOutlinedIcon />} {...RentalDetailsProps(8)} />
          </Tooltip>
          <Tooltip title="Cleaning" placement="left" arrow>
            <Tab icon={<CleaningServicesOutlinedIcon />} {...RentalDetailsProps(9)} />
          </Tooltip>
          <Tooltip title="Manage Reviews" placement="left" arrow>
            <Tab icon={<ReviewsOutlinedIcon />} {...RentalDetailsProps(10)} />
          </Tooltip>
          <Tooltip title="Map" placement="left" arrow>
            <Tab icon={<PinDropOutlinedIcon />} {...RentalDetailsProps(10)} />
          </Tooltip>
          <Tooltip title="Archive" placement="left" arrow>
            <Tab icon={<i className='icon icon-archive' style={{fontSize: 16}} />}  {...RentalDetailsProps(11)} />
          </Tooltip>
        </Tabs>

        <TabPanel value={value} index={0}>
          <BasicDetails />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DetailInfo />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <RentalAmenities />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <RentalGallery />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <RatesCalendar />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <RentalTags />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <RentalTaxVat />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <RentalFees />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <ActivityLogs />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <RentalCleaning />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <ManageReviews />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <RentalMap />
        </TabPanel>
        <TabPanel value={value} index={12}>
          Archive
        </TabPanel>
      </div>
    );
}