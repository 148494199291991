import * as React from 'react';
import { Button, IconButton, Menu, MenuItem } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import './RentalGallery.scss';
import img1 from '../../../../../../assets/images/placeholder/rental-gallery/img1.png';
import img2 from '../../../../../../assets/images/placeholder/rental-gallery/img2.png';
import img3 from '../../../../../../assets/images/placeholder/rental-gallery/img3.png';
import img4 from '../../../../../../assets/images/placeholder/rental-gallery/img4.png';
import img5 from '../../../../../../assets/images/placeholder/rental-gallery/img5.png';
import img6 from '../../../../../../assets/images/placeholder/rental-gallery/img6.png';
import img7 from '../../../../../../assets/images/placeholder/rental-gallery/img7.png';

export default function RentalGallery() {
    const [EditMenuEl, setEditMenuEl] = React.useState<null | HTMLElement>(null);
    const openEditMenu = Boolean(EditMenuEl);
    const handleClickEditMenu = (event: React.MouseEvent<HTMLElement>) => {
        setEditMenuEl(event.currentTarget);
    };
    const handleCloseEditMenu = () => {
        setEditMenuEl(null);
    };
    return (
      <div className="rental_gallery_tab_holder">
        <h4 className="title">Rental Gallery - 23/40</h4>
        <Menu
          className="dflt_dropdown_mui"
          anchorEl={EditMenuEl}
          open={openEditMenu}
          onClose={handleCloseEditMenu}
          onClick={handleCloseEditMenu}
          PaperProps={{
              elevation: 0,
              sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 0,
                  minWidth: 95,
                  '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 10,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                  },
              },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem>
            Set as Primary
          </MenuItem>
          <MenuItem>
            Action
          </MenuItem>
          <MenuItem>
            Another Action
          </MenuItem>
        </Menu>
        <div className="row">
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box primary">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img1} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Office Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img2} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Reading Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img3} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Living Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img4} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Main Hall 02</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img5} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Office Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img4} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Reading Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img6} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Living Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img7} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Main Hall 02</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img5} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Office Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img6} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Reading Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img7} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Living Room</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 col-xl-3 gallery_holder">
            <div className="box">
              <div className="img_holder">
                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                    <MoreHorizIcon sx={{fontSize: 20}} />
                </IconButton>
                <img src={img5} alt="" />
                <p className="pin_primary_text">
                  <Button variant="contained" className="set_primary_btn">
                    <PushPinIcon sx={{fontSize: 14}} />
                  </Button>
                  Primary Image
                </p>
              </div>
              <div className="captions">
                <h4>Main Hall 02</h4>
                <p>Some Description will go here</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}