import * as React from 'react';
import { Button, ButtonGroup} from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import './ActivityLogs.scss';

export default function ActivityLogs() {
    return (
      <div className="activity_logs_tab_holder">
        <h4 className="title">Activity Logs</h4>

        <div className="container-box">
            <div className="table-responsive table-holder">
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                date time
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                status
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                expires
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                message
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                21 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                23 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                24 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                21 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                29 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                28 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                21 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                27 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                        <tr>
                            <td>
                                25 - Jan - 2021  |  5:34 PM
                            </td>
                            <td>
                                Success
                            </td>
                            <td>
                                No
                            </td>
                            <td>
                                Some message will go here… 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                <div className="col-12 col-md-auto displaying_info">
                    Displaying <span>10</span> out of <span>25</span>
                </div>
                <div className="col-12 col-md-auto order-first order-md-last">
                    <ButtonGroup variant="outlined" className="cst_paginations">
                        <Button className="icon_btn_mui_dflt_outline" disabled>
                            <FirstPageIcon />
                        </Button>
                        <Button className="icon_btn_mui_dflt_outline" disabled>
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button className="btn_mui_dflt_outline">1 -10</Button>
                        <Button className="icon_btn_mui_dflt_outline">
                            <KeyboardArrowRightIcon />
                        </Button>
                        <Button className="icon_btn_mui_dflt_outline">
                            <LastPageIcon />
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>

      </div>
    );
}