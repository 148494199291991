import React from 'react';
import { Link } from 'react-router-dom';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';

import imageWebready from '../../../../../assets/images/placeholder/webready_icon.png';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';

function WeeklyTable(){

  return (
    <div className="table-responsive weekly-table-holder">
        <table className="table weekly-table">
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <span className="day">Mon</span>
                        <span className="date">4</span>
                    </th>
                    <th>
                        <span className="day">Tue</span>
                        <span className="date">5</span>
                    </th>
                    <th>
                        <span className="day">Wed</span>
                        <span className="date">6</span>
                    </th>
                    <th>
                        <span className="day">Thu</span>
                        <span className="date">7</span>
                    </th>
                    <th>
                        <span className="day">Fri</span>
                        <span className="date">8</span>
                    </th>
                    <th>
                        <span className="day">Sat</span>
                        <span className="date">9</span>
                    </th>
                    <th>
                        <span className="day">Sun</span>
                        <span className="date">10</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr className="all_day">
                    <td>All - Day</td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">12 am</span></td>
                    <td></td><td></td>
                    <td>
                        <div className="event">
                            <div className="event_dsc_holder unpaid">
                                <h4>James Smith</h4>
                                <div className="flx_lbl_box">
                                    <span className="lable_status">Unpaid</span>
                                    <img src={imageWebready} alt="" />
                                </div>
                                <p>
                                    Butterfly Apartment <br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:james@gmail.com'}>
                                        james@gmail.com
                                    </Link>
                                </p>
                                <div className="flx_icon_box">
                                    <div>
                                        <PeopleOutlineOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>3</span>
                                    </div>
                                    <div>
                                        <ChildCareOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>2</span>
                                    </div>
                                </div>
                                <div className="flx_icon_box">
                                    <div>
                                        <LightModeOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>11</span>
                                    </div>
                                    <div>
                                        <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>10</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">1 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">2 am</span></td>
                    <td></td>
                    <td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">3 am</span></td>
                    <td>
                        <div className="event">
                            <div className="event_dsc_holder paid">
                                <h4>Ruben Clark</h4>
                                <div className="flx_lbl_box">
                                    <span className="lable_status">Paid</span>
                                    <img src={imageWebready} alt="" />
                                </div>
                                <p>
                                    Butterfly Apartment <br />
                                    <Link className="email" to="#" onClick={() => window.location.href = 'mailto:ruben@gmail.com'}>
                                        ruben@gmail.com
                                    </Link>
                                </p>
                                <div className="flx_icon_box">
                                    <div>
                                        <PeopleOutlineOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>3</span>
                                    </div>
                                    <div>
                                        <ChildCareOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>2</span>
                                    </div>
                                </div>
                                <div className="flx_icon_box">
                                    <div>
                                        <LightModeOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>11</span>
                                    </div>
                                    <div>
                                        <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        <span>10</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">4 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">5 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">6 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">7 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">8 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">9 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td><span className="time">10 am</span></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td><td></td><td></td><td></td>
                    <td></td><td></td><td></td>
                </tr>
            </tbody>
        </table>
    </div>
  );
}

export default WeeklyTable;