import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, ButtonGroup, Checkbox, Chip, Divider, IconButton, InputAdornment, Menu, MenuItem, Switch, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import './StatementsPage.scss';
import imageRental_1 from '../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../assets/images/placeholder/thumb-5.jpg';

interface ChipData {
    key: number;
    label: string;
}

// Filter Dropdown
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`filter-tabpanel-${index}`}
        aria-labelledby={`filter-tab-${index}`}
        {...other}
        >
        {value === index && (
            <div className="tab_sub_panel">
            {children}
            </div>
        )}
        </div>
    );
}
function FIlterProps(index: number) {
    return {
        id: `filter-tab-${index}`,
        'aria-controls': `filter-tabpanel-${index}`,
    };
}
// End Filter Dropdown

function StatementsPage(){
    // Filter Dropdowns
    const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(null);
    const openView = Boolean(anchorElView);
    const handleClickView = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElView(event.currentTarget);
    };
    const handleCloseView = () => {
        setAnchorElView(null);
    };
    
    const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(null);
    const openFilter = Boolean(anchorElFilter);
    const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElFilter(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    // const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChangeFilterAc =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    // End Filter Dropdown

    const [checked, setChecked] = useState([false, false, false, false, false, false, false, false, false, false]);
    const CheckedAllTableRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([
            event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked,
            event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked
        ]);
    };

    const handleCheckbox0 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
    };
    const handleCheckbox1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], event.target.checked, checked[2], checked[3] ]);
    };
    const handleCheckbox2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
    };
    const handleCheckbox3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], event.target.checked]);
    };
    const handleCheckbox4 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], event.target.checked]);
    };
    const handleCheckbox5 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], event.target.checked]);
    };
    const handleCheckbox6 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], event.target.checked]);
    };
    const handleCheckbox7 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], event.target.checked]);
    };
    const handleCheckbox8 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7], event.target.checked]);
    };
    const handleCheckbox9 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7], checked[8], event.target.checked]);
    };
    
    const [chipData, setChipData] = React.useState<readonly ChipData[]>([
        { key: 0, label: 'Koon Cha Learn' },
        { key: 1, label: 'February' },
        { key: 2, label: 'Airbnb' }
    ]);
    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

  return (
    <div className="statements-page page_holder">
        <div className="header sticky-top has_searh_box">
            <TextField 
                variant="outlined"
                placeholder="Search for Inquries, Invoices & Guests.." 
                type="search" 
                size="small"
                className="mui_dflt_outlined_input has_l_icon search_header"
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </div>
        
        <div className="content_holder">

            <div className="row justify-content-between align-items-center title-holder">
                <div className="col-auto title">
                    <h4>Owner Statements</h4>
                    <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                        <Link to="#">
                            <i className="icon icon-info"></i>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                        <Link to="#">
                            <i className="icon icon-video" style={{fontSize: 11}}></i>
                        </Link>
                    </Tooltip>
                </div>
            </div>

            <div className="container-box">
                <div className="box_header">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto flx-box">
                            <TextField 
                                variant="outlined"
                                placeholder="Search Statements" 
                                type="search" 
                                size="small"
                                className="mui_dflt_outlined_input has_l_icon search-holder" 
                                InputProps={{
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <SearchOutlinedIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-auto flx-box">
                            <Button variant="text" className="btn-reset-filter">
                                <RemoveCircleOutlineOutlinedIcon />
                            </Button>
                            <Button variant="outlined" className="btn_circle">
                                8+
                            </Button>
                            {chipData.map((data) => {

                                return (
                                <div key={data.key}>
                                    <Chip
                                        variant="outlined"
                                        label={data.label}
                                        onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                    />
                                </div>
                                );
                            })}
                            <Tooltip title="Settings" placement="bottom" arrow>
                                <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary" onClick={handleClickFilter}>
                                    <Badge color="error" variant="dot">
                                        <SettingsOutlinedIcon />
                                    </Badge>
                                </Button>
                            </Tooltip>
                            <Menu
                                className="dropdown_filter_settings"
                                anchorEl={anchorElFilter}
                                open={openFilter}
                                onClose={handleCloseFilter}
                                PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    minWidth: 180,
                                    '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                    },
                                },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <div className="dropdown_header">
                                    <Tabs
                                        value={value}
                                        scrollButtons="auto"
                                        onChange={handleChange}
                                    >
                                        <Tab label="Grid" {...FIlterProps(0)} />
                                        <Tab label="Filters" {...FIlterProps(1)} />
                                    </Tabs>
                                </div>
                                <div className="dropdown_body">
                                    <TabPanel value={value} index={0}>
                                        <div className="grid_holder">
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Expense Date</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Due Date</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Rental</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Total</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div className="filter_holder">
                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFilterAc('panel1')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Rentals <span>2</span>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="rental_holder">
                                                        <TextField 
                                                            variant="outlined"
                                                            placeholder="Search" 
                                                            fullWidth
                                                            type="search" 
                                                            size="small"
                                                            className="mui_dflt_outlined_input has_l_icon search-holder" 
                                                            InputProps={{
                                                                startAdornment: 
                                                                <InputAdornment position="start">
                                                                    <IconButton size="small">
                                                                        <SearchOutlinedIcon />
                                                                    </IconButton>
                                                                </InputAdornment>,
                                                            }}
                                                        />
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_1} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>The Like View Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_2} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>Koon Cha Learn</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_3} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>Bunk Boutique Hostel C…</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_4} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>Pong Phen Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_5} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>The Like View Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_3} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>The Like View Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_2} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>ETZzz Hostel Bangkok</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFilterAc('panel2')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Status
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                     <div className="flx_box">
                                                        <div>Due Date</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Rental</div>
                                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Total</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFilterAc('panel3')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Category
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="flx_box">
                                                        <div>Due Date</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Rental</div>
                                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Total</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFilterAc('panel4')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Category
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="flx_box">
                                                        <div>Due Date</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Rental</div>
                                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Total</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="dropdown_ftr">
                                    <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase">
                                        Clear
                                    </Button>
                                    <Button className="btn_mui_primary no_shadow text-uppercase" variant="contained">
                                        Apply
                                    </Button>
                                </div>
                            </Menu>
                            <Tooltip title="View" placement="bottom" arrow>
                                <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary" onClick={handleClickView}>
                                    <Badge color="error" variant="dot">
                                        <VisibilityOutlinedIcon />
                                    </Badge>
                                </Button>
                            </Tooltip>
                            <Menu
                                className="dflt_dropdown_mui dropdown_clumn_view"
                                anchorEl={anchorElView}
                                open={openView}
                                onClose={handleCloseView}
                                onClick={handleCloseView}
                                PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    minWidth: 180,
                                    '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                    },
                                },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem>
                                    <SaveOutlinedIcon />
                                    Save New View
                                </MenuItem>
                                <MenuItem>
                                    <CachedIcon />
                                    Update Current View
                                </MenuItem>
                                <MenuItem>
                                    <DeleteOutlineOutlinedIcon />
                                    Delete Current View
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <ViewComfyOutlinedIcon />
                                    Default View
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <PushPinOutlinedIcon />
                                    New Inquiries View 
                                </MenuItem>
                                <MenuItem>
                                    <PushPinOutlinedIcon />
                                    Pong Boutique View
                                </MenuItem>
                                <MenuItem className="selected">
                                    <PushPinOutlinedIcon />
                                    Booking New View
                                </MenuItem>
                                <MenuItem>
                                    <PushPinOutlinedIcon />
                                    My Channels View
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
                
                <div className="table-responsive table-holder">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox 
                                        checked={
                                            checked[0] && checked[1] && checked[2] && checked[3] && checked[4]
                                            && checked[5] && checked[6] && checked[7] && checked[8] && checked[9]
                                        }
                                        indeterminate={
                                            checked[0] !== checked[1] !== checked[2] !== checked[3] !== checked[4]
                                            !== checked[5] !== checked[6] !== checked[7] !== checked[8] !== checked[9]
                                        }
                                        onChange={CheckedAllTableRow}
                                    />
                                    Created
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Rental 
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Duration
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Total
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Status
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={"" + (checked[0] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[0]} onChange={handleCheckbox0} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_1} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>The Like View Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    Jan 2022 - Sep 2023
                                </td>
                                <td>
                                    $456
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[1] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[1]} onChange={handleCheckbox1} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Koon Cha Learn</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    May 2022 - Dec 2023
                                </td>
                                <td>
                                    $567
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[2] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[2]} onChange={handleCheckbox2} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_3} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Bunk Boutique Hostel Chiangmai</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    Jan 2022 - Sep 2023
                                </td>
                                <td>
                                    $456
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[3] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[3]} onChange={handleCheckbox3} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_4} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    May 2022 - Dec 2023
                                </td>
                                <td>
                                    $567
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[4] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[4]} onChange={handleCheckbox4} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_5} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>ETZzz Hostel Bangkok</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    Jan 2022 - Sep 2023
                                </td>
                                <td>
                                    $456
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[5] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[5]} onChange={handleCheckbox5} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    May 2022 - Dec 2023
                                </td>
                                <td>
                                    $567
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[6] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[6]} onChange={handleCheckbox6} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_3} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Bunk Boutique Hostel Chiangmai</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    Jan 2022 - Sep 2023
                                </td>
                                <td>
                                    $456
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[7] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[7]} onChange={handleCheckbox7} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_4} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    May 2022 - Dec 2023
                                </td>
                                <td>
                                    $567
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[8] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[8]} onChange={handleCheckbox8} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_5} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>ETZzz Hostel Bangkok</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    Jan 2022 - Sep 2023
                                </td>
                                <td>
                                    $456
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                            <tr className={"" + (checked[9] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[9]} onChange={handleCheckbox9} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    May 2022 - Dec 2023
                                </td>
                                <td>
                                    $567
                                </td>
                                <td>
                                    Open
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                    <div className="col-12 col-md-auto displaying_info">
                        Displaying <span>10</span> out of <span>25</span>
                    </div>
                    <div className="col-12 col-md-auto order-first order-md-last">
                        <ButtonGroup variant="outlined" className="cst_paginations">
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <FirstPageIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Button className="btn_mui_dflt_outline">1 -10</Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <KeyboardArrowRightIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <LastPageIcon />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

            </div>

        </div>

    </div>
  );
}

export default StatementsPage;