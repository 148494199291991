import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Badge, Button, ButtonGroup, Checkbox, Divider, FormControl, IconButton, InputAdornment, ListItemText, Menu, MenuItem, OutlinedInput, Select, SelectChangeEvent, Switch, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HotTubOutlinedIcon from '@mui/icons-material/HotTubOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventIcon from '@mui/icons-material/Event';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import MotionPhotosOffOutlinedIcon from '@mui/icons-material/MotionPhotosOffOutlined';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import './RentalsPage.scss';
import imageRental_1 from '../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../assets/images/placeholder/thumb-5.jpg';

const ITEM_HEIGHT = 34;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const cities = [
  'Boston',
  'Fort Mill',
  'Newyork',
  'Diemen',
  'Blytheville',
  'Camden',
  'Fayetteville',
  'Magnolia',
  'Newport',
  'Calistoga',
];

const tags = [
    'Check-in',
    'Check-out',
    'Ready to rent',
    'Not serviced',
    'Do not use',
];

// Filter Dropdown
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`filter-tabpanel-${index}`}
        aria-labelledby={`filter-tab-${index}`}
        {...other}
        >
        {value === index && (
            <div className="tab_sub_panel">
            {children}
            </div>
        )}
        </div>
    );
}
function FIlterProps(index: number) {
    return {
        id: `filter-tab-${index}`,
        'aria-controls': `filter-tabpanel-${index}`,
    };
}
// End Filter Dropdown

function RentalsPage(){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [cityName, setCityName] = React.useState<string[]>([]);
    const [tagName, setTagName] = React.useState<string[]>([]);

    const handleChangeCities = (event: SelectChangeEvent<typeof cityName>) => {
        const {
        target: { value },
        } = event;
        setCityName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeTags = (event: SelectChangeEvent<typeof tagName>) => {
        const {
        target: { value },
        } = event;
        setTagName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const [EditMenuEl, setEditMenuEl] = React.useState<null | HTMLElement>(null);
    const openEditMenu = Boolean(EditMenuEl);
    const handleClickEditMenu = (event: React.MouseEvent<HTMLElement>) => {
        setEditMenuEl(event.currentTarget);
    };
    const handleCloseEditMenu = () => {
        setEditMenuEl(null);
    };

    const [checked, setChecked] = useState([false, false, false, false, false, false, false, false, false, false]);
    const CheckedAllTableRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([
            event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked,
            event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked
        ]);
    };

    // Filter Dropdowns
    const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(null);
    const openView = Boolean(anchorElView);
    const handleClickView = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElView(event.currentTarget);
    };
    const handleCloseView = () => {
        setAnchorElView(null);
    };
    
    const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(null);
    const openFilter = Boolean(anchorElFilter);
    const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElFilter(event.currentTarget);
    };
    const handleCloseFilter = () => {
        setAnchorElFilter(null);
    };
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    // const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [expanded, setExpanded] = React.useState<string | false>('');

    const handleChangeFilterAc =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };
    // End Filter Dropdown

    const handleCheckbox0 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
    };
    const handleCheckbox1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], event.target.checked, checked[2], checked[3] ]);
    };
    const handleCheckbox2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
    };
    const handleCheckbox3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], event.target.checked]);
    };
    const handleCheckbox4 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], event.target.checked]);
    };
    const handleCheckbox5 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], event.target.checked]);
    };
    const handleCheckbox6 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], event.target.checked]);
    };
    const handleCheckbox7 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], event.target.checked]);
    };
    const handleCheckbox8 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7], event.target.checked]);
    };
    const handleCheckbox9 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7], checked[8], event.target.checked]);
    };

  return (
    <div className="rentals-page page_holder">
        <div className="header sticky-top has_searh_box">
            <TextField 
                variant="outlined"
                placeholder="Search for Inquries, Invoices & Guests.." 
                type="search" 
                size="small"
                className="mui_dflt_outlined_input has_l_icon search_header"
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </div>
        
        <div className="content_holder">

            <div className="row justify-content-between align-items-center title-holder">
                <div className="col-auto title">
                    <h4>Rentals</h4>
                    <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                        <Link to="#">
                            <i className="icon icon-info"></i>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                        <Link to="#">
                            <i className="icon icon-video" style={{fontSize: 11}}></i>
                        </Link>
                    </Tooltip>
                </div>
                <div className="col-auto">
                    <Button
                        className="btn_mui_primary has_split_icon"
                        variant="contained"
                        onClick={handleClick}
                        aria-controls={open ? 'add-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        startIcon={<DomainAddOutlinedIcon style={{ fontSize: 20 }} />}
                        endIcon={<KeyboardArrowDownIcon className="split_icon" />}
                    >
                        ADD RENTAL
                    </Button>
                    <Menu
                        className="dflt_dropdown_mui"
                        anchorEl={anchorEl}
                        id="add-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            minWidth: 180,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            Add Rental (Manually)
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                            Import rentals (Airbnb URL)
                        </MenuItem>
                    </Menu>
                </div>
            </div>

            <div className="container-box">
                <div className="box_header">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto flx-box">
                            <TextField 
                                variant="outlined"
                                placeholder="Search" 
                                type="search" 
                                size="small"
                                className="mui_dflt_outlined_input has_l_icon search-holder" 
                                InputProps={{
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <SearchOutlinedIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                        <div className="col-auto flx-box">
                            <FormControl sx={{ width: 200 }} className="mui-dflt-select-box">
                                <Select
                                displayEmpty
                                multiple
                                value={cityName}
                                onChange={handleChangeCities}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <>Select Cities</>;
                                    }
                        
                                    return selected.join(', ');
                                }}
                                MenuProps={MenuProps}
                                >
                                    {cities.map((city) => (
                                        <MenuItem key={city} value={city} className="mui-dflt-select-menu-box">
                                            <Checkbox checked={cityName.indexOf(city) > -1} />
                                            <ListItemText primary={city} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ width: 200 }} className="mui-dflt-select-box">
                                <Select
                                    displayEmpty
                                    multiple
                                    value={tagName}
                                    onChange={handleChangeTags}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                        return <>Select Tags</>;
                                        }
                            
                                        return selected.join(', ');
                                    }}
                                    MenuProps={MenuProps}
                                >
                                {tags.map((tag) => (
                                    <MenuItem key={tag} value={tag} className="mui-dflt-select-menu-box">
                                        <Checkbox checked={tagName.indexOf(tag) > -1} />
                                        <ListItemText primary={tag} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            
                            <Tooltip title="Settings" placement="bottom" arrow>
                                <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary" onClick={handleClickFilter}>
                                    <Badge color="error" variant="dot">
                                        <SettingsOutlinedIcon />
                                    </Badge>
                                </Button>
                            </Tooltip>
                            <Menu
                                className="dropdown_filter_settings"
                                anchorEl={anchorElFilter}
                                open={openFilter}
                                onClose={handleCloseFilter}
                                PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    minWidth: 180,
                                    '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                    },
                                },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <div className="dropdown_header">
                                    <Tabs
                                        value={value}
                                        scrollButtons="auto"
                                        onChange={handleChange}
                                    >
                                        <Tab label="Grid" {...FIlterProps(0)} />
                                        <Tab label="Filters" {...FIlterProps(1)} />
                                    </Tabs>
                                </div>
                                <div className="dropdown_body">
                                    <TabPanel value={value} index={0}>
                                        <div className="grid_holder">
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Expense Date</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Due Date</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Rental</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div className="flx_txt"><DragIndicatorOutlinedIcon /> Total</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <div className="filter_holder">
                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFilterAc('panel1')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Rentals <span>2</span>
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="rental_holder">
                                                        <TextField 
                                                            variant="outlined"
                                                            placeholder="Search" 
                                                            fullWidth
                                                            type="search" 
                                                            size="small"
                                                            className="mui_dflt_outlined_input has_l_icon search-holder" 
                                                            InputProps={{
                                                                startAdornment: 
                                                                <InputAdornment position="start">
                                                                    <IconButton size="small">
                                                                        <SearchOutlinedIcon />
                                                                    </IconButton>
                                                                </InputAdornment>,
                                                            }}
                                                        />
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_1} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>The Like View Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_2} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>Koon Cha Learn</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_3} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>Bunk Boutique Hostel C…</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_4} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>Pong Phen Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_5} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>The Like View Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_3} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>The Like View Guesthouse</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                        <div className="flx_box">
                                                            <div className="rental_box">
                                                                <div className="img_holder">
                                                                    <img src={imageRental_2} alt="" />
                                                                </div>
                                                                <div className="rental_dsc">
                                                                    <h4>ETZzz Hostel Bangkok</h4>
                                                                </div>
                                                            </div>
                                                            <div><Switch className="mui_dflt_switch" /></div>
                                                        </div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFilterAc('panel2')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Status
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                     <div className="flx_box">
                                                        <div>Due Date</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Rental</div>
                                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Total</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFilterAc('panel3')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Category
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="flx_box">
                                                        <div>Due Date</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Rental</div>
                                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Total</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFilterAc('panel4')}>
                                                <AccordionSummary
                                                expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                                >
                                                    <div className="box_title">
                                                        Category
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <div className="flx_box">
                                                        <div>Due Date</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Rental</div>
                                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                    </div>
                                                    <div className="flx_box">
                                                        <div>Total</div>
                                                        <div><Switch className="mui_dflt_switch" /></div>
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="dropdown_ftr">
                                    <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase">
                                        Clear
                                    </Button>
                                    <Button className="btn_mui_primary no_shadow text-uppercase" variant="contained">
                                        Apply
                                    </Button>
                                </div>
                            </Menu>
                            <Tooltip title="View" placement="bottom" arrow>
                                <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary" onClick={handleClickView}>
                                    <Badge color="error" variant="dot">
                                        <VisibilityOutlinedIcon />
                                    </Badge>
                                </Button>
                            </Tooltip>
                            <Menu
                                className="dflt_dropdown_mui dropdown_clumn_view"
                                anchorEl={anchorElView}
                                open={openView}
                                onClose={handleCloseView}
                                onClick={handleCloseView}
                                PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    minWidth: 180,
                                    '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                    },
                                },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem>
                                    <SaveOutlinedIcon />
                                    Save New View
                                </MenuItem>
                                <MenuItem>
                                    <CachedIcon />
                                    Update Current View
                                </MenuItem>
                                <MenuItem>
                                    <DeleteOutlineOutlinedIcon />
                                    Delete Current View
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <ViewComfyOutlinedIcon />
                                    Default View
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                    <PushPinOutlinedIcon />
                                    New Inquiries View 
                                </MenuItem>
                                <MenuItem>
                                    <PushPinOutlinedIcon />
                                    Pong Boutique View
                                </MenuItem>
                                <MenuItem className="selected">
                                    <PushPinOutlinedIcon />
                                    Booking New View
                                </MenuItem>
                                <MenuItem>
                                    <PushPinOutlinedIcon />
                                    My Channels View
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </div>
                
                <div className="table-responsive table-holder">
                    <Menu
                        className="dflt_dropdown_mui"
                        anchorEl={EditMenuEl}
                        open={openEditMenu}
                        onClose={handleCloseEditMenu}
                        onClick={handleCloseEditMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 0,
                                minWidth: 95,
                                '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 48,
                                width: 15,
                                height: 15,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <EditOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Edit
                        </MenuItem>
                        <MenuItem>
                            <EventIcon style={{fontSize: 16, marginRight: 8}} />
                            Calendar
                        </MenuItem>
                        <MenuItem>
                            <DeleteOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Delete
                        </MenuItem>
                        <MenuItem>
                            <ContentCopyOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Duplicate
                        </MenuItem>
                        <MenuItem>
                            <MotionPhotosOffOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Disable
                        </MenuItem>
                    </Menu>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox 
                                        checked={
                                            checked[0] && checked[1] && checked[2] && checked[3] && checked[4]
                                            && checked[5] && checked[6] && checked[7] && checked[8] && checked[9]
                                        }
                                        indeterminate={
                                            checked[0] !== checked[1] !== checked[2] !== checked[3] !== checked[4]
                                            !== checked[5] !== checked[6] !== checked[7] !== checked[8] !== checked[9]
                                        }
                                        onChange={CheckedAllTableRow}
                                    />
                                    Name
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Description
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Phone
                                </th>
                                <th>
                                    Owner
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    City
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Country
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={"" + (checked[0] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[0]} onChange={handleCheckbox0} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_1} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>The Like View Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>D</Avatar>
                                        <span>David Pollack</span>
                                    </div>
                                </td>
                                <td>
                                    Boston
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[1] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[1]} onChange={handleCheckbox1} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Koon Cha Learn</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>1</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>J</Avatar>
                                        <span>James Smith</span>
                                    </div>
                                </td>
                                <td>
                                    Fort Mill
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[2] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[2]} onChange={handleCheckbox2} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_3} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Bunk Boutique Hostel Chiangmai</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>2</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>S</Avatar>
                                        <span>Sanjay Kumar</span>
                                    </div>
                                </td>
                                <td>
                                    Newyork
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[3] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[3]} onChange={handleCheckbox3} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_4} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>4</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>P</Avatar>
                                        <span>Pat Peralla</span>
                                    </div>
                                </td>
                                <td>
                                    Fort Mill
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[4] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[4]} onChange={handleCheckbox4} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_5} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>ETZzz Hostel Bangkok</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-48237
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>R</Avatar>
                                        <span>Rajive Kumar</span>
                                    </div>
                                </td>
                                <td>
                                    Bangkok
                                </td>
                                <td>
                                    Thailand
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[5] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[5]} onChange={handleCheckbox5} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_1} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>The Like View Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>D</Avatar>
                                        <span>David Pollack</span>
                                    </div>
                                </td>
                                <td>
                                    Boston
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[6] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[6]} onChange={handleCheckbox6} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Koon Cha Learn</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>1</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>J</Avatar>
                                        <span>James Smith</span>
                                    </div>
                                </td>
                                <td>
                                    Fort Mill
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[7] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[7]} onChange={handleCheckbox7} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_3} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Bunk Boutique Hostel Chiangmai</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>2</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>S</Avatar>
                                        <span>Sanjay Kumar</span>
                                    </div>
                                </td>
                                <td>
                                    Newyork
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[8] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[8]} onChange={handleCheckbox8} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_4} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>4</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-4827
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>P</Avatar>
                                        <span>Pat Peralla</span>
                                    </div>
                                </td>
                                <td>
                                    Fort Mill
                                </td>
                                <td>
                                    United States of America
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr className={"" + (checked[9] ? "selected_row" : "") }>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <Checkbox checked={checked[9]} onChange={handleCheckbox9} />
                                        <div className="rental_box">
                                            <div className="img_holder">
                                                <img src={imageRental_5} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>ETZzz Hostel Bangkok</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <BedOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <HotTubOutlinedIcon sx={{ fontSize: 16 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    214-577-48237
                                </td>
                                <td>
                                    <div className="avatar_flx_box">
                                        <Avatar>R</Avatar>
                                        <span>Rajive Kumar</span>
                                    </div>
                                </td>
                                <td>
                                    Bangkok
                                </td>
                                <td>
                                    Thailand
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                    <div className="col-12 col-md-auto displaying_info">
                        Displaying <span>10</span> out of <span>25</span>
                    </div>
                    <div className="col-12 col-md-auto order-first order-md-last">
                        <ButtonGroup variant="outlined" className="cst_paginations">
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <FirstPageIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Button className="btn_mui_dflt_outline">1 -10</Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <KeyboardArrowRightIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <LastPageIcon />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

            </div>

        </div>

    </div>
  );
}

export default RentalsPage;