import * as React from 'react';
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

import './RentalMap.scss';

export default function RentalMap() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAzFmGXg2ljb8YvgWuQfLxg7KEPiU2kMMA"
  });
  const center = React.useMemo(() => ({ lat: 44, lng: -80 }), []);
    return isLoaded ?(
      <div className="rental_map_tab_holder">
        <h4 className="title">Map</h4>
        <div className="container-box">
          <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
            <Marker position={center} />
          </GoogleMap>
        </div>
      </div>
    ): <></>
}