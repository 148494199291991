import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button, Avatar, Tooltip, Drawer, IconButton, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';

import './SideBar.scss';
import imageLogo from '../../../assets/images/logo.png';
import imageProfile from '../../../assets/images/placeholder/profile.jpeg';

function SideBar(){
    const { pathname } = useLocation();
    // Getting mobile devices
    const [userIsDesktop, setUserIsDesktop] = useState(true);
    useEffect(() => {
    window.innerWidth > 767 ? setUserIsDesktop(true) : setUserIsDesktop(false);
    }, [userIsDesktop]);
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <>
        {userIsDesktop && (
           <div className='sidebar_holder'>
                <div className="sticky-top logo_holder">
                    <Link to="/">
                        <img src={imageLogo} alt="Owner Center" title="Owner Center" />
                    </Link>
                </div>
                <div className="nav_holder">
                    <NavLink to="/calendar" end className={({ isActive }) => 
                                (['/calendar', '/calendar/multi', '/calendar/standard', '/calendar/planning', '/calendar/rates', '/calendar/yearly'].includes(pathname) ? "active" : "")}>
                        <Tooltip title="Calendar" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-standard-calendar"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/reports" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Reports" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-reports"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/payments" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Payments" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-payments" style={{fontSize: 18}}></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/bookings" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Bookings" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-bookings"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/statements" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Statements" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-statements"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/invoices" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Invoices" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-invoices"></i>
                            </Button>
                        </Tooltip>
                    </NavLink>
                    <NavLink to="/rentals" className={({ isActive }) => 
                                (isActive ? "active" : "")}>
                        <Tooltip title="Rentals" placement="right" arrow>
                            <Button variant="text">
                                <CorporateFareOutlinedIcon style={{fontSize: 24}} />
                            </Button>
                        </Tooltip>
                    </NavLink>
                </div>
                <div className="nav_bottom_fxd">
                    <Link to="#">
                        <Tooltip title="Notifications" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-notification"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="#">
                        {/* <Avatar  sx={{ width: 45, height: 45, bgcolor: '#02AB9D' }}>H</Avatar> */}
                        <Avatar alt="David Pollack" src={imageProfile} sx={{ width: 45, height: 45, bgcolor: '#02AB9D' }} />
                    </Link>
                    <Link to="#">
                        <Tooltip title="Logout" placement="right" arrow>
                            <Button variant="text">
                                <i className="icon icon-logout"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                </div>
           </div> 
        )}
        {/* ====Mobile version */}
        {!userIsDesktop && (
            <div className="mobile_nav_holder">
                <IconButton className="toggle_btn" onClick={toggleDrawer(true)}>
                    <MenuIcon sx={{ fontSize: 25, color: '#000' }} />
                </IconButton>
                <Drawer
                    anchor="left"
                    open={open}
                    onClose={toggleDrawer(false)}
                    className="mobile_menu_drawer"
                >
                    <div className="nav_header">
                        <div className="profile_box">
                            <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={
                                <IconButton>
                                    <CameraAltOutlinedIcon sx={{ fontSize: 12, color: '#03A99D' }} ></CameraAltOutlinedIcon>
                                </IconButton>
                            }
                            >
                                <Avatar alt="David Pollack" src={imageProfile} sx={{ width: 56, height: 56, bgcolor: '#02AB9D' }}/>
                            </Badge>
                        </div>
                        <div className="profile_info">
                            <h4 className="name">David Pollack</h4>
                            <Link className="email" to="#" onClick={() => window.location.href = 'mailto:davidpollack@tokeet.com'}>
                                davidpollack@tokeet.com
                            </Link>
                        </div>
                    </div>
                    <div className="navbox">
                        <NavLink to="/calendar" end className={({ isActive }) => 
                                (['/calendar', '/calendar/multi', '/calendar/standard', '/calendar/planning', '/calendar/rates', '/calendar/yearly'].includes(pathname) ? "active" : "")} 
                                onClick={toggleDrawer(false)}>
                            <i className="icon icon-standard-calendar"></i>
                            <span>Calendar</span>
                        </NavLink>
                        <NavLink to="/reports" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-reports"></i>
                            <span>Reports</span>
                        </NavLink>
                        <NavLink to="/payments" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-payments" style={{fontSize: 18}}></i>
                            <span>Payments</span>
                        </NavLink>
                        <NavLink to="/bookings" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-bookings"></i>
                            <span>Bookings</span>
                        </NavLink>
                        <NavLink to="/statements" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-statements"></i>
                            <span>Statements</span>
                        </NavLink>
                        <NavLink to="/invoices" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <i className="icon icon-invoices"></i>
                            <span>Invoices</span>
                        </NavLink>
                        <NavLink to="/rentals" className={({ isActive }) => 
                                    (isActive ? "active" : "")} onClick={toggleDrawer(false)}>
                            <CorporateFareOutlinedIcon style={{fontSize: 24}} />
                            <span>Rentals</span>
                        </NavLink>

                        <NavLink to="#" className="logout">
                            <i className="icon icon-logout"></i>
                            <span>Logout</span>
                        </NavLink>
                    </div>
                </Drawer>
            </div>
        )}
        </>
    );
}

export default SideBar;

