import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip, ButtonGroup, Menu, MenuItem, Divider, FormControlLabel, Switch, ToggleButtonGroup, ToggleButton, Rating, Badge } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

import './YearlyCalendarPage.scss';

function YearlyCalendarPage(){
  const [BookingStatus, setBookingStatus] = React.useState('Booked');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newBookingStatus: string | null,
  ) => {
    if (newBookingStatus !== null) {
      setBookingStatus(newBookingStatus);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="yearly-calendar-page page_holder">
      <div className="header sticky-top">
        <h4>Calendar</h4>
        <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-info"></i>
            </Link>
        </Tooltip>
        <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-video" style={{fontSize: 16}}></i>
            </Link>
        </Tooltip>
      </div>
      <div className="content_holder">
      <div className="row justify-content-between align-items-center title-holder">
            <div className="col-md-auto col-lg-7 col-xl-8 title">
                <h4>Multi Calendar (Yearly View)</h4>
                <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-info"></i>
                    </Link>
                </Tooltip>
                <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-video" style={{fontSize: 11}}></i>
                    </Link>
                </Tooltip>
            </div>
            <div className="col-md-auto col-lg-5 col-xl-4">
                <div className="row justify-content-md-between align-items-center">
                    <div className="col-auto">
                        <FormControlLabel
                            className="yes_no_switch_holder"
                            control={<Switch defaultChecked className="yes_no_switch" />} 
                            label="Show Cancelled"
                        />
                    </div>
                    <div className="col-auto">
                        <div className="btn-group-box">
                            <Link to="/calendar/multi">
                                <Tooltip title="Multi Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-multi-calendar-month"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/standard">
                                <Tooltip title="Standard Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-standard-calendar"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/planning">
                                <Tooltip title="Planning Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-planning-calendar"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/yearly">
                                <Tooltip title="Yearly Calendar" placement="bottom" arrow>
                                    <Button variant="text" className="active">
                                        <i className="icon icon-multi-calendar-year"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/rates">
                                <Tooltip title="Rates Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-rates-calendar"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-between align-items-center header-input-box">
            <div className="col-auto flx-box">
                <ToggleButtonGroup
                    className="status_toggle_btn_grp"
                    value={BookingStatus}
                    exclusive
                    onChange={handleChange}
                    >
                    <ToggleButton value="Available">
                        <i className="icon icon-selected"></i>
                        Available
                    </ToggleButton>
                    <ToggleButton value="Booked">
                        <i className="icon icon-selected"></i>
                        Booked
                    </ToggleButton>
                </ToggleButtonGroup>
                <Rating name="bookmark" max={1} />
                <Tooltip title="Refresh" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                        <RefreshIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Button variant="outlined" className="btn_mui_dflt_outline">
                    Now
                </Button>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">2021</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
            <div className="col-auto flx-box">
                <Button 
                    variant="text"
                    className="btn-filter"
                    endIcon={
                        <Badge color="error" variant="dot">
                            <FilterListOutlinedIcon />
                        </Badge>
                    }
                >
                    FILTER
                </Button>
                <Button variant="text" className="btn-reset-filter">
                    <RemoveCircleOutlineOutlinedIcon />
                </Button>
                <Button
                    className="btn_mui_dflt_outline clr_primary text-uppercase has_split_icon "
                    variant="outlined"
                    onClick={handleClick}
                    aria-controls={open ? 'edit-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    startIcon={<EditOutlinedIcon style={{ fontSize: 16 }} />}
                    endIcon={<KeyboardArrowDownIcon className="split_icon" />}
                >
                    EDIT
                </Button>
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={anchorEl}
                    id="edit-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: 180,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem>
                        Action
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        Another action
                    </MenuItem>
                </Menu>
                
                <Tooltip title="Print" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <LocalPrintshopOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
            </div>
        </div>

        <div className="container-box">
            <div className="row">

                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            January 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li>
                                <li className="has_event bg_green event_start"><span>6</span></li>
                                <li className="has_event bg_green"><span>7</span></li>
                                <li className="has_event bg_green"><span>8</span></li>
                                <li className="has_event bg_green"><span>9</span></li>
                                <li className="has_event bg_green event_end"><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li className="has_event bg_green event_end"><span>13</span></li><li><span>14</span></li><li><span>15</span></li>
                                <li><span>16</span></li><li className="has_event bg_blue event_start"><span>17</span></li>
                                <li className="has_event bg_blue event_end"><span>18</span></li><li><span>19</span></li><li><span>20</span></li>
                                <li><span>21</span></li><li><span>22</span></li><li><span>23</span></li><li><span>24</span></li><li><span>25</span></li>
                                <li><span>26</span></li><li><span>27</span></li><li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            February 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li><li><span>6</span></li>
                                <li><span>7</span></li><li><span>8</span></li>
                                <li><span>9</span></li><li><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                <li><span>15</span></li>
                                <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                <li className="has_event bg_green event_start"><span>20</span></li>
                                <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                <li className="has_event bg_green"><span>25</span></li>
                                <li className="has_event bg_green event_end"><span>26</span></li>
                                <li className="has_event bg_green event_end"><span>27</span></li>
                                <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            march 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li>
                                <li className="has_event bg_green event_start"><span>6</span></li>
                                <li className="has_event bg_green"><span>7</span></li>
                                <li className="has_event bg_green"><span>8</span></li>
                                <li className="has_event bg_green"><span>9</span></li>
                                <li className="has_event bg_green event_end"><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li className="has_event bg_green event_end"><span>13</span></li><li><span>14</span></li><li><span>15</span></li>
                                <li><span>16</span></li><li className="has_event bg_blue event_start"><span>17</span></li>
                                <li className="has_event bg_blue event_end"><span>18</span></li><li><span>19</span></li><li><span>20</span></li>
                                <li><span>21</span></li><li><span>22</span></li><li><span>23</span></li><li><span>24</span></li><li><span>25</span></li>
                                <li><span>26</span></li><li><span>27</span></li><li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            april 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li><li><span>6</span></li>
                                <li><span>7</span></li><li><span>8</span></li>
                                <li><span>9</span></li><li><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                <li><span>15</span></li>
                                <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                <li className="has_event bg_green event_start"><span>20</span></li>
                                <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                <li className="has_event bg_green"><span>25</span></li>
                                <li className="has_event bg_green event_end"><span>26</span></li>
                                <li className="has_event bg_green event_end"><span>27</span></li>
                                <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            may 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li>
                                <li className="has_event bg_green event_start"><span>6</span></li>
                                <li className="has_event bg_green"><span>7</span></li>
                                <li className="has_event bg_green"><span>8</span></li>
                                <li className="has_event bg_green"><span>9</span></li>
                                <li className="has_event bg_green event_end"><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li className="has_event bg_green event_end"><span>13</span></li><li><span>14</span></li><li><span>15</span></li>
                                <li><span>16</span></li><li className="has_event bg_blue event_start"><span>17</span></li>
                                <li className="has_event bg_blue event_end"><span>18</span></li><li><span>19</span></li><li><span>20</span></li>
                                <li><span>21</span></li><li><span>22</span></li><li><span>23</span></li><li><span>24</span></li><li><span>25</span></li>
                                <li><span>26</span></li><li><span>27</span></li><li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            june 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li><li><span>6</span></li>
                                <li><span>7</span></li><li><span>8</span></li>
                                <li><span>9</span></li><li><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                <li><span>15</span></li>
                                <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                <li className="has_event bg_green event_start"><span>20</span></li>
                                <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                <li className="has_event bg_green"><span>25</span></li>
                                <li className="has_event bg_green event_end"><span>26</span></li>
                                <li className="has_event bg_green event_end"><span>27</span></li>
                                <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            july 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li>
                                <li className="has_event bg_green event_start"><span>6</span></li>
                                <li className="has_event bg_green"><span>7</span></li>
                                <li className="has_event bg_green"><span>8</span></li>
                                <li className="has_event bg_green"><span>9</span></li>
                                <li className="has_event bg_green event_end"><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li className="has_event bg_green event_end"><span>13</span></li><li><span>14</span></li><li><span>15</span></li>
                                <li><span>16</span></li><li className="has_event bg_blue event_start"><span>17</span></li>
                                <li className="has_event bg_blue event_end"><span>18</span></li><li><span>19</span></li><li><span>20</span></li>
                                <li><span>21</span></li><li><span>22</span></li><li><span>23</span></li><li><span>24</span></li><li><span>25</span></li>
                                <li><span>26</span></li><li><span>27</span></li><li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            august 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li><li><span>6</span></li>
                                <li><span>7</span></li><li><span>8</span></li>
                                <li><span>9</span></li><li><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                <li><span>15</span></li>
                                <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                <li className="has_event bg_green event_start"><span>20</span></li>
                                <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                <li className="has_event bg_green"><span>25</span></li>
                                <li className="has_event bg_green event_end"><span>26</span></li>
                                <li className="has_event bg_green event_end"><span>27</span></li>
                                <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            september 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li>
                                <li className="has_event bg_green event_start"><span>6</span></li>
                                <li className="has_event bg_green"><span>7</span></li>
                                <li className="has_event bg_green"><span>8</span></li>
                                <li className="has_event bg_green"><span>9</span></li>
                                <li className="has_event bg_green event_end"><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li className="has_event bg_green event_end"><span>13</span></li><li><span>14</span></li><li><span>15</span></li>
                                <li><span>16</span></li><li className="has_event bg_blue event_start"><span>17</span></li>
                                <li className="has_event bg_blue event_end"><span>18</span></li><li><span>19</span></li><li><span>20</span></li>
                                <li><span>21</span></li><li><span>22</span></li><li><span>23</span></li><li><span>24</span></li><li><span>25</span></li>
                                <li><span>26</span></li><li><span>27</span></li><li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            october 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li><li><span>6</span></li>
                                <li><span>7</span></li><li><span>8</span></li>
                                <li><span>9</span></li><li><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                <li><span>15</span></li>
                                <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                <li className="has_event bg_green event_start"><span>20</span></li>
                                <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                <li className="has_event bg_green"><span>25</span></li>
                                <li className="has_event bg_green event_end"><span>26</span></li>
                                <li className="has_event bg_green event_end"><span>27</span></li>
                                <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            november 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li>
                                <li className="has_event bg_green event_start"><span>6</span></li>
                                <li className="has_event bg_green"><span>7</span></li>
                                <li className="has_event bg_green"><span>8</span></li>
                                <li className="has_event bg_green"><span>9</span></li>
                                <li className="has_event bg_green event_end"><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li className="has_event bg_green event_end"><span>13</span></li><li><span>14</span></li><li><span>15</span></li>
                                <li><span>16</span></li><li className="has_event bg_blue event_start"><span>17</span></li>
                                <li className="has_event bg_blue event_end"><span>18</span></li><li><span>19</span></li><li><span>20</span></li>
                                <li><span>21</span></li><li><span>22</span></li><li><span>23</span></li><li><span>24</span></li><li><span>25</span></li>
                                <li><span>26</span></li><li><span>27</span></li><li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3 calendar-box-holder">
                    <div className="calendar-box">
                        <div className="box-title">
                            december 2021
                        </div>
                        <div className="box-content">
                            <ul className="weekdays">
                                <li>Su</li><li>M</li><li>Tu</li>
                                <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                            </ul>
                            <ul className="days">  
                                <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                <li><span>5</span></li><li><span>6</span></li>
                                <li><span>7</span></li><li><span>8</span></li>
                                <li><span>9</span></li><li><span>10</span></li>
                                <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                <li><span>15</span></li>
                                <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                <li className="has_event bg_green event_start"><span>20</span></li>
                                <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                <li className="has_event bg_green"><span>25</span></li>
                                <li className="has_event bg_green event_end"><span>26</span></li>
                                <li className="has_event bg_green event_end"><span>27</span></li>
                                <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>

      </div>
    </div>
  );
}

export default YearlyCalendarPage;