import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField } from '@mui/material';

import './LoginPage.scss';
import imgLogo from '../../../../assets/images/logo-with-text.png';
import imgSlide1 from '../../../../assets/images/auth/custom_user_portal.svg'
import imgSlide2 from '../../../../assets/images/auth/customize_privileges.svg'
import imgSlide3 from '../../../../assets/images/auth/powerful_payout_rules.png'
import imgSlide4 from '../../../../assets/images/auth/create_invoices.svg'
import imgSlide5 from '../../../../assets/images/auth/invoices_with_company_logo.svg'
import imgSlide6 from '../../../../assets/images/auth/connect_payment_gateways.svg'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel'

interface State {
    password: string;
    showPassword: boolean;
}

function LoginPage(){
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('full_width_no_sidemenu')
        return () => {
            document.body.classList.remove('full_width_no_sidemenu')
        }
    }, [])
    const [values, setValues] = React.useState<State>({
        password: '',
        showPassword: false,
    });
    const handleChange =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
        ...values,
        showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    var items = [
        imgSlide1,
        imgSlide2,
        imgSlide3,
        imgSlide4,
        imgSlide5,
        imgSlide6
    ]
    const anArrayOfNumbers = [
        'Provide owners with a custom user portal to view reports and track their performance',
        'Customize the privileges for each owner’s account',
        'Create powerful payout rules to calculate the payable amount for each booking',
        'Create invoices to bill owners for bookings you generate',
        'Fully brand your owner invoices with your company logo',
        'Connect payment gateways to easily set up credit card payments to owners',
    ];
    return (
        <div className="login-page">
            <div className="lhs_sec">
                <div className="row justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <div className="logo_holder">
                            <img src={imgLogo} alt="Owner Center" />
                        </div>
                        <div className="form_holder">
                            <div className="input_box">
                                <label>Email <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    placeholder="john@company.com"
                                    type="email" 
                                    size="small"
                                    className="mui_dflt_outlined_input" 
                                    required
                                />
                            </div>
                            <div className="input_box mb-2">
                                <label>Password <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Password"
                                    size="small"
                                    className="mui_dflt_outlined_input" 
                                    required
                                    type={values.showPassword ? 'text' : 'password'}
                                    value={values.password}
                                    onChange={handleChange('password')}
                                    InputProps={{
                                        endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                />
                            </div>
                            <div className="input_box">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <FormControlLabel className="check_box" control={<Checkbox />} label="Remember Me" />
                                    </div>
                                    <div>
                                        <Link to="#">Forget Password</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="btn_holder">
                                <Button
                                    fullWidth
                                    className="btn_mui_primary text-uppercase"
                                    variant="contained"
                                >
                                    Log me in
                                </Button>
                            </div>
                            <p>
                                Don't have an account? <Link to="/signup">Signup</Link>
                            </p>
                        </div>
                        <div className="ftr_menus">
                            <Link to="#" className="active">English</Link>
                            <Link to="#">Español</Link>
                            <Link to="#">Français</Link>
                            <Link to="#">Deutsch</Link>
                            <Link to="#">Italiano</Link>
                            <Link to="#">Português</Link>
                            <Link to="#">Nederlands</Link>
                            <Link to="#">Русский</Link>
                            <Link to="#">日本語</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rhs_sec">
                <div className="row justify-content-center">
                    <div className="col-md-11 col-xl-10">
                        <h1 className="title">Owner Management Made Easy</h1>
                        <h4 className="subtitle">Features</h4>
                        <Carousel IndicatorIcon={anArrayOfNumbers}
                            className="auth_carosel"
                            indicatorContainerProps={{
                                className:"indicator_holder",
                        
                            }}     
                            activeIndicatorIconButtonProps={{
                                className: "active"
                            }}
                            navButtonsAlwaysInvisible={true}   
                            interval={3000}     
                            animation="fade"
                            duration={500}
                            stopAutoPlayOnHover={false}
                        >
                            {
                                items.map( (item, i) => <Item key={i} item={item} /> )
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
}
function Item(props: any){
    return (
        <div className="slide">
            <img src={props.item} alt="" />
        </div>
    )
}
export default LoginPage;