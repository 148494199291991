import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import './EditHoldEventPopup.scss';

export default function EditHoldEventPopup(props: any) {
  return (
    <Dialog
        maxWidth='sm'
        fullWidth
        className="edit_hold_event_popup"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
      >
        <DialogTitle className="dialog_title_box">
            <div className="title_holder">
                <h4>Edit Hold Event</h4>
            </div>
            <IconButton onClick={props.onClose} className="close_btn">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content_box">
            <div className="row">
                <div className="col-sm-6">
                    <div className="input_box">
                        <label>Title <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input" 
                            defaultValue="Reserved - Lou (Homea…"
                            required
                        />
                    </div>
                    <div className="input_box">
                        <label>Status</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={'Confirmed'}
                            >
                                <MenuItem value={'Confirmed'}>Confirmed</MenuItem>
                                <MenuItem value={'Pending'}>Pending</MenuItem>
                                <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="input_box">
                        <label>Rentals</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={'Sandriti Villa'}
                            >
                                <MenuItem value={'Sandriti Villa'}>Sandriti Villa</MenuItem>
                                <MenuItem value={'Bunk Boutique Hostel'}>Bunk Boutique Hostel</MenuItem>
                                <MenuItem value={'Koon Cha Learn'}>Koon Cha Learn</MenuItem>
                                <MenuItem value={'ETZzz Hostel Bangkok'}>ETZzz Hostel Bangkok</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="input_box">
                        <label>From: MM/DD/YYYY <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon" 
                            defaultValue="22 - Jan - 2020"
                            required
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton size="small">
                                        <TodayOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="input_box">
                        <label>Until: MM/DD/YYYY <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon" 
                            defaultValue="29 - Jan - 2020"
                            required
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton size="small">
                                        <TodayOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="input_box">
                        <label>Expiration: MM/DD/YYYY</label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon" 
                            defaultValue="23 - Jan - 2020"
                            InputProps={{
                                endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton size="small">
                                        <TodayOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </div>
            </div>
        </DialogContent>
        <DialogActions className="dialog_footer_box">
            <div className="row justify-content-between">
                <div className="col-auto flx-box">
                    <Button 
                        variant='outlined'
                        className="btn_mui_dflt_outline text-danger text-uppercase no_shadow"
                        startIcon={<DeleteOutlineOutlinedIcon style={{ fontSize: 20 }} />}
                    >delete</Button>
                </div>
                <div className="col-auto flx-box">
                    <Button variant='outlined' className="btn_mui_light_blue text-uppercase" onClick={props.onClose}>cancel</Button>
                    <Button
                        className="btn_mui_primary text-uppercase"
                        variant="contained"
                        startIcon={<RefreshOutlinedIcon style={{ fontSize: 20 }} />}
                    >
                        Update
                    </Button>
                </div>
            </div>
        </DialogActions>
    </Dialog>
  );
}