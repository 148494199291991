import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip, InputBase, IconButton, ToggleButtonGroup, 
ToggleButton, ButtonGroup, Menu, MenuItem, Divider, Badge, Tabs, Tab, Switch, Accordion, AccordionSummary, AccordionDetails, TextField, InputAdornment} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

import WeeklyTable from './WeeklyTable/WeeklyTable';
import MonthlyTable from './MonthlyTable/MonthyTable';

import './StandardCalendarPage.scss';
import imageRental_1 from '../../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../../assets/images/placeholder/thumb-5.jpg';

// Filter Dropdown
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`filter-tabpanel-${index}`}
        aria-labelledby={`filter-tab-${index}`}
        {...other}
        >
        {value === index && (
            <div className="tab_sub_panel">
            {children}
            </div>
        )}
        </div>
    );
}
function FIlterProps(index: number) {
    return {
        id: `filter-tab-${index}`,
        'aria-controls': `filter-tabpanel-${index}`,
    };
}
// End Filter Dropdown

function StandardCalendarPage(){
  const [calendarType, setcalendarType] = React.useState('Weekly');

  const handleChangeCalendarType = (
    event: React.MouseEvent<HTMLElement>,
    newcalendarType: string | null,
  ) => {
    if (newcalendarType !== null) {
      setcalendarType(newcalendarType);
    }
  };

  const [ShowWeeklyTable,setShowWeeklyTable] = useState(true);
  const [ShowMonthlyTable,setShowMonthlyTable] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Filter Dropdowns
  const [anchorElView, setAnchorElView] = React.useState<null | HTMLElement>(null);
  const openView = Boolean(anchorElView);
  const handleClickView = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElView(event.currentTarget);
  };
  const handleCloseView = () => {
      setAnchorElView(null);
  };
  
  const [anchorElFilter, setAnchorElFilter] = React.useState<null | HTMLElement>(null);
  const openFilter = Boolean(anchorElFilter);
  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElFilter(event.currentTarget);
  };
  const handleCloseFilter = () => {
      setAnchorElFilter(null);
  };
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
  };
  // const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [expanded, setExpanded] = React.useState<string | false>('');

  const handleChangeFilterAc =
      (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
  };
  // End Filter Dropdown

  return (
    <div className="standard-calendar-page page_holder">
      <div className="header sticky-top">
        <h4>Calendar</h4>
        <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-info"></i>
            </Link>
        </Tooltip>
        <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-video" style={{fontSize: 16}}></i>
            </Link>
        </Tooltip>
      </div>
      <div className="content_holder">
        <div className="row justify-content-between align-items-center title-holder">
            <div className="col-auto title">
                <h4>Standard Calendar</h4>
                <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-info"></i>
                    </Link>
                </Tooltip>
                <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-video" style={{fontSize: 11}}></i>
                    </Link>
                </Tooltip>
            </div>
            <div className="col-auto">
                <div className="btn-group-box">
                    <Link to="/calendar/multi">
                        <Tooltip title="Multi Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-multi-calendar-month"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/standard">
                        <Tooltip title="Standard Calendar" placement="bottom" arrow>
                            <Button variant="text" className="active">
                                <i className="icon icon-standard-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/planning">
                        <Tooltip title="Planning Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-planning-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/yearly">
                        <Tooltip title="Yearly Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-multi-calendar-year"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/rates">
                        <Tooltip title="Rates Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-rates-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                </div>
            </div>
        </div>
        <div className="row justify-content-between align-items-center header-input-box">
            <div className="col-auto flx-box">
                <ToggleButtonGroup
                    className="status_toggle_btn_grp"
                    value={calendarType}
                    exclusive
                    onChange={handleChangeCalendarType}
                    >
                    <ToggleButton value="Weekly" onClick={()=>{setShowWeeklyTable(true); setShowMonthlyTable(false);}}>
                        <i className="icon icon-selected"></i>
                        Weekly
                    </ToggleButton>
                    <ToggleButton value="Monthly" onClick={()=>{setShowWeeklyTable(false); setShowMonthlyTable(true);}}>
                        <i className="icon icon-selected"></i>
                        Monthly
                    </ToggleButton>
                </ToggleButtonGroup>
                
                {ShowWeeklyTable && (
                    <div className="range_date_picker_holder">
                        <InputBase
                            className="input_date"
                            defaultValue="4 - Aug - 2020"
                        />
                        <span>to</span>
                        <InputBase
                            className="input_date"
                            defaultValue="10 - Aug - 2021"
                        />
                        <IconButton type="button" sx={{ p: '10px' }}>
                            <i className="icon icon-standard-calendar"></i>
                        </IconButton>
                    </div>
                )}

            </div>
            <div className="col-auto flx-box">
                <Tooltip title="Refresh" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                        <RefreshIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Button variant="outlined" className="btn_mui_dflt_outline">
                    Today
                </Button>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">2020</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none"> 
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">September</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
            <div className="col-auto flx-box">

                {ShowMonthlyTable && (
                    <>
                    <Tooltip title="Settings" placement="bottom" arrow>
                        <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary" onClick={handleClickFilter}>
                            <Badge color="error" variant="dot">
                                <SettingsOutlinedIcon />
                            </Badge>
                        </Button>
                    </Tooltip>
                    <Menu
                        className="dropdown_filter_settings"
                        anchorEl={anchorElFilter}
                        open={openFilter}
                        onClose={handleCloseFilter}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            minWidth: 180,
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <div className="dropdown_header">
                            <Tabs
                                value={value}
                                scrollButtons="auto"
                                onChange={handleChange}
                            >
                                <Tab label="Grid" {...FIlterProps(0)} />
                                <Tab label="Filters" {...FIlterProps(1)} />
                            </Tabs>
                        </div>
                        <div className="dropdown_body">
                            <TabPanel value={value} index={0}>
                                <div className="grid_holder">
                                    <div className="flx_box">
                                        <div className="flx_txt"><DragIndicatorOutlinedIcon /> Expense Date</div>
                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                    </div>
                                    <div className="flx_box">
                                        <div className="flx_txt"><DragIndicatorOutlinedIcon /> Due Date</div>
                                        <div><Switch className="mui_dflt_switch" /></div>
                                    </div>
                                    <div className="flx_box">
                                        <div className="flx_txt"><DragIndicatorOutlinedIcon /> Rental</div>
                                        <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                    </div>
                                    <div className="flx_box">
                                        <div className="flx_txt"><DragIndicatorOutlinedIcon /> Total</div>
                                        <div><Switch className="mui_dflt_switch" /></div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div className="filter_holder">
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangeFilterAc('panel1')}>
                                        <AccordionSummary
                                        expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                        >
                                            <div className="box_title">
                                                Rentals <span>2</span>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="rental_holder">
                                                <TextField 
                                                    variant="outlined"
                                                    placeholder="Search" 
                                                    fullWidth
                                                    type="search" 
                                                    size="small"
                                                    className="mui_dflt_outlined_input has_l_icon search-holder" 
                                                    InputProps={{
                                                        startAdornment: 
                                                        <InputAdornment position="start">
                                                            <IconButton size="small">
                                                                <SearchOutlinedIcon />
                                                            </IconButton>
                                                        </InputAdornment>,
                                                    }}
                                                />
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_1} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>The Like View Guesthouse</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                </div>
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_2} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>Koon Cha Learn</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch className="mui_dflt_switch" /></div>
                                                </div>
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_3} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>Bunk Boutique Hostel C…</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch className="mui_dflt_switch" /></div>
                                                </div>
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_4} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>Pong Phen Guesthouse</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                                </div>
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_5} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>The Like View Guesthouse</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch className="mui_dflt_switch" /></div>
                                                </div>
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_3} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>The Like View Guesthouse</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch className="mui_dflt_switch" /></div>
                                                </div>
                                                <div className="flx_box">
                                                    <div className="rental_box">
                                                        <div className="img_holder">
                                                            <img src={imageRental_2} alt="" />
                                                        </div>
                                                        <div className="rental_dsc">
                                                            <h4>ETZzz Hostel Bangkok</h4>
                                                        </div>
                                                    </div>
                                                    <div><Switch className="mui_dflt_switch" /></div>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangeFilterAc('panel2')}>
                                        <AccordionSummary
                                        expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                        >
                                            <div className="box_title">
                                                Status
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                                <div className="flx_box">
                                                <div>Due Date</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div>Rental</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div>Total</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangeFilterAc('panel3')}>
                                        <AccordionSummary
                                        expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                        >
                                            <div className="box_title">
                                                Category
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="flx_box">
                                                <div>Due Date</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div>Rental</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div>Total</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangeFilterAc('panel4')}>
                                        <AccordionSummary
                                        expandIcon={<ChevronRightOutlinedIcon sx={{ fontSize: 20 }} />}
                                        >
                                            <div className="box_title">
                                                Category
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="flx_box">
                                                <div>Due Date</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div>Rental</div>
                                                <div><Switch defaultChecked className="mui_dflt_switch" /></div>
                                            </div>
                                            <div className="flx_box">
                                                <div>Total</div>
                                                <div><Switch className="mui_dflt_switch" /></div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </TabPanel>
                        </div>
                        <div className="dropdown_ftr">
                            <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase">
                                Clear
                            </Button>
                            <Button className="btn_mui_primary no_shadow text-uppercase" variant="contained">
                                Apply
                            </Button>
                        </div>
                    </Menu>
                    <Tooltip title="View" placement="bottom" arrow>
                        <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary" onClick={handleClickView}>
                            <Badge color="error" variant="dot">
                                <VisibilityOutlinedIcon />
                            </Badge>
                        </Button>
                    </Tooltip>
                    <Menu
                        className="dflt_dropdown_mui dropdown_clumn_view"
                        anchorEl={anchorElView}
                        open={openView}
                        onClose={handleCloseView}
                        onClick={handleCloseView}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            minWidth: 180,
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <SaveOutlinedIcon />
                            Save New View
                        </MenuItem>
                        <MenuItem>
                            <CachedIcon />
                            Update Current View
                        </MenuItem>
                        <MenuItem>
                            <DeleteOutlineOutlinedIcon />
                            Delete Current View
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                            <ViewComfyOutlinedIcon />
                            Default View
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                            <PushPinOutlinedIcon />
                            New Inquiries View 
                        </MenuItem>
                        <MenuItem>
                            <PushPinOutlinedIcon />
                            Pong Boutique View
                        </MenuItem>
                        <MenuItem className="selected">
                            <PushPinOutlinedIcon />
                            Booking New View
                        </MenuItem>
                        <MenuItem>
                            <PushPinOutlinedIcon />
                            My Channels View
                        </MenuItem>
                    </Menu>
                    </>
                )}

                <Button
                    className="btn_mui_primary has_split_icon"
                    variant="contained"
                    onClick={handleClick}
                    aria-controls={open ? 'add-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    startIcon={<AddIcon style={{ fontSize: 20 }} />}
                    endIcon={<KeyboardArrowDownIcon className="split_icon" />}
                >
                    ADD
                </Button>
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={anchorEl}
                    id="add-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: 180,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuItem>
                        Add Booking
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        Add Hold Event
                    </MenuItem>
                </Menu>

                <Tooltip title="Share" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <ShareOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Upload" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <CloudUploadOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Print" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <LocalPrintshopOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
            </div>
        </div>

        {/* Weekly table */}
        {ShowWeeklyTable && (
            <div className="container-box">
                <WeeklyTable />
            </div>
        )}

        {/* Monthly Table */}
        {ShowMonthlyTable && (
            <div className="container-box">
                <MonthlyTable />
            </div>
        )}

      </div>
    </div>
  );
}

export default StandardCalendarPage;