import React from 'react';
import { Link } from 'react-router-dom';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InvoicesOverlay from '../pages/invoices/InvoiceOverlay/InvoicesOverlay';
import StatementOverlay from '../pages/statements/StatementOverlay/StatementOverlay';
import BookingPopup from '../pages/bookings/BookingPopup/BookingPopup';
import EditHoldEventPopup from '../pages/bookings/EditHoldEventPopup/EditHoldEventPopup';
import CreateBookingOverlay from '../pages/bookings/CreateBookingOverlay/CreateBookingOverlay';
import AddRentalManuallyOverlay from '../pages/rentals/AddRentalManuallyOverlay/AddRentalManuallyOverlay';
import ImportRentalOverlay from '../pages/rentals/ImportRentalOverlay/ImportRentalOverlay';
import RentalsOverlay from '../pages/rentals/RentalsOverlay/RentalsOverlay';

function SitemapPage(){
    // Invoice Overlay
    const [InvoicesOverlayOpen, setInvoicesOverlayOpen] = React.useState(false);
    const openInvoicesOverlay = (newOpen: boolean) => () => {
        setInvoicesOverlayOpen(newOpen);
    };
    // Statement Overlay
    const [StatementOverlayOpen, setStatementOverlayOpen] = React.useState(false);
    const openStatementOverlay = (newOpen: boolean) => () => {
        setStatementOverlayOpen(newOpen);
    };
    // Booking Popup
    const [openBookingPopup, setBookingPopup] = React.useState(false);
    const handleClickBookingPopupOpen = () => {
        setBookingPopup(true);
    };
    const handleBookingPopupClose = () => {
        setBookingPopup(false);
    };
    // Edit Hold Event Popup
    const [openEditHoldEventPopup, setEditHoldEventPopup] = React.useState(false);
    const handleClickEditHoldEventPopupOpen = () => {
        setEditHoldEventPopup(true);
    };
    const handleEditHoldEventPopupClose = () => {
        setEditHoldEventPopup(false);
    };
    // Create Booking Overlay
    const [CreateBookingOverlayOpen, setCreateBookingOverlayOpen] = React.useState(false);
    const openCreateBookingOverlay = (newOpen: boolean) => () => {
        setCreateBookingOverlayOpen(newOpen);
    };
    // Add Rental (Manually) Overlay
    const [AddRentalManuallyOverlayOpen, setAddRentalManuallyOverlayOpen] = React.useState(false);
    const openAddRentalManuallyOverlay = (newOpen: boolean) => () => {
        setAddRentalManuallyOverlayOpen(newOpen);
    };
    // Import rentals (Airbnb URL)
    const [ImportRentalOverlayOpen, setImportRentalOverlayOpen] = React.useState(false);
    const openImportRentalOverlay = (newOpen: boolean) => () => {
        setImportRentalOverlayOpen(newOpen);
    };
    // Rentals Overlay
    const [RentalsOverlayOpen, setRentalsOverlayOpen] = React.useState(false);
    const openRentalsOverlay = (newOpen: boolean) => () => {
        setRentalsOverlayOpen(newOpen);
    };

  return (
    <div className="page_holder">
        <div className="header sticky-top has_searh_box">
            <TextField 
                variant="outlined"
                placeholder="Search" 
                type="search" 
                size="small"
                className="mui_dflt_outlined_input has_l_icon search_header"
                InputProps={{
                    startAdornment: 
                    <InputAdornment position="start">
                        <IconButton size="small">
                            <SearchOutlinedIcon />
                        </IconButton>
                    </InputAdornment>,
                }}
            />
        </div>
        <div className="content_holder">
            <h4>OC-771: Add different other calendars for Owners</h4>
            <Link to="/calendar/multi" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Multi Calendar Page</Button>
            </Link>
            <Link to="/calendar/planning" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Planning Calendar Page</Button>
            </Link>
            <Link to="/calendar/standard" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Standard Calendar(Weekly/Monthly) Page</Button>
            </Link>
            <Link to="/calendar/yearly" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Yearly Calendar Page</Button>
            </Link>
            <Link to="/calendar/rates" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Rates Calendar Page</Button>
            </Link>
            
            <br /><br /><br />
            <h4>OC-791: Design Owner Portal Remaining screens</h4>
            <Link to="/payments" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Payments Page</Button>
            </Link>
            <Link to="/payments/add-payments" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Add Payments Page</Button>
            </Link>
            <Link to="/statements" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Statements Page</Button>
            </Link>
            <Link to="/invoices" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Invoices Page</Button>
            </Link>
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openInvoicesOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary">Invoices Overlay</Button>
            </Link>
            <InvoicesOverlay 
                open={InvoicesOverlayOpen}
                onClose={openInvoicesOverlay(false)}
            />
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openStatementOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary" >Statement Overlay</Button>
            </Link>
            <StatementOverlay 
                open={StatementOverlayOpen}
                onClose={openStatementOverlay(false)}
            />

            <br /><br /><br />
            <h4>OC-792: [Owner Portal] Design Bookings related screens</h4>
            <Link to="/bookings" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Bookings Page</Button>
            </Link>
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={handleClickBookingPopupOpen}>
                <Button variant="contained" className="btn_mui_primary">Booking Popup</Button>
            </Link>
            <BookingPopup 
                onClose={handleBookingPopupClose}
                open={openBookingPopup}
            />
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={handleClickEditHoldEventPopupOpen}>
                <Button variant="contained" className="btn_mui_primary">Edit Hold Event Popup</Button>
            </Link>
            <EditHoldEventPopup 
                onClose={handleEditHoldEventPopupClose}
                open={openEditHoldEventPopup}
            />
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openCreateBookingOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary" >Create Booking Overlay</Button>
            </Link>
            <CreateBookingOverlay 
                open={CreateBookingOverlayOpen}
                onClose={openCreateBookingOverlay(false)}
            />

            <br /><br /><br />
            <h4>OC-785: Registration Process for Owners</h4>
            <Link to="/login" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Login Page</Button>
            </Link>
            <Link to="/signup" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Signup Page</Button>
            </Link>


            <br /><br /><br />

            <h4>OC-790: Design Owner Portal Rental Screens</h4>
            <Link to="/rentals" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}}>
                <Button variant="contained" className="btn_mui_primary">Rentals Page</Button>
            </Link>
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openAddRentalManuallyOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary">Add Rental (Manually) Overlay</Button>
            </Link>
            <AddRentalManuallyOverlay 
                open={AddRentalManuallyOverlayOpen}
                onClose={openAddRentalManuallyOverlay(false)}
            />
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openImportRentalOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary">Import rentals (Airbnb URL) -- Processing/Success View</Button>
            </Link>
            <ImportRentalOverlay 
                open={ImportRentalOverlayOpen}
                onClose={openImportRentalOverlay(false)}
            />
            <br /><br />
            <h4>Rental Overlays and all Tabs</h4>
            <Link to="#" style={{ margin: 10, marginLeft: 0, display: 'inline-block'}} onClick={openRentalsOverlay(true)}>
                <Button variant="contained" className="btn_mui_primary">Rentals Overlay</Button>
            </Link>
            <RentalsOverlay 
                open={RentalsOverlayOpen}
                onClose={openRentalsOverlay(false)}
            />
            <br />
            <ul>
                <li>
                    Tab: <strong>Rental Details</strong>
                    <ul>
                        <li>Sub Tab: <strong>Basic Edit</strong></li>
                        <li>Sub Tab: <strong>Detail Information</strong></li>
                        <li>Sub Tab: <strong>Rental Amenities</strong></li>
                        <li>Sub Tab: <strong>Rental Gallery</strong></li>
                        <li>Sub Tab: <strong>RATES CALENDAR</strong></li>
                        <li>Sub Tab: <strong>RENTAL TAGS</strong></li>
                        <li>Sub Tab: <strong>RENTAL TAX & VAT</strong></li>
                        <li>Sub Tab: <strong>RENTAL FEES</strong></li>
                        <li>Sub Tab: <strong>ACTIVITY LOGS</strong></li>
                        <li>Sub Tab: <strong>CLEANING</strong></li>
                        <li>Sub Tab: <strong>MANAGE REVIEWS</strong></li>
                        <li>Sub Tab: <strong>MAP</strong></li>
                    </ul>
                </li>
                <li>Tab: <strong>Inquries</strong></li>
                <li>Tab: <strong>Expenses</strong></li>
                <li>Tab: <strong>Tasks</strong></li>
                <li>Tab: <strong>Channel Details</strong></li>
            </ul>
        </div>
    </div>
  );
}

export default SitemapPage;