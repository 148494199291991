import React from 'react';

import imageWebready from '../../../../../assets/images/placeholder/webready_icon.png';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import FlightLandOutlinedIcon from '@mui/icons-material/FlightLandOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';

function MonthlyTable(){

  return (
    <div className="table-responsive monthly-table-holder">
        <table className="table monthly-table">
            <thead>
                <tr>
                    <th>
                        <span className="day">Mon</span>
                    </th>
                    <th>
                        <span className="day">Tue</span>
                    </th>
                    <th>
                        <span className="day">Wed</span>
                    </th>
                    <th>
                        <span className="day">Thu</span>
                    </th>
                    <th>
                        <span className="day">Fri</span>
                    </th>
                    <th>
                        <span className="day">Sat</span>
                    </th>
                    <th>
                        <span className="day">Sun</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className="disable">
                        <span className="date">26</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="disable">
                        <span className="date">27</span>
                    </td>
                    <td className="disable">
                        <span className="date">28</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="disable">
                        <span className="date">29</span>
                    </td>
                    <td className="disable">
                        <span className="date">30</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="disable">
                        <span className="date">31</span>
                    </td>
                    <td>
                        <span className="date">1</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="date">2</span>
                    </td>
                    <td>
                        <span className="date">3</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">4</span>
                        <div className="event">
                            <div className="left_skew_box"></div>
                            <div className="event_dsc_holder b_cancelled">
                                <div className="event_dsc">
                                    <img src={imageWebready} alt="" />
                                    David Pollack 
                                    <span className="sep_dot">•</span> Webready 
                                    <span className="sep_dot">•</span> Cancelled 
                                    <span className="sep_dot">•</span> The
                                </div>
                            </div>
                            <div className="right_skew_box"></div>
                        </div>
                    </td>
                    <td>
                        <span className="date">5</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">6</span>
                    </td>
                    <td>
                        <span className="date">7</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">8</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="date">9</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                                <div>
                                    <FlightLandOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>8</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">10</span>
                    </td>
                    <td>
                        <span className="date">11</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">12</span>
                        <div className="event">
                            <div className="left_skew_box"></div>
                            <div className="event_dsc_holder b_confirmed">
                                <div className="event_dsc">
                                    <img src={imageWebready} alt="" />
                                    David Pollack 
                                    <span className="sep_dot">•</span> Webready 
                                    <span className="sep_dot">•</span> Confirmed 
                                    <span className="sep_dot">•</span> The
                                </div>
                            </div>
                            <div className="right_skew_box"></div>
                        </div>
                    </td>
                    <td>
                        <span className="date">13</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">14</span>
                    </td>
                    <td>
                        <span className="date">15</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="date">16</span>
                    </td>
                    <td>
                        <span className="date">17</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">18</span>
                    </td>
                    <td>
                        <span className="date">19</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">20</span>
                    </td>
                    <td>
                        <span className="date">21</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">22</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="date">23</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">24</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                        <div className="event">
                            <div className="left_skew_box"></div>
                            <div className="event_dsc_holder b_pending">
                                <div className="event_dsc">
                                    <img src={imageWebready} alt="" />
                                    David Pollack 
                                    <span className="sep_dot">•</span> Webready 
                                    <span className="sep_dot">•</span> Pending 
                                    <span className="sep_dot">•</span> The
                                </div>
                            </div>
                            <div className="right_skew_box"></div>
                        </div>
                    </td>
                    <td>
                        <span className="date">25</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">26</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">27</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <FlightTakeoffOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>6</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">28</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">29</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span className="date">30</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span className="date">31</span>
                    </td>
                    <td className="disable">
                        <span className="date">1</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="disable">
                        <span className="date">2</span>
                    </td>
                    <td className="disable">
                        <span className="date">3</span>
                        <div className="flx-icon-box-holder">
                            <div className="flx_icon_box">
                                <div>
                                    <CleaningServicesOutlinedIcon sx={{ fontSize: 13 }} />
                                    <span>2</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td className="disable">
                        <span className="date">4</span>
                    </td>
                    <td className="disable">
                        <span className="date">5</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
  );
}

export default MonthlyTable;