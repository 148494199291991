import * as React from 'react';
import { Button, Drawer, IconButton, Tab, Tabs } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CircleIcon from '@mui/icons-material/Circle';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';

import RentalDetailsTab from './RentalDetailsTab/RentalDetailsTab';
import InquiryTab from './InquiryTab/InquiryTab';
import ChannelDetailsTab from './ChannelDetails/ChannelDetailsTab';
import TasksTab from './TasksTab/TasksTab';
import ExpensesTab from './ExpensesTab/ExpensesTab';

import './RentalsOverlay.scss';
import imgRental from '../../../../assets/images/placeholder/thumb-3.jpg';
import imgUser from '../../../../assets/images/placeholder/user-3.png';
import imgTokeetIcon from '../../../../assets/images/channels/tokeet.png';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`rental-tabpanel-${index}`}
      className="full_height position-relative"
      aria-labelledby={`rental-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div className="tab_panel_holder">
          {children}
        </div>
      )}
    </div>
  );
}

function rentalTabsProps(index: number) {
  return {
    id: `rental-tab-${index}`,
    'aria-controls': `rental-tabpanel-${index}`,
  };
}
export default function RentalsOverlay(props: any) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
    return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
        className="rentals_overlay_holder"
    >
        <Button 
            className="btn_circle_close" 
            onClick={props.onClose}
        >
            <CloseIcon />
        </Button>
        <div className="sidebar_right">
          <Button 
              className="btn_esc_close" 
              onClick={props.onClose}
          >
              <CloseIcon style={{ fontSize: 22 }} />
              <br />
              ESC
          </Button>
        </div>

        <div className="d_header">
          <TabPanel value={value} index={0}>
            <div className="header_flx_box">
                <div className="avater_box">
                  <img src={imgRental} alt="" />
                </div>
                <div className="title_box">
                  <h4>
                    The Like View Guesthouse
                    <IconButton className="dropdown_btn">
                      <KeyboardArrowDownIcon sx={{fontSize: 31}} />
                    </IconButton>
                  </h4>
                  <p>Capital International Airport Beijing China 100621</p>
                </div>
                <div className="btn_box">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase">
                    view summary
                  </Button>
                </div>
            </div> 
            <div className="btn_holder">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>cancel</Button>
                  <Button
                      className="btn_mui_primary text-uppercase"
                      variant="contained"
                      startIcon={<SaveOutlinedIcon style={{ fontSize: 20 }} />}
                  >
                      Save
                  </Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div className="header_flx_box">
                <div className="avater_box">
                  <img src={imgUser} alt="" />
                </div>
                <div className="title_box">
                  <h4>
                    Ruben Clark
                    <label>Arriving</label>
                    <img src={imgTokeetIcon} alt="" />
                    <CircleIcon style={{ fontSize: 10, color: '#EA6161' }} />
                  </h4>
                  <h6>
                    Butterfly Apartment  • ruben@gmail.com
                  </h6>
                  <p>
                    12 - Jul - 2021 to 22 - Jul - 2021 • 
                    <span>
                      <PeopleOutlineRoundedIcon style={{ fontSize: 14 }} />
                      3
                    </span>
                    <span>
                      <ChildCareOutlinedIcon style={{ fontSize: 14 }} />
                      2
                    </span>
                    <span>
                      <LightModeOutlinedIcon style={{ fontSize: 14 }} />
                      11
                    </span>
                    <span>
                      <NightlightOutlinedIcon style={{ fontSize: 14 }} />
                      10
                    </span>
                  </p>
                </div>
            </div> 
            <div className="btn_holder">
                <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>Close</Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <div className="header_flx_box">
                <div className="avater_box">
                  <img src={imgRental} alt="" />
                </div>
                <div className="title_box">
                  <h4>
                    The Like View Guesthouse
                    <IconButton className="dropdown_btn">
                      <KeyboardArrowDownIcon sx={{fontSize: 31}} />
                    </IconButton>
                  </h4>
                  <p>Capital International Airport Beijing China 100621</p>
                </div>
                <div className="btn_box">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase">
                    view summary
                  </Button>
                </div>
            </div> 
            <div className="btn_holder">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>Close</Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <div className="header_flx_box">
                <div className="avater_box">
                  <img src={imgRental} alt="" />
                </div>
                <div className="title_box">
                  <h4>
                    The Like View Guesthouse
                    <IconButton className="dropdown_btn">
                      <KeyboardArrowDownIcon sx={{fontSize: 31}} />
                    </IconButton>
                  </h4>
                  <p>Capital International Airport Beijing China 100621</p>
                </div>
            </div> 
            <div className="btn_holder">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>Close</Button>
            </div>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <div className="header_flx_box">
                <div className="avater_box">
                  <img src={imgRental} alt="" />
                </div>
                <div className="title_box">
                  <h4>
                    The Like View Guesthouse
                    <IconButton className="dropdown_btn">
                      <KeyboardArrowDownIcon sx={{fontSize: 31}} />
                    </IconButton>
                  </h4>
                  <p>Capital International Airport Beijing China 100621</p>
                </div>
                <div className="btn_box">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase">
                    view summary
                  </Button>
                </div>
            </div> 
            <div className="btn_holder">
                  <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>Close</Button>
            </div>
          </TabPanel>
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          allowScrollButtonsMobile
          className="main_tabs sticky-top"
        >
          <Tab label="Rental Details" {...rentalTabsProps(0)} />
          <Tab label="Inquries" {...rentalTabsProps(1)} />
          <Tab label="Expenses" {...rentalTabsProps(2)} />
          <Tab label="Tasks" {...rentalTabsProps(3)} />
          <Tab label="Channel Details" {...rentalTabsProps(4)} />                      
        </Tabs>
        
        <div className="d_content">
          <TabPanel value={value} index={0}>
            <RentalDetailsTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InquiryTab />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ExpensesTab />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TasksTab />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <ChannelDetailsTab />
          </TabPanel>
        </div>
    </Drawer>
    );
}