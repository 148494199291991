import * as React from 'react';
import { FormControl, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import './RentalAmenities.scss';

export default function RentalAmenities() {
    return (
      <div className="rental_amenities_tab_holder">
        <div className="row justify-content-between align-items-center title_holder">
          <div className="col-auto">
            <h4 className="title">RENTAL AMENITIES</h4>
          </div>
          <div className="col-auto flx-box">
            <TextField 
              variant="outlined"
              placeholder="Search" 
              type="search" 
              size="small"
              className="mui_dflt_outlined_input has_l_icon search-holder" 
              InputProps={{
                  startAdornment: 
                  <InputAdornment position="start">
                      <IconButton size="small">
                          <SearchOutlinedIcon />
                      </IconButton>
                  </InputAdornment>,
              }}
            />
            <FormControl sx={{ width: 250 }} className="mui-dflt-select-box">
              <Select
                defaultValue={1}
              >
                <MenuItem value={1}>
                  All Channels
                </MenuItem> 
                <MenuItem value={2}>
                  Option 2
                </MenuItem>
                <MenuItem value={3}>
                  Option 3
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="container-box">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h4>Most common</h4>
              <p>
                24 Hour Check-in<br />
                Activities Older Children<br />
                Activities Young Children<br />
                Adjoining Rooms<br />
                Air Conditioning<br />
                All-Inclusive<br />
                Beach View<br />
                Beach Front<br />
                Bed And Breakfast<br />
                Telephone<br />
                Alarm Clock<br />
                Electric Vehicle Charger<br />
                Elevator<br />
                Living Room<br />
                Public Lounge<br />
                Dining Area<br />
                Garage<br />
                Private Entrance<br />
                Gazebo<br />
                Balcony<br />
                Veranda<br />
                Living Room<br />
                Public Lounge<br />
                Dining Area<br />
                Gazebo
              </p>

              <h4>Heating</h4>
              <p>
                Breakfast<br />
                Business Services<br />
                Cable Television<br />
                Car Rental<br />
                Credit Cards Accepted<br />
                Doorman<br />
                Dryer
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h4>Accessibility</h4>
              <p>
                Indoor Fireplace<br />
                Fitness Center<br />
                Free Breakfast<br />
                Free Cancelation<br />
                Free Housekeeping<br />
                Free Internet<br />
                Free Local Calls<br />
                Free Parking<br />
                Free Wifi<br />
                Freezer<br />
                Games<br />
                Game Room<br />
                Emergency Exit<br />
                Free Parking<br />
                Free Wifi
              </p>

              <h4>Location</h4>
              <p>
                Gym<br />
                Hair Dryer<br />
                Hangers<br />
                Heating<br />
                Hot Tub<br />
                Housekeeping<br />
                Clothes Iron<br />
                Room Service<br />
                Baggage Storage<br />
                Grocery Deliveries<br />
                Housekeeping<br />
                Clothes Iron<br />

                <br /><br />

                Cooking hob<br />
                Kettle
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h4>Bathroom & laundry</h4>
              <p>
                Ironing Board<br />
                Kids Activities<br />
                Late Arrival<br />
                Late Check Out<br />
                Lockers Storage<br />
                Loyalty Rewards Available<br />
                Kids Activities<br />
                Late Arrival
              </p>

              <h4>Entertainment</h4>
              <p>
                Games room<br />
                DVD player<br />
                Radio<br />
                CD player<br />
                Games Console<br />
                iPod docking station<br />
                Board games / puzzles<br />
                Streaming service (such as Netflix)<br />
                Table Tennis Table<br />
                Radio<br />
                CD player
              </p>

              <h4>Consumables</h4>
              <p>
                Bottle of water<br />
                Fruits<br />
                Chocolate/cookies<br />
                Wine/champagne<br />
                Hand sanitiser<br />
                Slippers<br />
                Fruits<br />
                Chocolate/cookies<br />
                Personal meet & greet available<br />
                Electric blankets
              </p>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <h4>Cooking</h4>
              <p>
                Stroller Parking<br />
                Swimming Pool<br />
                Television<br />
                Tennis Court<br />
                Valet Parking<br />
                Washer<br />
                Wheelchair Access<br />
                Swimming Pool<br />
                Television
              </p>

              <h4>Outside</h4>
              <p>
                BBQ/Picnic area<br />
                Outdoor furniture<br />
                Garden<br />
                Beach<br />
                Private beach area<br />
                Terrace<br />
                Bicycle<br />
                Indoor pool<br />
                Massage<br />
                Playground<br />
                Outdoor Fireplace<br />
                Outdoor Furniture<br />
                Ski Storage<br />
                Snow Gear<br />
                Tennis Equipment<br />
                Crib<br />
                Lake<br />
                Lake Front<br />
                Near Ocean<br />
                Ocean Front<br />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}