import * as React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import './DetailInfo.scss';

export default function DetailInfo() {
    return (
      <div className="detail_info_tab_holder">
        <h4 className="title">Detail Information</h4>
        <div className="row">
          <div className="col-12 inputbox_holder">
              <div className="container-box">
                  <h4 className="box_title">RENTAL INFORMATION</h4>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="input_box">
                        <label>Rental Type</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={1}
                            >
                                <MenuItem value={1}>
                                  Apartment
                                </MenuItem> 
                                <MenuItem value={2}>
                                  Condo
                                </MenuItem>
                                <MenuItem value={3}>
                                  House
                                </MenuItem>
                            </Select>
                        </FormControl>
                      </div>
                      <div className="input_box">
                        <label>Enter Rental Description</label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input" 
                            defaultValue="Apartment"
                            multiline
                            rows={5}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="row">
                        <div className="col-md-6 input_box">
                          <label>Size Metric</label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={1}
                              >
                                  <MenuItem value={1}>
                                    SQFT
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    Option 2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    Option 3
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 input_box">
                          <label>Size(SQFT)</label>
                          <TextField 
                              variant="outlined"
                              fullWidth
                              type="text" 
                              size="small"
                              className="mui_dflt_outlined_input" 
                              defaultValue=""
                          />
                        </div>
                        <div className="col-md-6 col-lg-3 input_box">
                          <label>Bedroom</label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={2}
                              >
                                  <MenuItem value={1}>
                                    1
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    3
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 col-lg-3 input_box">
                          <label>Bathroom</label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={2}
                              >
                                  <MenuItem value={1}>
                                    1
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    3
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 col-lg-3 input_box">
                          <label>Sleep Min</label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={3}
                              >
                                  <MenuItem value={1}>
                                    1
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    3
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 col-lg-3 input_box">
                          <label>Sleep Max</label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={4}
                              >
                                  <MenuItem value={1}>
                                    1
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    3
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    4
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 input_box">
                          <label>Block days before booking</label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={1}
                              >
                                  <MenuItem value={1}>
                                    1
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    3
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    4
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                        <div className="col-md-6 input_box">
                          <label>Block days after booking </label>
                          <FormControl className="mui-dflt-select-box" fullWidth>
                              <Select
                                  defaultValue={2}
                              >
                                  <MenuItem value={1}>
                                    1
                                  </MenuItem> 
                                  <MenuItem value={2}>
                                    2
                                  </MenuItem>
                                  <MenuItem value={3}>
                                    3
                                  </MenuItem>
                                  <MenuItem value={4}>
                                    4
                                  </MenuItem>
                              </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <div className="col-lg-6 inputbox_holder">
              <div className="container-box">
                <h4 className="box_title">Check-in / Check-out Times</h4>
                <div className="row">
                  <div className="col-xl-6 input_box">
                    <label>Check-in Time <span className="text-danger">*</span></label>
                    <TextField 
                      variant="outlined"
                      fullWidth
                      type="text" 
                      size="small"
                      className="mui_dflt_outlined_input has_r_icon" 
                      defaultValue="12:00 PM"
                      required
                      InputProps={{
                        endAdornment: 
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <AccessTimeIcon />
                          </IconButton>
                        </InputAdornment>,
                      }}
                    />
                  </div>
                  <div className="col-xl-6 input_box">
                    <label>Check-out Time <span className="text-danger">*</span></label>
                    <TextField 
                      variant="outlined"
                      fullWidth
                      type="text" 
                      size="small"
                      className="mui_dflt_outlined_input has_r_icon" 
                      defaultValue="11:00 AM"
                      required
                      InputProps={{
                        endAdornment: 
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <AccessTimeIcon />
                          </IconButton>
                        </InputAdornment>,
                      }}
                    />
                  </div>
                  <div className="col-xl-6 input_box">
                    <label>Check-in Allowed?</label>
                    <FormGroup row>
                      <FormControlLabel
                        value="SUN"
                        control={<Checkbox defaultChecked />}
                        label="SUN"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="MON"
                        control={<Checkbox />}
                        label="MON"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="TUE"
                        control={<Checkbox />}
                        label="TUE"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="WED"
                        control={<Checkbox />}
                        label="WED"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="THU"
                        control={<Checkbox />}
                        label="THU"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="FRI"
                        control={<Checkbox />}
                        label="FRI"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="SAT"
                        control={<Checkbox />}
                        label="SAT"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-xl-6 input_box">
                    <label>Check-out Allowed?</label>
                    <FormGroup row>
                      <FormControlLabel
                        value="SUN"
                        control={<Checkbox defaultChecked />}
                        label="SUN"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="MON"
                        control={<Checkbox />}
                        label="MON"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="TUE"
                        control={<Checkbox />}
                        label="TUE"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="WED"
                        control={<Checkbox />}
                        label="WED"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="THU"
                        control={<Checkbox />}
                        label="THU"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="FRI"
                        control={<Checkbox />}
                        label="FRI"
                        labelPlacement="top"
                      />
                      <FormControlLabel
                        value="SAT"
                        control={<Checkbox />}
                        label="SAT"
                        labelPlacement="top"
                      />
                    </FormGroup>
                  </div>
                  <div className="col-12 input_box">
                    <label>Timezone <span className="text-danger">*</span></label>
                    <FormControl className="mui-dflt-select-box" fullWidth>
                        <Select
                            defaultValue={1}
                        >
                            <MenuItem value={1}>
                              Africa / Asmara
                            </MenuItem> 
                            <MenuItem value={2}>
                              Atlantic
                            </MenuItem>
                            <MenuItem value={3}>
                              Eastern
                            </MenuItem>
                            <MenuItem value={4}>
                              Central
                            </MenuItem>
                        </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="container-box">
                  <h4 className="box_title">Wi-fi details</h4>
                  <div className="row">
                      <div className="col-xl-6 input_box">
                        <label>Wifi Network</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={1}
                            >
                                <MenuItem value={1}>
                                  AT&T
                                </MenuItem> 
                                <MenuItem value={2}>
                                  Option 2
                                </MenuItem>
                                <MenuItem value={3}>
                                  Option 3
                                </MenuItem>
                                <MenuItem value={4}>
                                  Option 4
                                </MenuItem>
                            </Select>
                        </FormControl>
                      </div>
                      <div className="col-xl-6 input_box">
                          <label>Wifi Password</label>
                          <TextField 
                              variant="outlined"
                              fullWidth
                              type="password" 
                              size="small"
                              className="mui_dflt_outlined_input" 
                              defaultValue="12345678"
                          />
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-lg-6 inputbox_holder">
              <div className="container-box">
                <h4 className="box_title">RENTAL INSTRUCTIONS</h4>
                <div className="row">
                  <div className="col-12 input_box">
                    <label>Payment Terms</label>
                    <TextField 
                      variant="outlined"
                      fullWidth
                      type="text" 
                      size="small"
                      className="mui_dflt_outlined_input" 
                      defaultValue=""
                      multiline
                      rows={2}
                    />
                  </div>
                  <div className="col-12 input_box">
                    <label>Payment Instructions</label>
                    <TextField 
                      variant="outlined"
                      fullWidth
                      type="text" 
                      size="small"
                      className="mui_dflt_outlined_input" 
                      defaultValue=""
                      multiline
                      rows={2}
                    />
                  </div>
                </div>
              </div>
              <div className="container-box">
                  <h4 className="box_title">Parking INSTRUCTIONS</h4>
                  <div className="row">
                      <div className="col-xl-6 input_box">
                        <label>Parking</label>
                        <FormControl className="mui-dflt-select-box" fullWidth>
                            <Select
                                defaultValue={1}
                            >
                                <MenuItem value={1}>
                                  Off-site
                                </MenuItem> 
                                <MenuItem value={2}>
                                  Option 2
                                </MenuItem>
                                <MenuItem value={3}>
                                  Option 3
                                </MenuItem>
                                <MenuItem value={4}>
                                  Option 4
                                </MenuItem>
                            </Select>
                        </FormControl>
                      </div>
                      <div className="col-xl-6 input_box">
                          <label>Type of Parking</label>
                          <TextField 
                              variant="outlined"
                              fullWidth
                              type="text" 
                              size="small"
                              className="mui_dflt_outlined_input" 
                              defaultValue="Valet Parking"
                          />
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    );
}