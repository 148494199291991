import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Drawer, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LensIcon from '@mui/icons-material/Lens';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import './CreateBookingOverlay.scss';
import imgflagIndia from '../../../../assets/images/placeholder/india_flag.svg';
import imgAgoda from '../../../../assets/images/channels/agoda.png';
import imgAirbnb from '../../../../assets/images/channels/airbnb.png';
import imgBookingcom from '../../../../assets/images/channels/booking_com.png';
import imgExpedia from '../../../../assets/images/channels/expedia.png';
import imgHolidu from '../../../../assets/images/channels/holidu.png';
import imgHometogo from '../../../../assets/images/channels/hometogo.png';
import imgMarriott from '../../../../assets/images/channels/marriott.png';
import imgVrbo from '../../../../assets/images/channels/vrbo.png';
import imgWebready from '../../../../assets/images/channels/webready.png';

const rentals = [
    'Nest Villas',
    'The Like View Guesthouse',
    'Koon Cha Learn',
    'Bunk Boutique Hostel ',
    'Pong Phen Guesthouse',
    'ETZzz Hostel Bangkok',
    'Camden Hostel',
    'Fayetteville Hostel',
    'Magnolia Guesthouse',
    'Newport Hostel',
    'Calistoga Guesthouse',
  ];
export default function CreateBookingOverlay(props: any) {

    const [expanded, setExpanded] = React.useState<string | false>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
        };
    return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
        className="create_booking_overlay_holder"
    >
        <Button 
            className="btn_circle_close" 
            onClick={props.onClose}
        >
            <CloseIcon />
        </Button>
        <div className="d_header">
           <div className="title">Create Booking</div> 
           <div className="btn_holder">
                <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>cancel</Button>
                <Button
                    className="btn_mui_primary text-uppercase"
                    variant="contained"
                    startIcon={<SaveOutlinedIcon style={{ fontSize: 20 }} />}
                >
                    Save
                </Button>
           </div>
        </div>
        <div className="d_content">
            <div className="accordion_holder">
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: 31 }} />}
                    >
                        <div className="step-counter">1</div>
                        <div className="title_holder">
                            <h4>Guest Details</h4>
                            <p>Enter details for new or existing guest.</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-md-6 input_box">
                                <label>Search for Guest <span>(Optional)</span></label>
                                <FormControl className="mui-dflt-select-box" fullWidth>
                                    <Select>
                                        <MenuItem value={'Option 1'}>Option 1</MenuItem>
                                        <MenuItem value={'Option 2'}>Option 2</MenuItem>
                                        <MenuItem value={'Option 3'}>Option 3</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-6 input_box">
                                <label>Name <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input" 
                                    defaultValue=""
                                    required
                                />
                            </div>
                            <div className="col-md-6 input_box">
                                <label>Email <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="email" 
                                    size="small"
                                    className="mui_dflt_outlined_input" 
                                    defaultValue=""
                                    required
                                />
                            </div>
                            <div className="col-md-6 input_box">
                                <label>Phone</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="email" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon" 
                                    defaultValue="749 - 459 - 85009"
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="end">
                                            <img src={imgflagIndia} height="18" alt="" />
                                            <IconButton size="small">
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 input_box">
                                <label>Select Channel</label>
                                <FormControl className="mui-dflt-select-box has_img" fullWidth>
                                    <Select
                                        defaultValue={'HomeToGo'}
                                    >
                                        <div className="search_box_under_select">
                                            <TextField 
                                                variant="outlined"
                                                placeholder="Enter New Resource Name" 
                                                type="search" 
                                                size="small"
                                                className="mui_dflt_outlined_input" 
                                            />
                                        </div>
                                        <MenuItem className='d_flx_img_menu_item' value={'Agoda'}>
                                            <img src={imgAgoda} alt="" /> Agoda
                                        </MenuItem>
                                        <MenuItem className='d_flx_img_menu_item' value={'Airbnb API'}>
                                            <img src={imgAirbnb} alt="" /> Airbnb API
                                        </MenuItem>
                                        <MenuItem className='d_flx_img_menu_item' value={'Booking.com'}>
                                            <img src={imgBookingcom} alt="" /> Booking.com
                                        </MenuItem>
                                        <MenuItem className='d_flx_img_menu_item' value={'Expedia'}>
                                            <img src={imgExpedia} alt="" /> Expedia
                                        </MenuItem>    
                                        <MenuItem className='d_flx_img_menu_item' value={'HomeToGo'}>
                                            <img src={imgHometogo} alt="" /> HomeToGo
                                        </MenuItem>    
                                        <MenuItem className='d_flx_img_menu_item' value={'VRBO API'}>
                                            <img src={imgVrbo} alt="" /> VRBO API
                                        </MenuItem>       
                                        <MenuItem className='d_flx_img_menu_item' value={'Marriott'}>
                                            <img src={imgMarriott} alt="" /> Marriott
                                        </MenuItem>     
                                        <MenuItem className='d_flx_img_menu_item' value={'Webready'}>
                                            <img src={imgWebready} alt="" /> Webready
                                        </MenuItem>    
                                        <MenuItem className='d_flx_img_menu_item' value={'Holidu'}>
                                            <img src={imgHolidu} alt="" /> Holidu
                                        </MenuItem>   
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-sm-6 col-md-4 input_box">
                                        <label>Adults <span className="text-danger">*</span></label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_icon_divider" 
                                            defaultValue="2"
                                            required
                                            InputProps={{
                                                startAdornment: 
                                                <InputAdornment position="start">
                                                    <IconButton size="small">
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton size="small">
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-4 input_box">
                                        <label>Children</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_icon_divider" 
                                            defaultValue="1"
                                            InputProps={{
                                                startAdornment: 
                                                <InputAdornment position="start">
                                                    <IconButton size="small">
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton size="small">
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-4 input_box">
                                        <label>Pets</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_icon_divider" 
                                            defaultValue="1"
                                            InputProps={{
                                                startAdornment: 
                                                <InputAdornment position="start">
                                                    <IconButton size="small">
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton size="small">
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="input_box col-md-6">
                                <label>
                                    Flag
                                    <Tooltip title="Flag information goes here." placement="right" arrow>
                                        <HelpOutlineIcon className="info_icon" />
                                    </Tooltip>
                                </label>
                                <div className="row">
                                    <div className="col-sm-6 col-lg-6 mb-2 mb-lg-0">
                                        <Box sx={{ display: 'flex', alignItems: 'center'  }}>
                                            <TextField 
                                                variant="outlined"
                                                fullWidth
                                                type="email" 
                                                size="small"
                                                className="mui_dflt_outlined_input" 
                                                defaultValue=""
                                            />
                                            <LensIcon sx={{ color: '#4693F7', ml: 1, my: 0.5, cursor: 'pointer' }} fontSize="large" />
                                        </Box>
                                    </div>
                                    <div className="col-sm-6 col-lg-6">
                                        <FormControl className="mui-dflt-select-box" fullWidth>
                                            <Select
                                                defaultValue={'Arrived'}
                                            >
                                                <MenuItem value={'Arrived'}>Arrived</MenuItem>
                                                <MenuItem value={'Departed'}>Departed</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="input_box col-md-6">
                                <label>Language</label>
                                <FormControl className="mui-dflt-select-box" fullWidth>
                                    <Select
                                        defaultValue={'English'}
                                    >
                                        <MenuItem value={'English'}>English</MenuItem>
                                        <MenuItem value={'Spanish'}>Spanish</MenuItem>
                                        <MenuItem value={'Mandarin'}>Mandarin</MenuItem>
                                        <MenuItem value={'Russian'}>Russian</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-sm-6 col-lg-3 input_box">
                                <label>Check-in Time</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue="12:00 PM"
                                    required
                                    InputProps={{
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <QueryBuilderIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 input_box">
                                <label>Check-out Time</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue="11:00 AM"
                                    required
                                    InputProps={{
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <QueryBuilderIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>

                            <div className="col-sm-6 input_box">
                                <label>Confirmation Date</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue="18 - Sep - 2020"
                                    InputProps={{
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <TodayOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ fontSize: 31 }} />}
                    >
                        <div className="step-counter">2</div>
                        <div className="title_holder">
                            <h4>Charges Details <span className="text-danger">*</span></h4>
                            <p>Enter charges for the selected rentals</p>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="input_box col-lg-6">
                                <label>Rental <span className="text-danger">*</span></label>
                                <Autocomplete
                                    className="select_rentals_chip"
                                    multiple
                                    limitTags={2}
                                    options={rentals.map((option) => option)}
                                    defaultValue={[rentals[0], rentals[8], rentals[9]]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 input_box">
                                <label>Arrive <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue=""
                                    required
                                    InputProps={{
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <TodayOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 input_box">
                                <label>Depart <span className="text-danger">*</span></label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon" 
                                    defaultValue=""
                                    required
                                    InputProps={{
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <IconButton size="small">
                                                <TodayOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-lg-6 input_box">
                                        <label>Base Charges <span className="text-danger">*</span></label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_icon_divider has_input_icon" 
                                            defaultValue="75"
                                            required
                                            InputProps={{
                                                startAdornment: 
                                                <InputAdornment position="start">
                                                    <IconButton size="small">
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    <AttachMoneyIcon className='input_icon' />
                                                </InputAdornment>,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton size="small">
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                    <div className="col-lg-6 input_box">
                                        <label>Discount & Fees <span className="text-danger">*</span></label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_icon_divider has_input_icon mb-3" 
                                            defaultValue="34"
                                            required
                                            InputProps={{
                                                startAdornment: 
                                                <InputAdornment position="start">
                                                    <IconButton size="small">
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    <AttachMoneyIcon className='input_icon' />
                                                </InputAdornment>,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton size="small">
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_icon_divider has_input_icon mb-3" 
                                            defaultValue="23"
                                            required
                                            InputProps={{
                                                startAdornment: 
                                                <InputAdornment position="start">
                                                    <IconButton size="small">
                                                        <RemoveIcon />
                                                    </IconButton>
                                                    <AttachMoneyIcon className='input_icon' />
                                                </InputAdornment>,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <IconButton size="small">
                                                        <AddIcon />
                                                    </IconButton>
                                                </InputAdornment>,
                                            }}
                                        />
                                        
                                        <Button 
                                            variant='text'
                                            startIcon={<AddIcon style={{ fontSize: 20 }} />}
                                            className="add_tax_btn"
                                        >Add Tax</Button>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="input_box col-md-6">
                                <label>
                                    Rate Breakdown
                                    <Tooltip title="Flag information goes here." placement="right" arrow>
                                        <HelpOutlineIcon className="info_icon" />
                                    </Tooltip>
                                </label>
                                <CloseIcon className="multiply_icon" />
                                <div className="text_box">
                                    21 - Sep - 2020 • 75 • standard<br />
                                    22 - Sep - 2020 • 75 • standard<br />
                                    23 - Sep - 2020 • 75 • standard<br />
                                    24 - Sep - 2020 • 75 • standard<br />
                                </div>  
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    </Drawer>
    );
}
