import * as React from 'react';
import { Box, Button, CircularProgress, CircularProgressProps, Drawer, TextField} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SyncIcon from '@mui/icons-material/Sync';

import './ImportRentalOverlay.scss';
import imgAirbnbLogo from '../../../../assets/images/airbnb_logo.png';

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} className="progressbar" />
      <div className="progress_text">
        <div>
          <img src={imgAirbnbLogo} width="60" alt="" />
          <br />
          <h4>{`${Math.round(props.value)}`}<span>%</span></h4>
        </div>
      </div>
    </Box>
  );
}

export default function ImportRentalOverlay(props: any) {
    const [RentalAdded,setRentalAdded] = React.useState(true);
    const [RentalUploading,setRentalUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
      return () => {
        clearInterval(timer);
      };
    }, []);
    const StartUploadingRental = () => {
      setRentalAdded(false);
      setRentalUploading(true);
    };
    const CancelUploadingRental = () => {
      setRentalAdded(true);
      setRentalUploading(false);
    };
    return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
        className="import_rental_overlay_holder"
    >
        <Button 
            className="btn_circle_close" 
            onClick={props.onClose}
        >
            <CloseIcon />
        </Button>
        <div className="d_header">
           <div className="title">Import rentals (Airbnb URL)</div> 
           <div className="btn_holder">
                <Button variant='outlined' className="btn_mui_dflt_outline no_shadow clr_primary text-uppercase" onClick={props.onClose}>cancel</Button>
                <Button
                    className="btn_mui_primary text-uppercase"
                    variant="contained"
                    startIcon={<SaveOutlinedIcon style={{ fontSize: 20 }} />}
                >
                    Save
                </Button>
           </div>
        </div>
        <div className="d_content">
          <div className="url_input_holder">
            <label>Enter Airbnb URL <span className="text-danger">*</span></label>
            <div className="input_flx_box">
              <div className="input_box">
                <TextField 
                    variant="outlined"
                    fullWidth
                    type="text" 
                    size="small"
                    className="mui_dflt_outlined_input" 
                    defaultValue="1ivcip7b.dev.tokeetmail.com"
                />
              </div>
              <div className="btn_holder">
                <Button
                    className="btn_mui_dflt_outline text-uppercase clr_primary"
                    variant="outlined"
                    startIcon={<SyncIcon style={{ fontSize: 20 }} />}
                    onClick={()=>StartUploadingRental()}
                >
                    import rentals
                </Button>
              </div>
            </div>
          </div>
          <div className="container-box">
            {RentalUploading && (
              <div className="importing_rental">
                <h4>Importing rentals from Airbnb</h4>
                <p>Please wait for few minutes to complete the process</p>
                <div className="progressbar_holder">
                  <CircularProgressWithLabel value={progress} />
                </div>
                <Button
                    className="btn_mui_dflt_outline clr_primary text-uppercase"
                    variant="outlined"
                    onClick={()=>CancelUploadingRental()}
                >
                    Cancel
                </Button>
              </div>
            )}
            
            {RentalAdded && (
              <div className="rental_successfully_added">
                <div className="success_icon_holder">
                  <i className="icon icon-rental-added"></i>
                </div>
                <h4>Rental Successfully Added in the List</h4>
                <p>
                  All the rentals have been successfully added to the list from Airbnb. <br className="d-none d-lg-block" />
                  Please click on the below ‘close’ button to view it in the list.
                </p>
                <Button
                    className="btn_mui_primary text-uppercase"
                    variant="contained"
                >
                    Close
                </Button>
              </div>  
            )}
          </div>
        </div>
    </Drawer>
    );
}