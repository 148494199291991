import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";

import SideBar from './app/components/sidebar/SideBar';
import SiteMap from './app/sitemap/SitemapPage';
import MultiCalendarPage from "./app/pages/calendar/MultiCalendar/MultiCalendarPage";
import StandardCalendarPage from "./app/pages/calendar/StandardCalendar/StandardCalendarPage";
import PlanningCalendarPage from "./app/pages/calendar/PlanningCalendar/PlanningCalendarPage";
import YearlyCalendarPage from "./app/pages/calendar/YearlyCalendar/YearlyCalendarPage";
import RatesCalendarPage from "./app/pages/calendar/RatesCalendar/RatesCalendarPage";
import RentalsPage from "./app/pages/rentals/RentalsPage";
import PaymentsPage from "./app/pages/payments/PaymentsPage";
import AddPaymentsPage from "./app/pages/payments/AddPayments/AddPaymentsPage";
import InvoicesPage from "./app/pages/invoices/InvoicesPage";
import StatementsPage from "./app/pages/statements/StatementsPage";
import BookingsPage from './app/pages/bookings/BookingsPage';
import LoginPage from './app/pages/auth/LoginPage/LoginPage';
import SignupPage from './app/pages/auth/SignupPage/SignupPage';

import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#02AB9D',
    },
  },
  typography: {
    "fontFamily": `'Nunito Sans', sans-serif`,
   },
});

function App() {
  const [isFetching, setIsFetching] = useState(true); 
  useEffect(() => {
      setTimeout(function () {
      setIsFetching(false); 
      }, 2500);
  }, []);

  if (isFetching) {
    return (        
      <div className="loading-screen">
        <GridLoader color="#02ab9d" size={20} />
      </div>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>   
        <SideBar />
        <Routes>
          <Route path="/" element={<SiteMap />} />
          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/Calendar" element={<MultiCalendarPage />} />
          <Route path="/Calendar/multi" element={<MultiCalendarPage />} />
          <Route path="/Calendar/standard" element={<StandardCalendarPage />} />
          <Route path="/Calendar/planning" element={<PlanningCalendarPage />} />
          <Route path="/Calendar/yearly" element={<YearlyCalendarPage />} />
          <Route path="/Calendar/rates" element={<RatesCalendarPage />} />
          <Route path="/rentals" element={<RentalsPage />} />
          <Route path="/payments" element={<PaymentsPage />} />
          <Route path="/payments/add-payments" element={<AddPaymentsPage />} />
          <Route path="/invoices" element={<InvoicesPage />} />
          <Route path="/statements" element={<StatementsPage />} />
          <Route path="/bookings" element={<BookingsPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
