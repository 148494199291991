import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, ButtonGroup, Checkbox, Drawer, FormControl, IconButton, InputAdornment, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';


import './StatementOverlay.scss';
import imgMercure from '../../../../assets/images/placeholder/mercure-hotels-logo.png';
import imgGuests from '../../../../assets/images/icons/guests.png';
import imgNight from '../../../../assets/images/icons/night.png';
import imgPayouts from '../../../../assets/images/icons/payouts.png';
import imgWebready from '../../../../assets/images/placeholder/webready_icon.png';
import imgAirbnb from '../../../../assets/images/placeholder/airbnb_icon.png';
import imgBookingCom from '../../../../assets/images/placeholder/booking_com_icon.png';
import imageRental_1 from '../../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../../assets/images/placeholder/thumb-5.jpg';

const ITEM_HEIGHT = 34;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const rentals = [
  'The Like View Guesthouse',
  'Koon Cha Learn',
  'Bunk Boutique Hostel ',
  'Pong Phen Guesthouse',
  'ETZzz Hostel Bangkok',
  'Camden Hostel',
  'Fayetteville Hostel',
  'Magnolia Guesthouse',
  'Newport Hostel',
  'Calistoga Guesthouse',
];

const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

export default function StatementOverlay(props: any) {
    const [rentalName, setRentalName] = React.useState<string[]>([]);
    const [monthName, setMonthName] = React.useState<string[]>([]);

    const handleChangeRentals = (event: SelectChangeEvent<typeof rentalName>) => {
        const {
        target: { value },
        } = event;
        setRentalName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeMonths = (event: SelectChangeEvent<typeof monthName>) => {
        const {
        target: { value },
        } = event;
        setMonthName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    
    const [checked, setChecked] = useState([false, false, false, false, false, false, false, false, false, false]);
    const CheckedAllTableRow = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([
            event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked,
            event.target.checked, event.target.checked, event.target.checked, event.target.checked, event.target.checked
        ]);
    };

    const handleCheckbox0 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
    };
    const handleCheckbox1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], event.target.checked, checked[2], checked[3] ]);
    };
    const handleCheckbox2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
    };
    const handleCheckbox3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], event.target.checked]);
    };
    const handleCheckbox4 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], event.target.checked]);
    };
    const handleCheckbox5 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], event.target.checked]);
    };
    const handleCheckbox6 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], event.target.checked]);
    };
    const handleCheckbox7 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], event.target.checked]);
    };
    const handleCheckbox8 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7], event.target.checked]);
    };
    const handleCheckbox9 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked([checked[0], checked[1], checked[2], checked[3], checked[4], checked[5], checked[6], checked[7], checked[8], event.target.checked]);
    };
    return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
        className="statement_overlay_holder"
    >
        <Button 
            className="btn_circle_close" 
            onClick={props.onClose}
        >
            <CloseIcon />
        </Button>
        <div className="d_header">
           <div className="title">Ruben Clark</div> 
           <div className="btn_holder">
                <Tooltip title="Print" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <LocalPrintshopOutlinedIcon />
                    </Button>
                </Tooltip>
           </div>
        </div>
        <div className="d_content">
            <div className="back_to_pev_page">
                <Link to="#">
                    <KeyboardBackspaceIcon />
                    back to owner statements
                </Link>
            </div>
            <div className="row flx_box_holder">
                <div className="col-12 col-sm-6 col-xl-3 box_holder">
                    <div className="box company_logo">
                        <img src={imgMercure} alt="" />
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3 box_holder">
                    <div className="box">
                        <Avatar sx={{ bgcolor: '#02AB9D' }}>
                            <img src={imgGuests} height="20" alt="" />
                        </Avatar>
                        <div className="">
                            <h4>Total Guests</h4>
                            <h2>89</h2>
                            <p>in last 30 days</p>
                        </div>  
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3 box_holder">
                    <div className="box">
                        <Avatar sx={{ bgcolor: '#35495C' }}>
                            <img src={imgNight} height="20" alt="" />
                        </Avatar>
                        <div className="">
                            <h4>Total Nights</h4>
                            <h2>74</h2>
                            <p>in last 30 days</p>
                        </div>  
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-xl-3 box_holder">
                    <div className="box">
                        <Avatar sx={{ bgcolor: '#EA9304' }}>
                            <img src={imgPayouts} height="20" alt="" />
                        </Avatar>
                        <div className="">
                            <h4>Total Payouts</h4>
                            <h2>$ 1,380</h2>
                            <p>in last 30 days</p>
                        </div>  
                    </div>
                </div>
            </div>
            <div className="row dsc_flx_box">
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                    <h6>Bill to</h6>
                    <p className="dsc_p">
                        <strong>Cedarwood resort</strong> <br />
                        Address 123 666 2988 <br />
                        City City, State / Province / Region 54000 <br />
                        Afghanistan
                        <br /><br />
                        +81-978789798709 <br />
                        emptykanza2@test.com
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                    <h6>Bill from</h6>
                    <p className="dsc_p">
                        <strong>Anne Kocher</strong> <br />
                        Address 123 666 2988 <br />
                        City City, State / Province / Region 54000 <br />
                        Afghanistan
                        <br /><br />
                        +81-978789798709 <br />
                        emptykanza2@test.com
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                    <h6>message</h6>
                    <p className="msg_p">
                        Duis aute irure dolor in reprehenderit
                        in voluptate velit esse cillum dolore eu 
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
                    </p>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                    <p>
                        <strong>Statement Date</strong><br />
                        Wed Feb 5, 2020
                    </p>
                    <p>
                        <strong>Statement Period</strong><br />
                        Feb 2020
                    </p>
                    <p>
                        <strong>Statement No.</strong><br />
                        1580911171
                    </p>
                    <p>
                        <strong>Account No.</strong><br />
                        1565919402
                    </p>
                </div>
            </div>

            <div className="container-box">
                <div className="box_header">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto flx-box">
                            <h4>Statements <span>- 25</span></h4>
                        </div>
                        <div className="col-auto flx-box">
                            <FormControl sx={{ width: 200 }} className="mui-dflt-select-box">
                                <Select
                                displayEmpty
                                multiple
                                value={rentalName}
                                onChange={handleChangeRentals}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                    return <>Select Rentals</>;
                                    }
                        
                                    return selected.join(', ');
                                }}
                                MenuProps={MenuProps}
                                >
                                    {rentals.map((rental) => (
                                        <MenuItem key={rental} value={rental} className="mui-dflt-select-menu-box">
                                            <Checkbox checked={rentalName.indexOf(rental) > -1} />
                                            <ListItemText primary={rental} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ width: 200 }} className="mui-dflt-select-box">
                                <Select
                                    displayEmpty
                                    value={monthName}
                                    onChange={handleChangeMonths}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                        return <>Select Month</>;
                                        }
                            
                                        return selected.join(', ');
                                    }}
                                    MenuProps={MenuProps}
                                >
                                {months.map((month) => (
                                    <MenuItem key={month} value={month} className="mui-dflt-select-menu-box">
                                        <ListItemText primary={month} />
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>

                            <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                                <FilterAltOffIcon color="error" />
                            </Button>
                            <TextField 
                                variant="outlined"
                                placeholder="Search Statements" 
                                type="search" 
                                size="small"
                                className="mui_dflt_outlined_input has_l_icon search-holder" 
                                InputProps={{
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <SearchOutlinedIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                            <Tooltip title="Download" placement="bottom" arrow>
                                <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                                    <FileDownloadOutlinedIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                
                <div className="table-responsive table-holder">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    <Checkbox 
                                        checked={
                                            checked[0] && checked[1] && checked[2] && checked[3] && checked[4]
                                            && checked[5] && checked[6] && checked[7] && checked[8] && checked[9]
                                        }
                                        indeterminate={
                                            checked[0] !== checked[1] !== checked[2] !== checked[3] !== checked[4]
                                            !== checked[5] !== checked[6] !== checked[7] !== checked[8] !== checked[9]
                                        }
                                        onChange={CheckedAllTableRow}
                                    />
                                    Created
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Rental 
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Details
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Start date
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Payout
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={"" + (checked[0] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[0]} onChange={handleCheckbox0} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_1} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>The Like View Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Colin Ochel</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    $456
                                </td>
                            </tr>
                            <tr className={"" + (checked[1] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[1]} onChange={handleCheckbox1} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Koon Cha Learn</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Anne Kocher</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    $567
                                </td>
                            </tr>
                            <tr className={"" + (checked[2] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[2]} onChange={handleCheckbox2} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgAirbnb} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_3} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Bunk Boutique Hostel Chiangmai</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Colin Ochel</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    $457
                                </td>
                            </tr>
                            <tr className={"" + (checked[3] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[3]} onChange={handleCheckbox3} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgBookingCom} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_4} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Anne Kocher</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    $567
                                </td>
                            </tr>
                            <tr className={"" + (checked[4] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[4]} onChange={handleCheckbox4} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgAirbnb} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_5} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>ETZzz Hostel Bangkok</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Colin Ochel</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    $434
                                </td>
                            </tr>
                            <tr className={"" + (checked[5] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[5]} onChange={handleCheckbox5} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Anne Kocher</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    $567
                                </td>
                            </tr>
                            <tr className={"" + (checked[6] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[6]} onChange={handleCheckbox6} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_3} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Bunk Boutique Hostel Chiangmai</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Colin Ochel</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    $457
                                </td>
                            </tr>
                            <tr className={"" + (checked[7] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[7]} onChange={handleCheckbox7} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_4} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Anne Kocher</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    $567
                                </td>
                            </tr>
                            <tr className={"" + (checked[8] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[8]} onChange={handleCheckbox8} />
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_5} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>ETZzz Hostel Bangkok</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Colin Ochel</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    $457
                                </td>
                            </tr>
                            <tr className={"" + (checked[9] ? "selected_row" : "") }>
                                <td>
                                    <Checkbox checked={checked[9]} onChange={handleCheckbox9} />
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    <div className="d_flx_rental_box">
                                        <div className="rental_box">
                                            <div className="icon_holder">
                                                <img src={imgWebready} alt="" />
                                            </div>
                                            <div className="img_holder">
                                                <img src={imageRental_2} alt="" />
                                            </div>
                                            <div className="rental_dsc">
                                                <h4>Pong Phen Guesthouse</h4>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span>Anne Kocher</span><br />
                                    <div className="flx_icon_box">
                                        <div>
                                            <span>3</span>
                                            <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                        <div>
                                            <span>2</span>
                                            <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    $567
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                    <div className="col-12 col-md-auto displaying_info">
                        Displaying <span>10</span> out of <span>25</span>
                    </div>
                    <div className="col-12 col-md-auto order-first order-md-last">
                        <ButtonGroup variant="outlined" className="cst_paginations">
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <FirstPageIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Button className="btn_mui_dflt_outline">1 -10</Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <KeyboardArrowRightIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <LastPageIcon />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

            </div>
        </div>
    </Drawer>
    );
}
