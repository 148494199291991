import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Drawer, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TodayIcon from '@mui/icons-material/Today';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import './InvoicesOverlay.scss';
import imgMercure from '../../../../assets/images/placeholder/mercure-hotels-logo.png';

export default function InvoicesOverlay(props: any) {
    return (
    <Drawer
        anchor="right"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
        className="invoices_overlay_holder"
    >
        <Button 
            className="btn_circle_close" 
            onClick={props.onClose}
        >
            <CloseIcon />
        </Button>
        <div className="d_header">
           <div className="title">Ruben Clark</div> 
           <div className="btn_holder">
                <Tooltip title="Print" placement="bottom" arrow>
                    <Button variant="outlined" className="btn_mui_icon_outline">
                        <LocalPrintshopOutlinedIcon />
                    </Button>
                </Tooltip>
           </div>
        </div>
        <div className="d_content">
            <div className="back_to_pev_page">
                <Link to="#">
                    <KeyboardBackspaceIcon />
                    back to owner invoices
                </Link>
            </div>
            <div className="row payment_status_box align-items-center justify-content-between">
                <div className="col-12 col-sm-auto company_logo">
                    <img src={imgMercure} alt="" />
                </div>
                <div className="col-12 col-sm-auto">
                    {/* <label className="paid">Paid</label> */}
                    <label className="unpaid">Unpaid</label>
                </div>
            </div>
            <div className="row invoice_info_flx_box">
                <div className="col-md-4 box">
                    <h6>invoice #</h6>
                    <h1>#1604583060172</h1>
                    <div className="input_box">
                        <label>Invoice Date</label>
                        <TextField 
                            variant="outlined"
                            defaultValue="05 - Nov - 2020" 
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon read-only"
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton size="small">
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="input_box">
                        <label>Due Date</label>
                        <TextField 
                            variant="outlined"
                            defaultValue="24 - Dec - 2020" 
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon read-only"
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <IconButton size="small">
                                        <TodayIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-4 box">
                    <h6>Bill to</h6>
                    <p>
                        <strong>Cedarwood resort</strong> <br />
                        Address 123 666 2988 <br />
                        City City, State / Province / Region 54000 <br />
                        Afghanistan
                        <br /><br />
                        +81-978789798709 <br />
                        emptykanza2@test.com
                    </p>
                </div>
                <div className="col-md-4 box">
                    <h6>Bill from</h6>
                    <p>
                        <strong>Anne Kocher</strong> <br />
                        Address 123 666 2988 <br />
                        City City, State / Province / Region 54000 <br />
                        Afghanistan
                        <br /><br />
                        +81-978789798709 <br />
                        emptykanza2@test.com
                    </p>
                </div>
            </div>
            <div className="messages">
                <h6>Message</h6>
                <p>
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                    Excepteur sint occaecat cupidatat non proident
                </p>
            </div>

            <div className="row item_tbl_title_box">
                <div className="col-md-8 item_left">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">Item Description</div>
                                <div className="col-md-6">Rental</div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-6 text-end">Nights</div>
                                <div className="col-md-6 text-end">Guests</div>
                            </div>
                        </div>
                        <div className="col-md-2 text-end">Payable</div>
                    </div>
                </div>
                <div className="col-md-4 item_right">taxes</div>
            </div>

            <div className="row item_tbl_row">
                <div className="col-xl-8 item_left">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input_box">
                                        <label>Item Description</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            defaultValue="Food Items" 
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box">
                                        <label>Rental</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            defaultValue="1021 SW Klickitat Way, #102" 
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_box">
                                        <label>Nights</label>
                                        <TextField 
                                            variant="outlined"
                                            defaultValue="" 
                                            fullWidth
                                            type="number" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <UnfoldMoreIcon />
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input_box">
                                        <label>Guests</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            defaultValue="" 
                                            type="number" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <UnfoldMoreIcon />
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="input_box">
                                <label>Payable</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="$ 345" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 item_right">
                    <label>Taxes</label>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="input_box">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="25% Patial Payment" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="input_box">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="Subtract" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input_box">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="Yearly $150 (PS)  " 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row item_tbl_row">
                <div className="col-xl-8 item_left">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input_box">
                                        <label>Item Description</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            defaultValue="Food Items" 
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_box">
                                        <label>Rental</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            defaultValue="1021 SW Klickitat Way, #102" 
                                            type="text" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-6">
                                    <div className="input_box">
                                        <label>Nights</label>
                                        <TextField 
                                            variant="outlined"
                                            defaultValue="" 
                                            fullWidth
                                            type="number" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <UnfoldMoreIcon />
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="input_box">
                                        <label>Guests</label>
                                        <TextField 
                                            variant="outlined"
                                            fullWidth
                                            defaultValue="" 
                                            type="number" 
                                            size="small"
                                            className="mui_dflt_outlined_input has_r_icon read-only"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: 
                                                <InputAdornment position="end">
                                                    <UnfoldMoreIcon />
                                                </InputAdornment>,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="input_box">
                                <label>Payable</label>
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="$ 345" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 item_right">
                    <label>Taxes</label>
                    <div className="row">
                        <div className="col-md-8">
                            <div className="input_box">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="25% Patial Payment" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="input_box">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="Subtract" 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input_box">
                                <TextField 
                                    variant="outlined"
                                    fullWidth
                                    defaultValue="Yearly $150 (PS)  " 
                                    type="text" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_r_icon read-only"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: 
                                        <InputAdornment position="end">
                                            <ExpandMoreIcon />
                                        </InputAdornment>,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Drawer>
    );
}
