import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InfoIcon from '@mui/icons-material/Info';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import './PaymentsPage.scss';

function PaymentsPage(){
    const [EditMenuEl, setEditMenuEl] = React.useState<null | HTMLElement>(null);
    const openEditMenu = Boolean(EditMenuEl);
    const handleClickEditMenu = (event: React.MouseEvent<HTMLElement>) => {
        setEditMenuEl(event.currentTarget);
    };
    const handleCloseEditMenu = () => {
        setEditMenuEl(null);
    };

  return (
    <div className="payments-page page_holder">
        <div className="header sticky-top">
            <h4>Payments</h4>
            <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                <Link to="#">
                    <i className="icon icon-info"></i>
                </Link>
            </Tooltip>
            <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                <Link to="#">
                    <i className="icon icon-video" style={{fontSize: 16}}></i>
                </Link>
            </Tooltip>
        </div>
        
        <div className="content_holder">
            <div className="info_box">
                <div>
                    <InfoIcon color="primary" fontSize="large" />
                </div>
                <div>
                    <p>
                        As an owner you can connect your payment gateways directly to Tokeet. 
                        Your property manager can choose to either have guests deposit payments directly into 
                        your connected gateway or into theirs. Your property manager will never be able to log 
                        into your payment gateway nor can they see your gateway credentials. Tokeet keeps this information 
                        encrypted and confidential within our databases. Your property manager will be able to see your 
                        gateway connection and use that gateway for payments related to your rentals.
                        <br /><br />
                        We recommend that you name your payment gateways so it's easy for your property manager
                        to identify them. Also, please disconnect any gateways which you no longer plan to
                        use with Tokeet. Tokeet does provide some activity log for each connected gateway, 
                        however we recommend that you refer to your payment gateway logs for the most complete and detailed statistics.
                    </p>
                </div>
            </div>

            <div className="container-box">
                <div className="box_header">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto title_box">
                            <h4>Payment Gateways</h4>
                            <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                                <Link to="#">
                                    <i className="icon icon-info"></i>
                                </Link>
                            </Tooltip>
                        </div>
                        <div className="col-auto flx-box">
                            <TextField 
                                variant="outlined"
                                placeholder="Search" 
                                type="search" 
                                size="small"
                                className="mui_dflt_outlined_input has_l_icon search-holder" 
                                InputProps={{
                                    startAdornment: 
                                    <InputAdornment position="start">
                                        <IconButton size="small">
                                            <SearchOutlinedIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="table-responsive table-holder">
                    <Menu
                        className="dflt_dropdown_mui"
                        anchorEl={EditMenuEl}
                        open={openEditMenu}
                        onClose={handleCloseEditMenu}
                        onClick={handleCloseEditMenu}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 0,
                                minWidth: 95,
                                '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 48,
                                width: 15,
                                height: 15,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                    >
                        <MenuItem>
                            <EditOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Edit
                        </MenuItem>
                        <MenuItem>
                            <FileDownloadOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Download
                        </MenuItem>
                        <MenuItem>
                            <DeleteOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                            Delete
                        </MenuItem>
                    </Menu>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>
                                    Connected
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Name
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    Gateway
                                    <UnfoldMoreIcon className="sort_icon" />
                                </th>
                                <th>
                                    ID
                                </th>
                                <th>
                                    Status
                                    <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                                        <Link to="#" className="info_icon">
                                            <i className="icon icon-info"></i>
                                        </Link>
                                    </Tooltip>
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    Default Booking Gateway
                                </td>
                                <td>
                                    PayPal
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch defaultChecked className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    New Payment Gateway
                                </td>
                                <td>
                                    Stripe
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    Default Booking Gateway
                                </td>
                                <td>
                                    PayPal
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch defaultChecked className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    New Payment Gateway
                                </td>
                                <td>
                                    Stripe
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    Default Booking Gateway
                                </td>
                                <td>
                                    PayPal
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch defaultChecked className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    New Payment Gateway
                                </td>
                                <td>
                                    Stripe
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    Default Booking Gateway
                                </td>
                                <td>
                                    PayPal
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch defaultChecked className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    New Payment Gateway
                                </td>
                                <td>
                                    Stripe
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    29 - Aug - 2020
                                </td>
                                <td>
                                    Default Booking Gateway
                                </td>
                                <td>
                                    PayPal
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch defaultChecked className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    30 - Aug - 2020
                                </td>
                                <td>
                                    New Payment Gateway
                                </td>
                                <td>
                                    Stripe
                                </td>
                                <td>
                                    44fcfe195516 
                                </td>
                                <td>
                                    <Switch className="mui_dflt_switch" />
                                </td>
                                <td>
                                    <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                        <MoreHorizIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                    <div className="col-12 col-md-auto displaying_info">
                        Displaying <span>10</span> out of <span>25</span>
                    </div>
                    <div className="col-12 col-md-auto order-first order-md-last">
                        <ButtonGroup variant="outlined" className="cst_paginations">
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <FirstPageIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline" disabled>
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Button className="btn_mui_dflt_outline">1 -10</Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <KeyboardArrowRightIcon />
                            </Button>
                            <Button className="icon_btn_mui_dflt_outline">
                                <LastPageIcon />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

            </div>

        </div>

    </div>
  );
}

export default PaymentsPage;