import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip, ButtonGroup, Menu, MenuItem, Divider, TextField, Autocomplete } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

import './PlanningCalendarPage.scss';
import imageRental_1 from '../../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../../assets/images/placeholder/thumb-5.jpg';
import imageWebready from '../../../../assets/images/placeholder/webready_icon.png';


function PlanningCalendarPage(){
  const months = [
    {title: 'January', id: 1},
    {title: 'February', id: 2},
    {title: 'March', id: 3},
    {title: 'April', id: 4},
    {title: 'May', id: 5},
    {title: 'June', id: 6},
    {title: 'July', id: 7},
    {title: 'August', id: 8},
    {title: 'September', id: 9},
    {title: 'October', id: 10},
    {title: 'November', id: 11},
    {title: 'December', id: 12},
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const scrollRef = useRef<HTMLDivElement | null>(null);
    const onClickScroll = (scrollOffset: number) => {
    scrollRef!.current!.scrollLeft += scrollOffset;
    };

  return (
    <div className="planning-calendar-page page_holder">
      <div className="header sticky-top">
        <h4>Calendar</h4>
        <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-info"></i>
            </Link>
        </Tooltip>
        <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-video" style={{fontSize: 16}}></i>
            </Link>
        </Tooltip>
      </div>
      <div className="content_holder">
        <div className="row justify-content-between align-items-center title-holder">
            <div className="col-auto title">
                <h4>Planning Calendar</h4>
                <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-info"></i>
                    </Link>
                </Tooltip>
                <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-video" style={{fontSize: 11}}></i>
                    </Link>
                </Tooltip>
            </div>
            <div className="col-auto">
                <div className="btn-group-box">
                    <Link to="/calendar/multi">
                        <Tooltip title="Multi Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-multi-calendar-month"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/standard">
                        <Tooltip title="Standard Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-standard-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/planning">
                        <Tooltip title="Planning Calendar" placement="bottom" arrow>
                            <Button variant="text" className="active">
                                <i className="icon icon-planning-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/yearly">
                        <Tooltip title="Yearly Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-multi-calendar-year"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/rates">
                        <Tooltip title="Rates Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-rates-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                </div>
            </div>
        </div>
        <div className="row justify-content-between align-items-center header-input-box">
            <div className="col-12 col-md-5 col-lg-6">
                <div className="select_month_box_holder">
                    <Autocomplete
                        className="select_months"
                        multiple
                        options={months.map((option) => option.title)}
                        defaultValue={[months[7].title, months[8].title, months[9].title, months[10].title]}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder="Add Month"
                        />
                        )}
                    />
                </div>
            </div>
            <div className="col-auto flx-box">
                <Tooltip title="Refresh" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                        <RefreshIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Button variant="outlined" className="btn_mui_dflt_outline">
                    Today
                </Button>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">2020</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
            <div className="col-auto flx-box">
                <Button
                    className="btn_mui_primary has_split_icon"
                    variant="contained"
                    onClick={handleClick}
                    aria-controls={open ? 'add-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    startIcon={<AddIcon style={{ fontSize: 20 }} />}
                    endIcon={<KeyboardArrowDownIcon className="split_icon" />}
                >
                    ADD
                </Button>
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={anchorEl}
                    id="add-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: 180,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuItem>
                        Add Booking
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        Add Hold Event
                    </MenuItem>
                </Menu>
                
                <Tooltip title="Share" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <ShareOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Upload" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <CloudUploadOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Print" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <LocalPrintshopOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
            </div>
        </div>

        <div className="container-box">
            <Button className="scroll_btn_left" variant="text" onClick={()=> onClickScroll(-70)}>
                <ArrowBackIosIcon fontSize="small" />
            </Button>
            <Button className="scroll_btn_right" variant="text" onClick={()=> onClickScroll(70)}>
                <ArrowForwardIosIcon fontSize="small" />
            </Button>
            <div className="table-responsive table-holder" ref={scrollRef}>
                <table className="table">
                    <tbody>
                        <tr className="thead">
                            <th>
                                <h4 className="month_name">August</h4>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">1</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">2</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">3</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">4</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">5</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">6</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">7</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">8</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">9</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">10</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">11</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">12</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">13</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">14</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">15</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">16</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">17</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">18</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">19</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">20</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">21</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">22</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">23</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">24</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">25</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">26</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">27</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">28</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">29</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">30</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">31</span>
                            </th>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_1} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Pong Phen Guesthouse</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_confirmed">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Confirmed 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_2} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_pending">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Pending 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_3} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Bunk Boutique Hostel Bangkok</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_cancelled">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            Martin Sheen
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Pending 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_4} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>ETZzz Hostel Bangkok</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr className="thead">
                            <th>
                                <h4 className="month_name">September</h4>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">1</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">2</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">3</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">4</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">5</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">6</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">7</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">8</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">9</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">10</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">11</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">12</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">13</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">14</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">15</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">16</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">17</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">18</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">19</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">20</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">21</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">22</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">23</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">24</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">25</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">26</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">27</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">28</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">29</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">30</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">31</span>
                            </th>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_5} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>The Like View Guesthouse</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_pending">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            Martin Sheen
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Pending 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr className="thead">
                            <th>
                                <h4 className="month_name">October</h4>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">1</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">2</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">3</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">4</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">5</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">6</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">7</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">8</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">9</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">10</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">11</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">12</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">13</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">14</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">15</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">16</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">17</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">18</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">19</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">20</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">21</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">22</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">23</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">24</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">25</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">26</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">27</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">28</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">29</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">30</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">31</span>
                            </th>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_1} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Pong Phen Guesthouse</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td>
                            <td></td>
                            <td></td><td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_confirmed">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Confirmed 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr className="thead">
                            <th>
                                <h4 className="month_name">November</h4>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">1</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">2</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">3</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">4</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">5</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">6</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">7</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">8</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">9</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">10</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">11</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">12</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">13</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">14</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">15</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">16</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">17</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">18</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">19</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">20</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">21</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">22</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">23</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">24</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">25</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">26</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">27</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">28</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">29</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">30</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">31</span>
                            </th>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_2} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_pending">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Pending 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>

                        <tr className="blank_ftr">
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </div>

      </div>
    </div>
  );
}

export default PlanningCalendarPage;