import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormControlLabel, Switch, Tooltip, InputBase, IconButton, ToggleButtonGroup, 
ToggleButton, Rating, ButtonGroup, Menu, MenuItem, Divider, TextField, InputAdornment } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HotTubOutlinedIcon from '@mui/icons-material/HotTubOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';

import './MultiCalendarPage.scss';
import imageRental_1 from '../../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../../assets/images/placeholder/thumb-5.jpg';
import imageWebready from '../../../../assets/images/placeholder/webready_icon.png';


function MultiCalendarPage(){
  const [BookingStatus, setBookingStatus] = React.useState('Booked');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newBookingStatus: string | null,
  ) => {
    if (newBookingStatus !== null) {
      setBookingStatus(newBookingStatus);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const scrollRef = useRef<HTMLDivElement | null>(null);
    const onClickScroll = (scrollOffset: number) => {
    scrollRef!.current!.scrollLeft += scrollOffset;
    };

  return (
    <div className="multi-calendar-page page_holder">
      <div className="header sticky-top">
        <h4>Calendar</h4>
        <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-info"></i>
            </Link>
        </Tooltip>
        <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-video" style={{fontSize: 16}}></i>
            </Link>
        </Tooltip>
      </div>
      <div className="content_holder">
        <div className="row justify-content-between align-items-center title-holder">
            <div className="col-md-auto col-lg-7 col-xl-8 title">
                <h4>Multi Calendar</h4>
                <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-info"></i>
                    </Link>
                </Tooltip>
                <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-video" style={{fontSize: 11}}></i>
                    </Link>
                </Tooltip>
            </div>
            <div className="col-md-auto col-lg-5 col-xl-4">
                <div className="row justify-content-md-between align-items-center">
                    <div className="col-auto">
                        <FormControlLabel
                            className="yes_no_switch_holder"
                            control={<Switch defaultChecked className="yes_no_switch" />} 
                            label="Show Cancelled"
                        />
                    </div>
                    <div className="col-auto">
                        <div className="btn-group-box">
                            <Link to="/calendar/multi">
                                <Tooltip title="Multi Calendar" placement="bottom" arrow>
                                    <Button variant="text" className="active">
                                        <i className="icon icon-multi-calendar-month"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/standard">
                                <Tooltip title="Standard Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-standard-calendar"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/planning">
                                <Tooltip title="Planning Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-planning-calendar"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/yearly">
                                <Tooltip title="Yearly Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-multi-calendar-year"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                            <Link to="/calendar/rates">
                                <Tooltip title="Rates Calendar" placement="bottom" arrow>
                                    <Button variant="text">
                                        <i className="icon icon-rates-calendar"></i>
                                    </Button>
                                </Tooltip>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-between align-items-center header-input-box">
            <div className="col-auto flx-box">
                <div className="range_date_picker_holder">
                    <InputBase
                        className="input_date"
                        defaultValue="22 - Jan - 2020"
                    />
                    <span>to</span>
                    <InputBase
                        className="input_date"
                        defaultValue="12 - Jan - 2021"
                    />
                    <IconButton type="button" sx={{ p: '10px' }}>
                        <i className="icon icon-standard-calendar"></i>
                    </IconButton>
                </div>
                <ToggleButtonGroup
                    className="status_toggle_btn_grp"
                    value={BookingStatus}
                    exclusive
                    onChange={handleChange}
                    >
                    <ToggleButton value="Available">
                        <i className="icon icon-selected"></i>
                        Available
                    </ToggleButton>
                    <ToggleButton value="Booked">
                        <i className="icon icon-selected"></i>
                        Booked
                    </ToggleButton>
                </ToggleButtonGroup>
                <Rating name="bookmark" max={1} />
            </div>
            <div className="col-auto flx-box">
                <Tooltip title="Refresh" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                        <RefreshIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Button variant="outlined" className="btn_mui_dflt_outline">
                    Today
                </Button>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">2020</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none"> 
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">September</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
            <div className="col-auto flx-box">
                <Button
                    className="btn_mui_primary has_split_icon"
                    variant="contained"
                    onClick={handleClick}
                    aria-controls={open ? 'add-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    startIcon={<AddIcon style={{ fontSize: 20 }} />}
                    endIcon={<KeyboardArrowDownIcon className="split_icon" />}
                >
                    ADD
                </Button>
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={anchorEl}
                    id="add-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: 180,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                >
                    <MenuItem>
                        Add Booking
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        Add Hold Event
                    </MenuItem>
                </Menu>

                <Tooltip title="Share" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <ShareOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Upload" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <CloudUploadOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Tooltip title="Print" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                        <LocalPrintshopOutlinedIcon fontSize="small" />
                    </Button>
                </Tooltip>
            </div>
        </div>

        <div className="container-box">
            <Button className="scroll_btn_left" variant="text" onClick={()=> onClickScroll(-70)}>
                <ArrowBackIosIcon fontSize="small" />
            </Button>
            <Button className="scroll_btn_right" variant="text" onClick={()=> onClickScroll(70)}>
                <ArrowForwardIosIcon fontSize="small" />
            </Button>
            <div className="table-responsive table-holder" ref={scrollRef}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <TextField 
                                    variant="outlined"
                                    placeholder="Search Rentals" 
                                    type="search" 
                                    size="small"
                                    className="mui_dflt_outlined_input has_l_icon"
                                    InputProps={{
                                        startAdornment: 
                                        <InputAdornment position="start">
                                            <IconButton size="small">
                                                <SearchOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}
                                />
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">1</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">2</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">3</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">4</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">5</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">6</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">7</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">8</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">9</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">10</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">11</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">12</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">13</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">14</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">15</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">16</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">17</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">18</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">19</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">20</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">21</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">22</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">23</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">24</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">25</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">26</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">27</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">28</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">29</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">30</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">31</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_pending">
                                    <div className="img_holder">
                                        <img src={imageRental_1} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Pong Phen Guesthouse</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_confirmed">
                                    <div className="img_holder">
                                        <img src={imageRental_2} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_confirmed">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Confirmed 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_cancelled">
                                    <div className="img_holder">
                                        <img src={imageRental_3} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Bunk Boutique Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_cancelled">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            Martin Sheen
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Cancelled 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_confirmed">
                                    <div className="img_holder">
                                        <img src={imageRental_4} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>ETZzz Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_pending">
                                    <div className="img_holder">
                                        <img src={imageRental_5} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>The Like View Guesthouse</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_pending">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            Martin Sheen
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Pending 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_confirmed">
                                    <div className="img_holder">
                                        <img src={imageRental_1} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Pong Phen Guesthouse</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td>
                            <td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_pending">
                                    <div className="img_holder">
                                        <img src={imageRental_2} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_pending">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Pending 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_cancelled">
                                    <div className="img_holder">
                                        <img src={imageRental_3} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Bunk Boutique Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_cancelled">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Cancelled 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_confirmed">
                                    <div className="img_holder">
                                        <img src={imageRental_4} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>ETZzz Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td>
                            <td>
                                <div className="event">
                                    <div className="left_skew_box"></div>
                                    <div className="event_dsc_holder b_confirmed">
                                        <div className="event_dsc">
                                            <img src={imageWebready} alt="" />
                                            David Pollack 
                                            <span className="sep_dot">•</span> Webready 
                                            <span className="sep_dot">•</span> Confirmed 
                                            <span className="sep_dot">•</span> The
                                        </div>
                                    </div>
                                    <div className="right_skew_box"></div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box booking_pending">
                                    <div className="img_holder">
                                        <img src={imageRental_5} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>The Like View Guesthouse</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <HotTubOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td>
                                <div className="night_stay">
                                    <span>2</span>
                                    <NightlightOutlinedIcon sx={{ fontSize: 12 }} />
                                </div>
                            </td>
                            <td>
                                <div className="night_stay">
                                    <span>3</span>
                                    <NightlightOutlinedIcon sx={{ fontSize: 12 }} />
                                </div>
                            </td>
                            <td>
                                <div className="night_stay">
                                    <span>4</span>
                                    <NightlightOutlinedIcon sx={{ fontSize: 12 }} />
                                </div>
                            </td>
                            <td className="bg_line">
                                <div className="night_stay">
                                    <span>1</span>
                                    <NightlightOutlinedIcon sx={{ fontSize: 12 }} />
                                </div>
                            </td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                <div className="col-12 col-md-auto displaying_info">
                    Displaying <span>10</span> out of <span>25</span>
                </div>
                <div className="col-12 col-md-auto order-first order-md-last">
                    <ButtonGroup variant="outlined" className="cst_paginations">
                        <Button className="icon_btn_mui_dflt_outline" disabled>
                            <FirstPageIcon />
                        </Button>
                        <Button className="icon_btn_mui_dflt_outline" disabled>
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button className="btn_mui_dflt_outline">1 -10</Button>
                        <Button className="icon_btn_mui_dflt_outline">
                            <KeyboardArrowRightIcon />
                        </Button>
                        <Button className="icon_btn_mui_dflt_outline">
                            <LastPageIcon />
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
        </div>

      </div>
    </div>
  );
}

export default MultiCalendarPage;