import * as React from 'react';
import { Avatar, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, TextField } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import './RentalCleaning.scss';
import img1 from '../../../../../../assets/images/placeholder/user-3.png';

export default function RentalCleaning() {
    const handleDelete = () => {
      console.info('You clicked the delete icon.');
    };
    return (
      <div className="cleaning_tab_holder">
        <h4 className="title">Cleaning</h4>
        <div className="row">
          <div className="col-lg-6 inputbox_holder">
              <div className="container-box">
                <h4 className="box_title">Cleaning details</h4>
                <div className="row">
                  <div className="col-12 input_box">
                    <label>Auto Assign Cleaner</label>
                    <TextField 
                        variant="outlined"
                        defaultValue="" 
                        fullWidth
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input read-only"
                    />
                    <div className="chip_holder">
                      <Chip
                        avatar={<Avatar alt="" src={img1} />}
                        label="James Smith"
                        onDelete={handleDelete}
                      />
                    </div>
                  </div>
                  <div className="col-12 input_box">
                    <label>Regular Instructions</label>
                    <TextField 
                        variant="outlined"
                        multiline
                        defaultValue="" 
                        fullWidth
                        type="text" 
                        size="small"
                        rows={4}
                        className="mui_dflt_outlined_input read-only"
                    />
                  </div>
                  <div className="col-sm-6 input_box">
                      <label>Hours</label>
                      <TextField 
                          variant="outlined"
                          fullWidth
                          type="text" 
                          size="small"
                          className="mui_dflt_outlined_input has_icon_divider read-only" 
                          defaultValue="1.5"
                          InputProps={{
                              startAdornment: 
                              <InputAdornment position="start">
                                  <IconButton size="small">
                                      <RemoveIcon />
                                  </IconButton>
                              </InputAdornment>,
                              endAdornment: 
                              <InputAdornment position="end">
                                  <IconButton size="small">
                                      <AddIcon />
                                  </IconButton>
                              </InputAdornment>,
                          }}
                      />
                  </div>
                  <div className="col-sm-6 input_box">
                      <label>Beds</label>
                      <TextField 
                          variant="outlined"
                          fullWidth
                          type="text" 
                          size="small"
                          className="mui_dflt_outlined_input has_icon_divider read-only" 
                          defaultValue="1Q"
                          InputProps={{
                              className: 'clr_primary fw-bold',
                              startAdornment: 
                              <InputAdornment position="start">
                                  <IconButton size="small">
                                      <RemoveIcon />
                                  </IconButton>
                              </InputAdornment>,
                              endAdornment: 
                              <InputAdornment position="end">
                                  <IconButton size="small">
                                      <AddIcon />
                                  </IconButton>
                              </InputAdornment>,
                          }}
                      />
                  </div>
                </div>
              </div>
              <div className="container-box">
                <h4 className="box_title">Check-in / Check-out Times</h4>
                <div className="row">
                  <div className="col-xl-6 input_box">
                    <label>Check-in Time <span className="text-danger">*</span></label>
                    <TextField 
                      variant="outlined"
                      fullWidth
                      type="text" 
                      size="small"
                      className="mui_dflt_outlined_input has_r_icon read-only" 
                      defaultValue="12:00 PM"
                      required
                      InputProps={{
                        endAdornment: 
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <AccessTimeIcon />
                          </IconButton>
                        </InputAdornment>,
                      }}
                    />
                  </div>
                  <div className="col-xl-6 input_box">
                    <label>Check-out Time <span className="text-danger">*</span></label>
                    <TextField 
                      variant="outlined"
                      fullWidth
                      type="text" 
                      size="small"
                      className="mui_dflt_outlined_input has_r_icon read-only" 
                      defaultValue="11:00 AM"
                      required
                      InputProps={{
                        endAdornment: 
                        <InputAdornment position="end">
                          <IconButton size="small">
                            <AccessTimeIcon />
                          </IconButton>
                        </InputAdornment>,
                      }}
                    />
                  </div>
                  <div className="col-12 input_box">
                    <label>Timezone <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        fullWidth
                        defaultValue="Africa / Asmara" 
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <ExpandMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
                </div>
              </div>
          </div>
          <div className="col-lg-6 inputbox_holder">
              <div className="container-box">
                <h4 className="box_title">Checklists</h4>
                <div className="row">
                  <div className="col-12 input_box">
                    <FormControlLabel className="check_box" control={<Checkbox />} label="Clean Bedroom" />
                    <FormControlLabel className="check_box" control={<Checkbox defaultChecked />} label="Clean Floor" />
                    <FormControlLabel className="check_box" control={<Checkbox />} label="Clean Pillows" />
                    <FormControlLabel className="check_box" control={<Checkbox />} label="Clean Windows and all the cuboards" />
                    <TextField 
                        variant="outlined"
                        fullWidth
                        multiline
                        defaultValue="" 
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input has_l_icon add_checklist"
                        placeholder="Add Checklist…"
                        rows={6}
                        InputProps={{
                            startAdornment: 
                            <InputAdornment position="start">
                                <CheckBoxOutlineBlankIcon />
                            </InputAdornment>,
                        }}
                    />
                    <p>
                      Press ‘Enter’ to add items
                    </p>
                  </div>
                </div>
              </div>
              
          </div>
        </div>
      </div>
    );
}