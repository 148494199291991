import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

import './BookingPopup.scss';
import imgWebreadyIcon from '../../../../assets/images/placeholder/webready_icon.png'

export default function BookingPopup(props: any) {
  return (
    <Dialog
        maxWidth='sm'
        fullWidth
        className="booking_popup"
        open={props.open}
        onClose = {(event, reason) => {
            if (props.onClose) {
                props.onClose(event, reason);
            }
        }}
      >
        <DialogTitle className="dialog_title_box">
            <div className="title_holder">
                <h4>David Pollack</h4>
                <p>
                    Pong Phen Guest House  |  Webready
                    <img src={imgWebreadyIcon} alt="" />
                </p>
            </div>
            <IconButton onClick={props.onClose} className="close_btn">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_content_box">
            <div className="b_flx_box">
                <div className="b_icon_holder">
                    <TodayOutlinedIcon className="b_icon" />
                </div>
                <div className="b_content_holder">
                    <h4>Booking Details</h4>
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-6 row_clm">
                            <label>From:</label>
                            <p>Wed | 11-March-2020</p>
                        </div>
                        <div className="col-12 col-sm-6 row_clm">
                            <label>To:</label>
                            <p>Mon | 15-March-2020</p>
                        </div>
                        <div className="col-12 col-sm-6 row_clm">
                            <label>Check-in:</label>
                            <p>2:00 PM</p>
                        </div>
                        <div className="col-12 col-sm-6 row_clm">
                            <label>Check-out:</label>
                            <p>7:00 PM</p>
                        </div>
                        <div className="col-auto row_clm">
                            <label>Adult</label>
                            <p>2</p>
                        </div>
                        <div className="col-auto row_clm">
                            <label>Children</label>
                            <p>0</p>
                        </div>
                        <div className="col-auto row_clm">
                            <label>Nights</label>
                            <p>4</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="b_flx_box">
                <div className="b_icon_holder">
                    <i className="icon icon-payments b_icon" style={{fontSize: 16}}></i>
                </div>
                <div className="b_content_holder">
                    <h4>Booking Charges</h4>
                    <div className="row align-items-center">
                        <div className="col-6 row_clm">
                            <label>Base Charge</label>
                        </div>
                        <div className="col-6 row_clm">
                            $300.00
                        </div>
                        <div className="col-6 row_clm">
                            <label>Additional Guests Fee</label>
                        </div>
                        <div className="col-6 row_clm">
                            $0.00
                        </div>
                        <div className="col-6 row_clm">
                            <label>Discounts & Fees</label>
                        </div>
                        <div className="col-6 row_clm">
                            $0.00
                        </div>
                        <div className="col-6 row_clm">
                            <label>Taxes</label>
                        </div>
                        <div className="col-6 row_clm">
                            $0.00
                        </div>
                        <div className="col-6 row_clm">
                            <label>Total</label>
                        </div>
                        <div className="col-6 row_clm">
                            <strong>$350.00</strong>
                            <label className="paid">paid</label>
                        </div>
                    </div>
                </div>
            </div>
        </DialogContent>
    </Dialog>
  );
}