import * as React from 'react';
import { Chip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './RentalTags.scss';

export default function RentalTags() {
    const handleClick = () => {
      console.info('You clicked the Chip.');
    };
    return (
      <div className="rental_tags_tab_holder">
        <h4 className="title">Rental Tags</h4>
        <div className="container-box">
          <h4>Select or Create Tag(s)</h4>
          <div className="row justify-content-between">
            <div className="col-md-5 col-lg-4 chip_holder">
              <Chip label="OFFERS" variant="outlined" onClick={handleClick} />
              <Chip label="RENTAL" variant="outlined" onClick={handleClick} />
              <Chip label="SANDEWA VILLA" variant="outlined" onClick={handleClick} />
              <Chip label="BEDROOM" variant="outlined" onClick={handleClick} />
              <Chip label="LUXURY" variant="outlined" onClick={handleClick} />
              <Chip label="BEACH RESORT" variant="outlined" onClick={handleClick} />
            </div>
            <div className="col-md-7">
              <div className="info_box">
                  <div>
                      <InfoOutlinedIcon color="primary" />
                  </div>
                  <div>
                      <p>
                        This feature allows you to add arbitrary descriptors to this template which can then be used 
                        when filtering on various tables and reports. 
                        For example, you can filter by tags on the templates table so that you only see a subset of all your templates
                      </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}