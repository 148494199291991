import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Menu, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';

import './RatesCalendar.scss';
import imgTokeet from '../../../../../../assets/images/channels/tokeet.png';
import imgRategenie from '../../../../../../assets/images/channels/rategenie.png';
import imgWebready from '../../../../../../assets/images/channels/webready.png';
import imgAirbnb from '../../../../../../assets/images/channels/airbnb.png';
import imgAgoda from '../../../../../../assets/images/channels/agoda.png';
import imgExpedia from '../../../../../../assets/images/channels/expedia.png';
import imgBooking from '../../../../../../assets/images/channels/booking_com.png';

export default function RatesCalendar() {
    const [popperEl, setPopperEl] = React.useState<null | HTMLElement>(null);
    const openPopper = Boolean(popperEl);
    const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
      setPopperEl(event.currentTarget);
    };
    const handleClosePopper = () => {
      setPopperEl(null);
    };

    return (
      <div className="rates_calendar_tab_holder">
        <div className="row justify-content-between align-items-center title-holder">
            <div className="col-auto title">
                <h4>
                  Rates Calendar 
                  <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                      <Link to="#">
                          <i className="icon icon-video" style={{fontSize: 12}}></i>
                      </Link>
                  </Tooltip>
                  <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-info"></i>
                    </Link>
                  </Tooltip>
                </h4>
                <p>Rates last updated 10-Mar-2021 6:10 PM.</p>
            </div>
            <div className="col-auto flx-box">
                <Tooltip title="Refresh" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                        <RefreshIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Button variant="outlined" className="btn_mui_dflt_outline">
                    Today
                </Button>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">2022</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none"> 
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">September</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
        </div>
        <div className="container-box">
            <div className="table-responsive table-holder">
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={popperEl}
                    open={openPopper}
                    onClose={handleClosePopper}
                    onClick={handleClosePopper}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0,
                            minWidth: 200,
                            maxWidth: 250,
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 50,
                            left: -7.5,
                            width: 15,
                            height: 15,
                            bgcolor: '#fff',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                >
                    <div className="calendar_popper_holder">
                        <div className="header_box">
                          23 September 2021
                        </div>
                        <div className="content_box">
                          <div className="td_row">
                            <div><img src={imgRategenie} alt="" /> Default</div>
                            <div className="clr_blue"><strong>$190</strong></div>
                          </div>
                          <div className="td_row">
                            <div><img src={imgAirbnb} alt="" /> Airbnb API</div>
                            <div className="clr_blue"><strong>$240</strong></div>
                          </div>
                          <div className="td_row">
                            <div><img src={imgBooking} alt="" /> Booking.com</div>
                            <div className="clr_red"><strong>$250 &uarr;</strong></div>
                          </div>
                          <div className="td_row">
                            <div><img src={imgExpedia} alt="" /> Expedia</div>
                            <div className="clr_red"><strong>$50 &uarr;</strong></div>
                          </div>
                          <div className="td_row">
                            <div><img src={imgAgoda} alt="" /> Agoda</div>
                            <div className="clr_red"><strong>$500 &darr;</strong></div>
                          </div>
                          <div className="td_row">
                            <div><img src={imgTokeet} alt="" /> Tokeet</div>
                            <div className="clr_blue"><strong>$240</strong></div>
                          </div>
                          <div className="td_row">
                            <div><img src={imgWebready} alt="" /> Webready</div>
                            <div className="clr_blue"><strong>$250</strong></div>
                          </div>
                        </div>
                    </div>
                </Menu>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>MON</th>
                            <th>TUE</th>
                            <th>WED</th>
                            <th>THU</th>
                            <th>FRI</th>
                            <th>SAT</th>
                            <th>SUN</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="unavailable"></td>
                            <td className="unavailable">
                              <div className="date">9</div>
                            </td>
                            <td className="unavailable">
                              <div className="date">10</div>
                            </td>
                            <td>
                              <div className="td_content bg_light_blue">
                                <div className="date">11</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div className="clr_blue"><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div className="clr_blue"><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div className="clr_blue"><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div className="clr_blue"><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content">
                                <div className="date">12</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content">
                                <div className="date">13</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content">
                                <div className="date">14</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                              <div className="td_content">
                                <div className="date">15</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content bg_dark_blue"  onClick={handleClickPopper}>
                                <div className="date">16</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content bg_light_blue">
                                <div className="date">17</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content"  onClick={handleClickPopper}>
                                <div className="date">18</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content bg_light_blue">
                                <div className="date">19</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div className="clr_red"><strong>$190 &uarr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div className="clr_red"><strong>$240 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content"  onClick={handleClickPopper}>
                                <div className="date">20</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content" onClick={handleClickPopper}>
                                <div className="date">21</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div className="clr_red"><strong>$190 &uarr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div className="clr_red"><strong>$240 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                              <div className="td_content">
                                <div className="date">22</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content bg_dark_blue"  onClick={handleClickPopper}>
                                <div className="date">23</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content bg_dark_blue">
                                <div className="date">24</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content"  onClick={handleClickPopper}>
                                <div className="date">25</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content bg_light_blue">
                                <div className="date">26</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div className="clr_red"><strong>$190 &uarr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div className="clr_red"><strong>$240 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content"  onClick={handleClickPopper}>
                                <div className="date">27</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content" onClick={handleClickPopper}>
                                <div className="date">28</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div className="clr_red"><strong>$190 &uarr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div className="clr_red"><strong>$240 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div className="clr_red"><strong>$250 &darr;</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                              <div className="td_content">
                                <div className="date">29</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content"  onClick={handleClickPopper}>
                                <div className="date">30</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div className="btn_more">2 More</div>
                                  <div className="d-flex align-items-center">
                                    <NightlightOutlinedIcon fontSize='small' />
                                    4
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="td_content">
                                <div className="date">31</div>
                                <div className="td_row">
                                  <div>Default</div>
                                  <div><strong>$190</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Airbnb API</div>
                                  <div><strong>$240</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Booking.com</div>
                                  <div><strong>$250</strong></div>
                                </div>
                                <div className="td_row">
                                  <div>Agoda</div>
                                  <div><strong>$250</strong></div>
                                </div>
                              </div>
                            </td>
                            <td className="unavailable_bg_green">
                              <div className="date">1</div>
                            </td>
                            <td className="unavailable">
                              <div className="date">2</div>
                            </td>
                            <td className="unavailable">
                              <div className="date">3</div>
                            </td>
                            <td className="unavailable">
                              <div className="date">4</div>
                            </td>
                        </tr>

                        <tr className="closing_row">
                            <td>5</td>
                            <td>6</td>
                            <td>7</td>
                            <td>8</td>
                            <td>9</td>
                            <td>10</td>
                            <td>11</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    );
}