import * as React from 'react';
import { Button, ButtonGroup, InputAdornment, Tab, Tabs, TextField, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';

import './ChannelDetailsTab.scss';

export default function ChannelDetailsTab() {
    const [value, setValue] = React.useState('one');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
    return (
      <div className="channel_details_tab_holder">
        <h4 className="title">Channel Details</h4>
        <div className="container-box">
          <h4 className="box_title">channel information</h4>
          <div className="row">
            <div className="col-xl-6">
              <div className="input_box">
                <label>Channel</label>
                <TextField 
                    variant="outlined"
                    fullWidth
                    type="text" 
                    size="small"
                    className="mui_dflt_outlined_input read-only" 
                    defaultValue="Airbnb"
                    InputProps={{
                      readOnly: true,
                    }}
                />
              </div>
              <div className="input_box">
                <label>Type</label>
                <TextField 
                    variant="outlined"
                    fullWidth
                    type="text" 
                    size="small"
                    className="mui_dflt_outlined_input read-only" 
                    defaultValue="API"
                    InputProps={{
                      readOnly: true,
                    }}
                />
              </div>
              <div className="input_box">
                <label>Date Linked</label>
                <TextField 
                    variant="outlined"
                    fullWidth
                    type="text" 
                    size="small"
                    className="mui_dflt_outlined_input read-only" 
                    defaultValue="16 - Sep - 2020 | 1:34 AM"
                    InputProps={{
                      readOnly: true,
                    }}
                />
              </div>

            </div>
            <div className="col-xl-6">
              <div className="input_box">
                <label>Linked Rental</label>
                <TextField 
                  variant="outlined"
                  fullWidth
                  defaultValue="Sandriti Villa" 
                  type="text" 
                  size="small"
                  className="mui_dflt_outlined_input has_r_icon read-only"
                  InputProps={{
                    readOnly: true,
                    endAdornment: 
                    <InputAdornment position="end">
                        <ExpandMoreIcon />
                    </InputAdornment>,
                  }}
                />
              </div>
              <div className="input_box">
                <label>Last Activity</label>
                <TextField 
                    variant="outlined"
                    fullWidth
                    type="text" 
                    size="small"
                    className="mui_dflt_outlined_input read-only" 
                    defaultValue="09-Sep-2021 | 11:45 PM"
                    InputProps={{
                      readOnly: true,
                    }}
                />
              </div>
              <div className="input_box">
                <label>URL</label>
                <TextField 
                  variant="outlined"
                  fullWidth
                  defaultValue="https://calendars.tokeet.com/calendar/rental/" 
                  type="text" 
                  size="small"
                  className="mui_dflt_outlined_input has_r_icon read-only"
                  required
                  InputProps={{
                    readOnly: true,
                    endAdornment: 
                    <InputAdornment position="end">
                        <ContentCopyOutlinedIcon className="clr_primary" />
                    </InputAdornment>,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <h4 className="title">Channel status</h4>
        <div className="container-box table_holder_has_no_pagi">
          <div className="table-responsive table-holder">
            <table className="table table-channel-status">
                <thead>
                    <tr>
                        <th>
                          status
                          <UnfoldMoreIcon className="sort_icon" />
                        </th>
                        <th>
                          IMPORTED
                          <UnfoldMoreIcon className="sort_icon" />
                        </th>
                        <th>
                          SKIPPED
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                          Disabled
                        </td>
                        <td>
                          11 Calendar Item(s)	
                        </td>
                        <td>
                          0 Calendar Item(s)
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        <div className="title_holder">
          <div className="row justify-content-between align-items-center">
            <div className="col-auto">
                <h4>Activity Logs</h4>
            </div>
            <div className="col-auto flx-box">
              <Tooltip title="Refresh" placement="bottom" arrow>
                  <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                      <RefreshIcon fontSize="small" className="clr_primary" />
                  </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="container-box table_holder_has_pagi">
          <Tabs
            value={value}
            onChange={handleChange}
            className="table_tabs"
            variant="scrollable"
            scrollButtons={false}
            allowScrollButtonsMobile
          >
            <Tab value="one" label="All" />
            <Tab value="two" label="Success" />
            <Tab value="three" label="Failure" />
            <Tab value="four" label="Disabled" />                        
          </Tabs>
          <div className="table-responsive table-holder">
              <table className="table">
                  <thead>
                      <tr>
                          <th>
                              date time
                              <UnfoldMoreIcon className="sort_icon" />
                          </th>
                          <th>
                              status
                              <UnfoldMoreIcon className="sort_icon" />
                          </th>
                          <th>
                              expires
                              <UnfoldMoreIcon className="sort_icon" />
                          </th>
                          <th>
                              message
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr>
                          <td>
                              21 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              23 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              24 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              21 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              29 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              28 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              21 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              27 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                      <tr>
                          <td>
                              25 - Jan - 2021  |  5:34 PM
                          </td>
                          <td>
                              Success
                          </td>
                          <td>
                              No
                          </td>
                          <td>
                              Some message will go here… 
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
          <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
              <div className="col-12 col-md-auto displaying_info">
                  Displaying <span>10</span> out of <span>25</span>
              </div>
              <div className="col-12 col-md-auto order-first order-md-last">
                  <ButtonGroup variant="outlined" className="cst_paginations">
                      <Button className="icon_btn_mui_dflt_outline" disabled>
                          <FirstPageIcon />
                      </Button>
                      <Button className="icon_btn_mui_dflt_outline" disabled>
                          <KeyboardArrowLeftIcon />
                      </Button>
                      <Button className="btn_mui_dflt_outline">1 -10</Button>
                      <Button className="icon_btn_mui_dflt_outline">
                          <KeyboardArrowRightIcon />
                      </Button>
                      <Button className="icon_btn_mui_dflt_outline">
                          <LastPageIcon />
                      </Button>
                  </ButtonGroup>
              </div>
          </div>
        </div>
      </div>
    );
}