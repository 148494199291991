import * as React from 'react';
import { Avatar, Badge, Button, ButtonGroup, Chip, IconButton, InputAdornment, Menu, MenuItem, Switch, TextField, Tooltip } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import './TasksTab.scss';

interface ChipData {
    key: number;
    label: string;
}

export default function TasksTab() {
    const [chipData, setChipData] = React.useState<readonly ChipData[]>([
        { key: 0, label: 'Gardening Expenses' },
        { key: 1, label: 'Jason Palumbo' },
        { key: 2, label: 'Airbnb' },
        { key: 3, label: 'Booking.com' },
        { key: 4, label: 'Webready' }
    ]);
    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };
    const [EditMenuEl, setEditMenuEl] = React.useState<null | HTMLElement>(null);
    const openEditMenu = Boolean(EditMenuEl);
    const handleClickEditMenu = (event: React.MouseEvent<HTMLElement>) => {
        setEditMenuEl(event.currentTarget);
    };
    const handleCloseEditMenu = () => {
        setEditMenuEl(null);
    };
    return (
      <div className="tasks_tab_holder">
        <div className="row status_tabs_holder">
            <div className="col-md-6 col-lg-3 status_tabs">
                <Button variant="text" className="box all active">
                    <div className="icon_holder">
                        <ListAltRoundedIcon />
                    </div>
                    <div>
                        <h6>All Task</h6>
                        <h1>46</h1>
                        <p>in last 30 days</p>
                    </div>
                </Button>
            </div>
            <div className="col-md-6 col-lg-3 status_tabs">
                <Button variant="text" className="box pending">
                    <div className="icon_holder">
                        <ListAltRoundedIcon />
                    </div>
                    <div>
                        <h6>Pending</h6>
                        <h1>12</h1>
                        <p>in last 30 days</p>
                    </div>
                </Button>
            </div>
            <div className="col-md-6 col-lg-3 status_tabs">
                <Button variant="text" className="box past-due">
                    <div className="icon_holder">
                        <ListAltRoundedIcon />
                    </div>
                    <div>
                        <h6>Past Due</h6>
                        <h1>11</h1>
                        <p>in last 30 days</p>
                    </div>
                </Button>
            </div>
            <div className="col-md-6 col-lg-3 status_tabs">
                <Button variant="text" className="box completed">
                    <div className="icon_holder">
                        <ListAltRoundedIcon />
                    </div>
                    <div>
                        <h6>Completed</h6>
                        <h1>23</h1>
                        <p>in last 30 days</p>
                    </div>
                </Button>
            </div>
        </div>
        <div className="container-box">
            <div className="box_header">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto flx-box">
                        <TextField 
                            variant="outlined"
                            placeholder="Search Tasks" 
                            type="search" 
                            size="small"
                            className="mui_dflt_outlined_input has_l_icon search-holder" 
                            InputProps={{
                                startAdornment: 
                                <InputAdornment position="start">
                                    <IconButton size="small">
                                        <SearchOutlinedIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-auto flx-box">
                        <Button variant="text" className="btn-reset-filter">
                            <RemoveCircleOutlineOutlinedIcon />
                        </Button>
                        <Button variant="outlined" className="btn_circle">
                            8+
                        </Button>
                        {chipData.map((data) => {

                            return (
                            <div key={data.key}>
                                <Chip
                                    variant="outlined"
                                    label={data.label}
                                    onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                                />
                            </div>
                            );
                        })}
                        <Tooltip title="Settings" placement="bottom" arrow>
                            <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                                <Badge color="error" variant="dot">
                                    <SettingsOutlinedIcon />
                                </Badge>
                            </Button>
                        </Tooltip>
                        <Tooltip title="View" placement="bottom" arrow>
                            <Button variant="outlined" className="icon_btn_mui_dflt_outline clr_primary">
                                <VisibilityOutlinedIcon />
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            
            <div className="table-responsive table-holder">
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={EditMenuEl}
                    open={openEditMenu}
                    onClose={handleCloseEditMenu}
                    onClick={handleCloseEditMenu}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0,
                            minWidth: 95,
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 48,
                            width: 15,
                            height: 15,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                    <MenuItem>
                        <EditOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                        Edit
                    </MenuItem>
                    <MenuItem>
                        <FileDownloadOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                        Download
                    </MenuItem>
                    <MenuItem>
                        <DeleteOutlinedIcon style={{fontSize: 16, marginRight: 8}} />
                        Delete
                    </MenuItem>
                </Menu>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                User 
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                Name
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                Created
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                Inquiry
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                Status
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th>
                                Due
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th className="text-center">
                                Mark as complete
                                <UnfoldMoreIcon className="sort_icon" />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>D</Avatar>
                                    <span>David Pollack</span>
                                </div>
                            </td>
                            <td>
                                Cleaning Task
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>A</Avatar>
                                    <div>
                                        <span>Anne Kocher</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="pending-icon" /> Pending
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch defaultChecked className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>A</Avatar>
                                    <span>Andrinna</span>
                                </div>
                            </td>
                            <td>
                                Some New Task
                            </td>
                            <td>
                                30 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>K</Avatar>
                                    <div>
                                        <span>Keith Thomason</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="pastdue-icon" /> Past Due
                            </td>
                            <td>
                                30 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>C</Avatar>
                                    <span>Colin Ochel</span>
                                </div>
                            </td>
                            <td>
                                Inquiry Related Task 
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>R</Avatar>
                                    <div>
                                        <span>Roman Newman</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="completed-icon" /> Completed
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch defaultChecked className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>D</Avatar>
                                    <span>David Pollack</span>
                                </div>
                            </td>
                            <td>
                                Cleaning Task
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>A</Avatar>
                                    <div>
                                        <span>Anne Kocher</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="pending-icon" /> Pending
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch defaultChecked className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>A</Avatar>
                                    <span>Andrinna</span>
                                </div>
                            </td>
                            <td>
                                Some New Task
                            </td>
                            <td>
                                30 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>K</Avatar>
                                    <div>
                                        <span>Keith Thomason</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="pastdue-icon" /> Past Due
                            </td>
                            <td>
                                30 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>C</Avatar>
                                    <span>Colin Ochel</span>
                                </div>
                            </td>
                            <td>
                                Inquiry Related Task 
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>R</Avatar>
                                    <div>
                                        <span>Roman Newman</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="completed-icon" /> Completed
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch defaultChecked className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>D</Avatar>
                                    <span>David Pollack</span>
                                </div>
                            </td>
                            <td>
                                Cleaning Task
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>A</Avatar>
                                    <div>
                                        <span>Anne Kocher</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="pending-icon" /> Pending
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch defaultChecked className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>A</Avatar>
                                    <span>Andrinna</span>
                                </div>
                            </td>
                            <td>
                                Some New Task
                            </td>
                            <td>
                                30 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>K</Avatar>
                                    <div>
                                        <span>Keith Thomason</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="pastdue-icon" /> Past Due
                            </td>
                            <td>
                                30 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>C</Avatar>
                                    <span>Colin Ochel</span>
                                </div>
                            </td>
                            <td>
                                Inquiry Related Task 
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td>
                                <div className="avatar_flx_box">
                                    <Avatar>R</Avatar>
                                    <div>
                                        <span>Roman Newman</span>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <PeopleAltOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                            <div>
                                                <span>2</span>
                                                <NightlightOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <CircleIcon className="completed-icon" /> Completed
                            </td>
                            <td>
                                29 - Aug - 2020
                            </td>
                            <td className="text-center">
                                <Switch defaultChecked className="mui_dflt_switch" />
                            </td>
                            <td>
                                <IconButton className="edit_btn" onClick={handleClickEditMenu}>
                                    <MoreHorizIcon />
                                </IconButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="row justify-content-center justify-content-md-between align-items-center pagination_holder">
                <div className="col-12 col-md-auto displaying_info">
                    Displaying <span>10</span> out of <span>25</span>
                </div>
                <div className="col-12 col-md-auto order-first order-md-last">
                    <ButtonGroup variant="outlined" className="cst_paginations">
                        <Button className="icon_btn_mui_dflt_outline" disabled>
                            <FirstPageIcon />
                        </Button>
                        <Button className="icon_btn_mui_dflt_outline" disabled>
                            <KeyboardArrowLeftIcon />
                        </Button>
                        <Button className="btn_mui_dflt_outline">1 -10</Button>
                        <Button className="icon_btn_mui_dflt_outline">
                            <KeyboardArrowRightIcon />
                        </Button>
                        <Button className="icon_btn_mui_dflt_outline">
                            <LastPageIcon />
                        </Button>
                    </ButtonGroup>
                </div>
            </div>

        </div>
      </div>
    );
}