import * as React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

import './RentalTaxVat.scss';

export default function RentalTaxVat() {
    return (
      <div className="rental_tax_vat_tab_holder">
        <h4 className="title">Rental Tax & vat</h4>

        <div className="container-box">
          <div className="row">
            <div className="col-lg-4">
              <div className="input_box">
                  <label>Name <span className="text-danger">*</span></label>
                  <TextField 
                      variant="outlined"
                      defaultValue="Yearly" 
                      fullWidth
                      type="text" 
                      size="small"
                      required
                      className="mui_dflt_outlined_input has_r_icon read-only"
                      InputProps={{
                          readOnly: true,
                          endAdornment: 
                          <InputAdornment position="end">
                              <ExpandMoreIcon />
                          </InputAdornment>,
                      }}
                  />
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
                <div className="input_box">
                    <label>Amount ($) <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        defaultValue="12" 
                        fullWidth
                        type="number" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <UnfoldMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <div className="col-md-6 col-lg-2">
                <div className="input_box">
                    <label>Type <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        fullWidth
                        defaultValue="Flat" 
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <ExpandMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <div className="col-lg-4">
                <div className="input_box">
                    <label>Modality <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        fullWidth
                        defaultValue="Per Stay (PS)" 
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <ExpandMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
            </div>
          </div>
        </div>
        
        <div className="container-box">
          <div className="row">
            <div className="col-lg-4">
              <div className="input_box">
                  <label>Name <span className="text-danger">*</span></label>
                  <TextField 
                      variant="outlined"
                      defaultValue="Yearly" 
                      fullWidth
                      type="text" 
                      size="small"
                      required
                      className="mui_dflt_outlined_input has_r_icon read-only"
                      InputProps={{
                          readOnly: true,
                          endAdornment: 
                          <InputAdornment position="end">
                              <ExpandMoreIcon />
                          </InputAdornment>,
                      }}
                  />
              </div>
            </div>
            <div className="col-md-6 col-lg-2">
                <div className="input_box">
                    <label>Amount ($) <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        defaultValue="12" 
                        fullWidth
                        type="number" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <UnfoldMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <div className="col-md-6 col-lg-2">
                <div className="input_box">
                    <label>Type <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        fullWidth
                        defaultValue="Flat" 
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <ExpandMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
            </div>
            <div className="col-lg-4">
                <div className="input_box">
                    <label>Modality <span className="text-danger">*</span></label>
                    <TextField 
                        variant="outlined"
                        fullWidth
                        defaultValue="Per Stay (PS)" 
                        type="text" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon read-only"
                        required
                        InputProps={{
                            readOnly: true,
                            endAdornment: 
                            <InputAdornment position="end">
                                <ExpandMoreIcon />
                            </InputAdornment>,
                        }}
                    />
                </div>
            </div>
          </div>
        </div>

      </div>
    );
}