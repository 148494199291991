import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Switch, Tooltip, IconButton, ButtonGroup, Menu, MenuItem, Divider, TextField, InputAdornment, Badge } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';


import './RatesCalendarPage.scss';
import imageRental_1 from '../../../../assets/images/placeholder/thumb-1.jpg';
import imageRental_2 from '../../../../assets/images/placeholder/thumb-2.jpg';
import imageRental_3 from '../../../../assets/images/placeholder/thumb-3.jpg';
import imageRental_4 from '../../../../assets/images/placeholder/thumb-4.jpg';
import imageRental_5 from '../../../../assets/images/placeholder/thumb-5.jpg';
import imgIconLocation from '../../../../assets/images/location_pin.svg';
import imgIconDollarSetting from '../../../../assets/images/dollar_setting.svg';


function RatescalendarPage(){

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [popperEl, setPopperEl] = React.useState<null | HTMLElement>(null);
  const openPopper = Boolean(popperEl);
  const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
    setPopperEl(event.currentTarget);
  };
  const handleClosePopper = () => {
    setPopperEl(null);
  };
  

  return (
    <div className="rates-calendar-page page_holder">
      <div className="header sticky-top">
        <h4>Calendar</h4>
        <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-info"></i>
            </Link>
        </Tooltip>
        <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
            <Link to="#">
                <i className="icon icon-video" style={{fontSize: 16}}></i>
            </Link>
        </Tooltip>
      </div>
      <div className="content_holder">
        <div className="row justify-content-between align-items-center title-holder">
            <div className="col-auto title">
                <h4>Rates Calendar </h4>
                <Tooltip title="Click here to see the associated help article." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-info"></i>
                    </Link>
                </Tooltip>
                <Tooltip title="Click here to see a video tutorial." placement="bottom" arrow>
                    <Link to="#">
                        <i className="icon icon-video" style={{fontSize: 11}}></i>
                    </Link>
                </Tooltip>
            </div>
            <div className="col-auto">
                <div className="btn-group-box">
                    <Link to="/calendar/multi">
                        <Tooltip title="Multi Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-multi-calendar-month"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/standard">
                        <Tooltip title="Standard Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-standard-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/planning">
                        <Tooltip title="Planning Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-planning-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/yearly">
                        <Tooltip title="Yearly Calendar" placement="bottom" arrow>
                            <Button variant="text">
                                <i className="icon icon-multi-calendar-year"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                    <Link to="/calendar/rates">
                        <Tooltip title="Rates Calendar" placement="bottom" arrow>
                            <Button variant="text" className="active">
                                <i className="icon icon-rates-calendar"></i>
                            </Button>
                        </Tooltip>
                    </Link>
                </div>
            </div>
        </div>
        <div className="row justify-content-between align-items-center header-input-box">
            <div className="col-auto flx-box">
                <div className="search_holder">
                    <TextField 
                        variant="outlined"
                        placeholder="Search Rentals" 
                        type="search" 
                        size="small"
                        className="mui_dflt_outlined_input has_l_icon"
                        InputProps={{
                            startAdornment: 
                            <InputAdornment position="start">
                                <IconButton size="small">
                                    <SearchOutlinedIcon />
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                </div>
                <Button 
                    variant="text"
                    className="btn-filter"
                >
                    <Badge color="error" variant="dot">
                        <FilterListOutlinedIcon />
                    </Badge>
                </Button>
                <Button variant="text" className="btn-reset-filter">
                    <RemoveCircleOutlineOutlinedIcon />
                </Button>
            </div>
            <div className="col-auto flx-box">
                <Tooltip title="Refresh" placement="bottom" arrow>
                    <Button variant="outlined" className="icon_btn_mui_dflt_outline">
                        <RefreshIcon fontSize="small" />
                    </Button>
                </Tooltip>
                <Button variant="outlined" className="btn_mui_dflt_outline">
                    Today
                </Button>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">2022</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
                <ButtonGroup variant="outlined" className="has_shadow">
                    <Button className="icon_btn_mui_dflt_outline shadow_none"> 
                        <ArrowBackIosIcon fontSize="small" />
                    </Button>
                    <Button className="btn_mui_dflt_outline shadow_none">September</Button>
                    <Button className="icon_btn_mui_dflt_outline shadow_none">
                        <ArrowForwardIosIcon fontSize="small" />
                    </Button>
                </ButtonGroup>
            </div>
            <div className="col-auto flx-box">
                <div className="switch_holder">
                    <label>Status On</label>
                    <Switch defaultChecked className="mui_dflt_switch" />
                </div>
            </div>
            <div className="col-auto flx-box">
                <Button
                    className="btn_mui_primary has_split_icon"
                    variant="contained"
                    onClick={handleClick}
                    aria-controls={open ? 'add-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    startIcon={<BoltOutlinedIcon style={{ fontSize: 20 }} />}
                    endIcon={<KeyboardArrowDownIcon className="split_icon" />}
                >
                    ACTIONS
                </Button>
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={anchorEl}
                    id="add-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        minWidth: 180,
                        '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem>
                        Action
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        Another action
                    </MenuItem>
                </Menu>
            </div>
        </div>

        <div className="container-box">
            <div className="table-responsive table-holder">
                <Menu
                    className="dflt_dropdown_mui"
                    anchorEl={popperEl}
                    open={openPopper}
                    onClose={handleClosePopper}
                    onClick={handleClosePopper}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 0,
                            minWidth: 250,
                            maxWidth: 280,
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 130,
                            width: 15,
                            height: 15,
                            bgcolor: '#03A99D',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                    <div className="popper_holder">
                        <div className="header_box">
                            Sep 4th 2022
                        </div>
                        <div className="content_box">
                            <div className="row">
                                <div className="col-7">
                                    Starting Rate <br />
                                    <strong className="rate">$389</strong>
                                </div>
                                <div className="col-5">
                                    Nightly <br />
                                    <strong>$300</strong>
                                </div>
                                <div className="col-7">
                                    Max Stay Arrival <br />
                                    <strong>4 Days</strong>
                                </div>
                                <div className="col-5">
                                    Min Stay Arrive <br />
                                    <strong>2 Days</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </Menu>
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <div className="table_pagination">
                                    <div className="pagi_ctrl">
                                        <IconButton>
                                            <FirstPageIcon />
                                        </IconButton>
                                        <IconButton>
                                            <NavigateBeforeIcon />
                                        </IconButton>
                                    </div>
                                    <div className="info_text"><span>13</span> of 50</div> 
                                    <div className="pagi_ctrl">
                                        <IconButton>
                                            <NavigateNextIcon />
                                        </IconButton>
                                        <IconButton>
                                            <LastPageIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">1</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">2</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">3</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">4</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">5</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">6</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">7</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">8</span>
                            </th>
                            <th>
                                <span className="day">Tue</span>
                                <span className="date">9</span>
                            </th>
                            <th>
                                <span className="day">Wed</span>
                                <span className="date">10</span>
                            </th>
                            <th>
                                <span className="day">Thu</span>
                                <span className="date">11</span>
                            </th>
                            <th>
                                <span className="day">Fri</span>
                                <span className="date">12</span>
                            </th>
                            <th>
                                <span className="day">Sat</span>
                                <span className="date">13</span>
                            </th>
                            <th>
                                <span className="day">Sun</span>
                                <span className="date">14</span>
                            </th>
                            <th>
                                <span className="day">Mon</span>
                                <span className="date">15</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_1} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>ETZzz Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 245</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 271</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 312</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 219</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 678</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 523</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_2} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flx_icon_abs_box">
                                    <div>
                                        <img src={imgIconDollarSetting} alt="" />
                                    </div>
                                    <div>
                                        <img src={imgIconLocation} alt="" />
                                    </div>
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_lowdemand" onClick={handleClickPopper}>
                                    <strong>$ 278</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_3} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Bunk Boutique Hostel</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="p_booked_dates"></td>
                            <td>
                                <div className="td_content rates_exceeded" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_lowdemand" onClick={handleClickPopper}>
                                    <strong>$ 278</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_exceeded" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_4} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>ETZzz Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 245</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 271</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 312</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 219</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 678</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 523</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_5} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flx_icon_abs_box">
                                    <div>
                                        <img src={imgIconDollarSetting} alt="" />
                                    </div>
                                    <div>
                                        <img src={imgIconLocation} alt="" />
                                    </div>
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_lowdemand" onClick={handleClickPopper}>
                                    <strong>$ 278</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_3} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Bunk Boutique Hostel</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 126</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_exceeded" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_lowdemand" onClick={handleClickPopper}>
                                    <strong>$ 278</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_exceeded" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_4} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>ETZzz Hostel Bangkok</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 245</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 271</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 312</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 219</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 678</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 523</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="rental_box_holder">
                                <div className="rental_box">
                                    <div className="img_holder">
                                        <img src={imageRental_5} alt="" />
                                    </div>
                                    <div className="rental_dsc">
                                        <h4>Koon Cha Learn</h4>
                                        <div className="flx_icon_box">
                                            <div>
                                                <span>3</span>
                                                <BedOutlinedIcon sx={{ fontSize: 13 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flx_icon_abs_box">
                                    <div>
                                        <img src={imgIconDollarSetting} alt="" />
                                    </div>
                                    <div>
                                        <img src={imgIconLocation} alt="" />
                                    </div>
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 346</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 389</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_lowdemand" onClick={handleClickPopper}>
                                    <strong>$ 278</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="unavailable"></td>
                            <td>
                                <div className="td_content rates_highdemand" onClick={handleClickPopper}>
                                    <strong>$ 387</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content rates_modified" onClick={handleClickPopper}>
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 300</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                            <td>
                                <div className="td_content">
                                    <strong>$ 342</strong>
                                    <br />
                                    1
                                </div>
                            </td>
                            <td className="booked_dates"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <div className="status_info_flx_box">
            <div className="status_box">
                <div className="clr_box booked_dates"></div>
                <span>Booked Dates</span>
            </div>
            <div className="status_box">
                <div className="clr_box unavailable"></div>
                <span>Unavailable</span>
            </div>
            <div className="status_box">
                <div className="clr_box rates_exceeded"></div>
                <span>Rates Exceeded</span>
            </div>
            <div className="status_box">
                <div className="clr_box rates_modified"></div>
                <span>Rates Modified</span>
            </div>
            <div className="status_box">
                <div className="clr_box low_demand"></div>
                <span>Low Demand</span>
            </div>
            <div className="status_box">
                <div className="clr_box high_demand"></div>
                <span>High Demand</span>
            </div>
        </div>

      </div>
    </div>
  );
}

export default RatescalendarPage;