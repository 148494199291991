import * as React from 'react';
import { Button, Checkbox, FormControlLabel, InputAdornment, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import TodayIcon from '@mui/icons-material/Today';

import './RentalFees.scss';

export default function RentalFees() {
    const [ShowMoreOptions,setShowMoreOptions] = React.useState(false);
    return (
      <div className="rental_fees_tab_holder">
        <h4 className="title">Rental Fees</h4>

        <div className="container-box">
          <div className="row">
            <div className="col-lg-4">
                <div className="row">
                    <div className="col-md-6 input_box">
                        <label>Name <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            defaultValue="Night Fee" 
                            fullWidth
                            type="text" 
                            size="small"
                            required
                            className="mui_dflt_outlined_input read-only"
                        />
                    </div>
                    <div className="col-md-6 input_box">
                        <label>Amount ($) <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            defaultValue="345" 
                            fullWidth
                            type="number" 
                            size="small"className="mui_dflt_outlined_input has_r_icon read-only"
                            required
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <UnfoldMoreIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-md-6 input_box">
                        <label>Type <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            defaultValue="Flat" 
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon read-only"
                            required
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <ExpandMoreIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-md-6 input_box">
                        <label>Modality <span className="text-danger">*</span></label>
                        <TextField 
                            variant="outlined"
                            fullWidth
                            defaultValue="Per Stay (PS)" 
                            type="text" 
                            size="small"
                            className="mui_dflt_outlined_input has_r_icon read-only"
                            required
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <ExpandMoreIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-8">
                <div className="input_box">
                    <label>Description</label>
                    <TextField 
                        variant="outlined"
                        defaultValue="" 
                        multiline
                        fullWidth
                        type="number" 
                        size="small"
                        className="mui_dflt_outlined_input read-only"
                        rows={5}
                        placeholder="Enter description here"
                    />
                </div>
            </div>
          </div>
          {ShowMoreOptions && (
            <>
                <div className="row">
                    <div className="col-md-4 col-lg-2 input_box">
                        <label>Guest Threshold</label>
                        <TextField 
                            variant="outlined"
                            defaultValue="" 
                            fullWidth
                            type="number" 
                            size="small"className="mui_dflt_outlined_input has_r_icon read-only"
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <UnfoldMoreIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-md-4 col-lg-2 input_box">
                        <label>Length Threshold</label>
                        <TextField 
                            variant="outlined"
                            defaultValue="" 
                            fullWidth
                            type="number" 
                            size="small"className="mui_dflt_outlined_input has_r_icon read-only"
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <UnfoldMoreIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-md-4 col-lg-2 input_box">
                        <label>Scheduling Start</label>
                        <TextField 
                            variant="outlined"
                            defaultValue="" 
                            fullWidth
                            type="text" 
                            size="small"className="mui_dflt_outlined_input has_r_icon read-only"
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <TodayIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                    <div className="col-md-4 col-lg-2 input_box">
                        <label>Scheduling End</label>
                        <TextField 
                            variant="outlined"
                            defaultValue="" 
                            fullWidth
                            type="text" 
                            size="small"className="mui_dflt_outlined_input has_r_icon read-only"
                            InputProps={{
                                readOnly: true,
                                endAdornment: 
                                <InputAdornment position="end">
                                    <TodayIcon />
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </div> 
                <div className="row">
                    <div className="col-md-4 col-lg-2 input_box">
                        <FormControlLabel className="check_box" control={<Checkbox />} label="Upfront" />
                    </div> 
                    <div className="col-md-4 col-lg-2 input_box">
                        <FormControlLabel className="check_box" control={<Checkbox defaultChecked />} label="Refundable" />
                    </div> 
                    <div className="col-md-4 col-lg-2 input_box">
                        <FormControlLabel className="check_box" control={<Checkbox />} label="Kids Activities" />
                    </div> 
                </div>
                <Button variant='text' className="btn_options" onClick={()=>setShowMoreOptions(false)}>Less Options <ExpandLessIcon /></Button>
            </>
          )}
          {!ShowMoreOptions && (
            <>
                <Button variant='text' className="btn_options" onClick={()=>setShowMoreOptions(true)}>More Options <ExpandMoreIcon /></Button>
            </>
          )}
        </div>

      </div>
    );
}