import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, InputAdornment, TextField } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import './InquiryTab.scss';
import imgUser from '../../../../../assets/images/placeholder/user-3.png';
import imgTokeetIcon from '../../../../../assets/images/channels/tokeet.png';

export default function InquiryTab() {
    return (
      <div className="inquiry_tab_holder">
        <div className="row">
            <div className="col-md-4 col-xl-3 inquiry_sidebar">
                <div className="box">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: 24 }} />}
                        >
                            <div className="box_title">
                                Guest details
                                <VisibilityOutlinedIcon fontSize='small' />
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="flx_box">
                                <div className="avater_box">
                                    <img src={imgUser} alt="" />
                                </div>
                                <div>
                                    <h4 className="title">
                                        Ruben Clark<br />
                                        <label>Arriving</label>
                                        <img src={imgTokeetIcon} alt="" />
                                    </h4>
                                    <div className="txt_box">
                                        <label>Email</label>
                                        <p>ruben@gmail.com</p>
                                    </div>
                                    <div className="txt_box">
                                        <label>Phone</label>
                                        <p>24454657687689</p>
                                    </div>
                                    <div className="txt_box">
                                        <label>Check - In</label>
                                        <p>12 July, 2021 • 12:00 PM</p>
                                    </div>
                                    <div className="txt_box">
                                        <label>Check - Out</label>
                                        <p>22 July, 2021 • 12:00 PM</p>
                                    </div>
                                </div>
                            </div> 
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="box">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: 24 }} />}
                        >
                            <div className="box_title">
                                Booking details
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="flx_box">
                                <div className="avater_box">
                                    <img src={imgUser} alt="" />
                                </div>
                                <div>
                                    <h4 className="title mb-0">
                                        Butterfly Apartment
                                    </h4>
                                    <div className="row">
                                        <div className="col-auto txt_box">
                                            <label>Adults</label>
                                            <p>3</p>
                                        </div>
                                        <div className="col-auto txt_box">
                                            <label>Children</label>
                                            <p>2</p>
                                        </div>
                                        <div className="col-auto txt_box">
                                            <label>Days</label>
                                            <p>11</p>
                                        </div>
                                        <div className="col-auto txt_box">
                                            <label>Nights</label>
                                            <p>10</p>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="box">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: 24 }} />}
                        >
                            <div className="box_title">
                                Booking charges
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row">
                                <div className="col-6 txt_box">
                                    <label>Base Charge</label>
                                    <p>$300.00</p>
                                </div>
                                <div className="col-6 txt_box">
                                    <label>Additional Guests Fee</label>
                                    <p>$0.00</p>
                                </div>
                                <div className="col-6 txt_box">
                                    <label>Discounts & Fees</label>
                                    <p>$0.00</p>
                                </div>
                                <div className="col-6 txt_box">
                                    <label>Taxes</label>
                                    <p>$0.00</p>
                                </div>
                                <div className="col-6 txt_box">
                                    <label>Total</label>
                                    <p><strong>$350.00</strong></p>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="box">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ fontSize: 24 }} />}
                        >
                            <div className="box_title">
                                Occupied dates  
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="calendar_header">
                                <IconButton sx={{opacity: 0}}>
                                    <ChevronLeftOutlinedIcon />
                                </IconButton>
                                February 2021
                                <IconButton>
                                    <ChevronRightOutlinedIcon />
                                </IconButton>
                            </div>
                            <div className="calendar_content">
                                <ul className="weekdays">
                                    <li>Su</li><li>M</li><li>Tu</li>
                                    <li>W</li><li>Th</li><li>F</li><li>Sa</li>
                                </ul>
                                <ul className="days">  
                                    <li className="disable"><span>30</span></li><li className="disable"><span>31</span></li>
                                    <li><span>1</span></li><li><span>2</span></li><li><span>3</span></li><li><span>4</span></li>
                                    <li><span>5</span></li><li><span>6</span></li>
                                    <li><span>7</span></li><li><span>8</span></li>
                                    <li><span>9</span></li><li><span>10</span></li>
                                    <li><span>11</span></li><li><span>12</span></li><li><span>13</span></li><li><span>14</span></li>
                                    <li><span>15</span></li>
                                    <li className="has_event bg_blue event_start"><span>16</span></li><li className="has_event bg_blue"><span>17</span></li>
                                    <li className="has_event bg_blue"><span>18</span></li><li className="has_event bg_blue event_end"><span>19</span></li>
                                    <li className="has_event bg_green event_start"><span>20</span></li>
                                    <li className="has_event bg_green"><span>21</span></li><li className="has_event bg_green"><span>22</span></li>
                                    <li className="has_event bg_green"><span>23</span></li><li className="has_event bg_green"><span>24</span></li>
                                    <li className="has_event bg_green"><span>25</span></li>
                                    <li className="has_event bg_green event_end"><span>26</span></li>
                                    <li className="has_event bg_green event_end"><span>27</span></li>
                                    <li><span>28</span></li><li><span>29</span></li><li><span>30</span></li>
                                    <li><span>31</span></li><li className="disable"><span>1</span></li><li className="disable"><span>2</span></li>
                                </ul>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="box has_padding">
                    <h4 className="box_title"> Related Invoices</h4>
                </div>
                <div className="box has_padding">
                    <h4 className="box_title"> Notes</h4>
                </div>
            </div>
            <div className="col-md-8 col-xl-9 inquiry_content">
                <div className="search_box">
                    <TextField 
                        variant="outlined"
                        fullWidth
                        placeholder="Search" 
                        type="search" 
                        size="small"
                        className="mui_dflt_outlined_input has_r_icon"
                        InputProps={{
                            endAdornment: 
                            <InputAdornment position="end">
                                <IconButton size="small">
                                    <SearchOutlinedIcon />
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                </div>
                <h4 className="day_n_date">Today</h4>

                <div className="inquiry_box">
                    <div className="avatar">
                        <img src={imgTokeetIcon} alt="" />
                    </div>
                    <div className="content_box">
                        <div className="head_flx_box">
                            <div>
                                <h2>Post Meeting Followup</h2>
                                <h6>Today at 5:44 AM  •  Ruben Clark (Web App)</h6>
                            </div>
                            <IconButton className="unfold_more_btn">
                                <UnfoldMoreIcon />
                            </IconButton>
                        </div>

                        <p>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
                            magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, 
                            consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore.
                        </p>
                    </div>
                </div>

                <div className="inquiry_box">
                    <div className="avatar">
                        <HelpCenterOutlinedIcon />
                    </div>
                    <div className="content_box">
                        <div className="head_flx_box">
                            <div>
                                <h2>Stage: Demo Scheduled Demo Done</h2>
                                <h6>Today at 5:44 AM  •  Ruben Clark (Web App)</h6>
                            </div>
                            <IconButton className="unfold_more_btn">
                                <UnfoldMoreIcon />
                            </IconButton>
                        </div>

                        <p>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
                            magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum.
                        </p>
                    </div>
                </div>

                <div className="inquiry_box">
                    <div className="avatar">
                        <HelpCenterOutlinedIcon />
                    </div>
                    <div className="content_box selected">
                        <div className="head_flx_box">
                            <div>
                                <h2>Our Meeting for Today</h2>
                                <h6>Today at 5:44 AM  •  Ruben Clark (Web App)</h6>
                            </div>
                            <IconButton className="unfold_more_btn">
                                <UnfoldMoreIcon />
                            </IconButton>
                        </div>

                        <p>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
                            magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, 
                            consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. 
                            Ut enim ad minima veniam, quis nostrum , sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. 
                            Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                        </p>
                    </div>
                </div>
                
                <div className="inquiry_box">
                    <div className="avatar">
                        <LocalAtmOutlinedIcon />
                    </div>
                    <div className="content_box">
                        <div className="head_flx_box">
                            <div>
                                <h2>December Month Stay Invoice</h2>
                                <h6>Today at 5:44 AM  •  Ruben Clark (Web App)</h6>
                            </div>
                            <IconButton className="unfold_more_btn">
                                <UnfoldMoreIcon />
                            </IconButton>
                        </div>

                        <p>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
                            magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum.
                        </p>
                    </div>
                </div>
                
                <div className="inquiry_box">
                    <div className="avatar">
                        <HelpCenterOutlinedIcon />
                    </div>
                    <div className="content_box">
                        <div className="head_flx_box">
                            <div>
                                <h2>Download Safety Instructions</h2>
                                <h6>Today at 5:44 AM  •  Ruben Clark (Web App)</h6>
                            </div>
                            <IconButton className="unfold_more_btn">
                                <UnfoldMoreIcon />
                            </IconButton>
                        </div>

                        <p>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
                            magni dolores eos qui ratione voluptatem sequi nesciunt.
                        </p>
                    </div>
                </div>

                <div className="inquiry_box">
                    <div className="avatar">
                        <LocalAtmOutlinedIcon />
                    </div>
                    <div className="content_box">
                        <div className="head_flx_box">
                            <div>
                                <h2>December Month Stay Invoice</h2>
                                <h6>Today at 5:44 AM  •  Ruben Clark (Web App)</h6>
                            </div>
                            <IconButton className="unfold_more_btn">
                                <UnfoldMoreIcon />
                            </IconButton>
                        </div>

                        <p>
                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur 
                            magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum.
                        </p>
                    </div>
                </div>
                <h4 className="loading_info">Loading <strong>21</strong> of 100</h4>
            </div>
        </div>
      </div>
    );
}